import React, { useEffect } from "react";
import getAllQuizAttempts from "../api/quiz/getAllQuizAttempts";
import { QuizAttempt } from "../types/Quiz";


export interface QuizAttemptsState {
  status: 'FETCHING' | 'FETCH_ERROR' | 'SUCCESS';
  error?: string;
  attempts?: QuizAttempt[];
};
 
export default function useAllAttempts(quizId?: string, userId?: string) {
  
  const [quizAttemptState, setQuizAttemptState] = React.useState<QuizAttemptsState>({
    status: 'FETCHING'
  });

  const fetchQuizAttempt = React.useMemo(() => async () => {
    if (!quizId) {
      setQuizAttemptState({
        status: 'SUCCESS',
        attempts: undefined,
        error: undefined
      });
      return;
    }
    const resp = await getAllQuizAttempts(quizId, userId);
    if (resp.error) {
      setQuizAttemptState({
        status: 'FETCH_ERROR',
        error: resp.error
      })
    } else {
      setQuizAttemptState({
        status: 'SUCCESS',
        error: '',
        attempts: resp
            .attempts?.sort((a1, a2) => a2.timestamp! > a1.timestamp! ? 1 : -1)
      })
    }
  }, [quizId, userId]);
  useEffect(() => {
    fetchQuizAttempt()
  }, [fetchQuizAttempt]);
  const value = React.useMemo(() => {
    return {
      ...quizAttemptState,
      fetchQuizAttempt
    }
  }, [quizAttemptState, fetchQuizAttempt]);
  return value;
}