import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Hub } from "aws-amplify";
import { useEffect, useState } from "react";

export default function useAuthState() {
  const {
    authStatus,
    user
  } = useAuthenticator();
  // const [authState, setAuthState] = useState<AuthState>();
  // const [user, setUser] = useState<any>();
  useEffect(() => {
    Hub.listen('auth', (data) => {
      console.log("data", data);
    })
    // return onAuthUIStateChange((nextAuthState, authData) => {
    //   console.log("next auth state", nextAuthState);
    //   console.log("auth data", authData);
    //   setAuthState(nextAuthState);
    //   setUser(authData);
    // });
  }, []);
  const isUserSignedIn = !!user;
  return {
    authState: authStatus,
    isUserSignedIn,
    user
  }
}