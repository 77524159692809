import ApiRequest from "../apiRequest";
import { CourseProgress } from "./getCourseProgress";

export default async function markAsCompleted(courseId: string, sectionId: string, partIds?: string[]): Promise<CourseProgress> {

  let completed = [sectionId];
  if (partIds) {
    completed = completed.concat(partIds);
  }
  try {
    let result = await ApiRequest<CourseProgress>(
      `/api/course/${courseId}/navigation`,
      'POST',
      JSON.stringify({
        courseId,
        completed
      })
    )
    return result;
  } catch (err) {
    console.error("could not mark as completed", err);
    return {
      error: "could not mark as completed",
      courseId
    }
  }
}