import ChatDocument from "../../types/ChatDocument";
import ApiRequest from "../apiRequest";


export default async function getDocumentS3Url(documentId: string): Promise<string|undefined> {


  try {
    let result: {url?: string | undefined} = await ApiRequest(
      `/api/docMetadata/${documentId}/url`,
      'GET'
    )
    return result?.url;
  } catch (err) {
    console.error("could not get document url", err);
    
    return undefined;
  }
};