import { Container, Typography } from "@mui/material";
import { Prompt } from "react-router-dom";
import Metadata from "../components/Metadata";
import ProgrammingChallenge from "../components/playground/ProgramingChallenge";
import useProgramRunnner from "../hooks/useProgramRunner";

export default function Playground() {

  const language = `NODE_LTS`;
  const programRunner = useProgramRunnner(language);

  return (
    <Container style={{
      marginTop: '2em',
      marginBottom: '2em'
    }}>
      <Prompt
        when={true}
        message="Playground code is not saved, are you sure you want to leave?"
      />
      <Metadata
        title={"Playground - Learnly"}
        description={"Use this playground to play around with any code you want."}
      />
      <div>
        <Typography variant={'h4'}>
          Playground
        </Typography>
        <Typography variant={'body1'}>
          Use this playground to play around with any code you want.
        </Typography>
        <br/>
      </div>
      <ProgrammingChallenge programRunner={programRunner}/>
    </Container>
    
  );
}