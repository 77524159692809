import { Alert, AlertTitle } from "@mui/lab";
import useProgramRunnner from "../../hooks/useProgramRunner";
import { BasePart, CodePart } from "../../types/Course";
import ProgrammingChallenge from "../playground/ProgramingChallenge";
import PartComponentProps from "./PartComponentPropType";

export default function CourseExerciseUpload({
  part: basePart
}: PartComponentProps) {
  const part = basePart as CodePart;
  const programRunner = useProgramRunnner( part?.baseLanguage || 'JAVA', part?.baseCode || '', !!part?.baseLanguage);
  if (!part) {
    return null;
  }

  let SubmissionResult = null;
  if (part.successfulOutput && programRunner.lastExecution?.result === part.successfulOutput) {
    SubmissionResult = <Alert severity="success">
      <AlertTitle><strong>Success</strong></AlertTitle>
      {part.successMessage || 'Good job! Your program output matches the expected output!'}
    </Alert>;
  } else if (part.successfulOutput && programRunner.lastExecution && !programRunner.lastExecution.errors) {
    SubmissionResult = <Alert severity="warning">Output doesn't match expectation</Alert>;
  }

  return <div>
    <ProgrammingChallenge 
    programRunner={programRunner}
    SubmissionResult={SubmissionResult}
    />
  </div>; 
}