import { Button, Container, Grid, Typography } from "@mui/material";
import NewNavBar from "../../components/NewNavBar";
import { ThemeProvider,createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        info:{
            main:'#5D4682',
            dark:'#4c396b'  
        }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1920,
      },
    },
  });


export default function FAQs(){

    return(
        <ThemeProvider theme={theme}>
        <Container maxWidth={"xl"}>
            <NewNavBar/>
            {/* body start */}
            <Container maxWidth={"lg"} style={{margin:'20px 0'}}>

                <Typography variant="h4" component="h1" style={{marginBottom:'10px'}}>Frequently Asked Questions</Typography>

                <Grid container spacing={2}>
                    <QuestionAndAnswer 
                        question={`What is Learnly?`} 
                        answer={`Learnly is an AI (Artificial Intelligence) tool which leverages the power of OpenAI’s LLM (Large Language Model) and allows our users to learn anything with structure and custom exercises and quizzes to gauge your progress.`}
                    />
                    <QuestionAndAnswer 
                        question={`How much does this cost?`} 
                        answer={`Check our prices <a href="/#pricing">here</a> to understand our unparalleled pricing, redefining the industry benchmark.`}
                    />
                    <QuestionAndAnswer 
                        question={`Is my information secure?`} 
                        answer={`Yes, we don’t share any of your information you have uploaded. We use OpenAI to generate text, they will not store any of your data that is used for personalized responses.`}
                    />
                    <QuestionAndAnswer 
                        question={`What is a Guru? `} 
                        answer={`Gurus are interactive chatbots which are created by uploading/feeding personal and specific documents/videos. The documents are limited to PDFs at the moment and videos can be uploaded in the form of YouTube URLs`}
                    />
                    <QuestionAndAnswer 
                        question={`What files can I upload?`} 
                        answer={`Currently we only support PDF documents and YouTube video links. Which are used to create custom Gurus and courses.`}
                    />
                    <QuestionAndAnswer 
                        question={`Can anyone see my created Gurus and Courses?`} 
                        answer={`No, your information stays confidential to you. Not even our administrators can see your uploaded information. Users do have the ability to confidentially share their courses with other Learnly users. `}
                    />
                    <QuestionAndAnswer 
                        question={`Do I need to create Gurus before I create courses?`} 
                        answer={`No, you do not have to create Gurus. Courses can be generated by typing any topic name as required. However, if you want to create a course from the content available in your files, then in that case you would need to create a Guru first and then create a course from it.`}
                    />
                    <QuestionAndAnswer 
                        question={`Why should I use this vs other OpenAI solutions like ChatGPT?`} 
                        answer={`Learnly, allows you to upload and interact with multiple documents at once. Learnly further allows you to create custom learning journeys through your private personal and/or organizational information. `}
                    />
                    <QuestionAndAnswer 
                        question={`What’s Next?`} 
                        answer={`Our Next Major release will allow us to integrate into organizational knowledge sources like SharePoint and/or Confluence.`}
                    />
                    <QuestionAndAnswer 
                        question={`Still require help?`} 
                        answer={`Visit our <a href="/contact-us">Contact page</a> for any aditional questions.`}
                    />
                </Grid>
            </Container>
            {/* body end */}
        </Container>
        </ThemeProvider>
    )
}


function QuestionAndAnswer({ question, answer }:{ question: string, answer: string }){
    return (
    <Grid item xs={12}>
        <div 
            style={{
                color:'#8B63CB',
                fontStyle:'italic',
            }}>
            <Typography style={{ margin:'5px 0', fontSize:'1.3rem', }}> {question} </Typography>
        </div>

        <div 
            style={{
                color:'#ffffff',
            }}>
            <Typography dangerouslySetInnerHTML={{ __html: answer }}/>
        </div>

    </Grid>
    )
}
