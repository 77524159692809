import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { DetailedHTMLProps, ImgHTMLAttributes, useState } from "react";
import getSignedUrl from "../../api/urlSigner/getSignedUrl";


export default function MarkdownImage(
  props: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
) {
  const {src, ...otherProps} = props;

  const [imageSrc, setImageSrc] = useState<string|undefined>();

  useEffect(() => {
    if (src?.startsWith('courseImage:')) {
      const fetchImage = async () => {
        const {signedUrl} = await getSignedUrl(src);
        setImageSrc(signedUrl || 'N/A');
      }
      fetchImage();
    } else {
      setImageSrc(src);
    }
  }, [src]);
  
  return !imageSrc ? <CircularProgress/> : <img
    src={src}
    {...otherProps}
  />
}