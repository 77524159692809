
import { styled, Theme } from "@mui/material/styles";
const DrawerHeader = styled("div")(({ theme }: {theme: Theme}) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end"
}));

export default DrawerHeader;