import React from "react";
import { CourseState } from "../hooks/useCourse";


interface ICourseContext extends CourseState {
  markAsCompleted: (sectionId: string, partIds?: string[]) => Promise<void>;
  fetchSection: (sectionId: string) => void,
  fetchCourse: () => Promise<void>;
  isPublic ?: boolean;
}

const CourseContext = React.createContext<ICourseContext>({
  courseId: '',
  fetchCourse: async () => {},
  fetchSection: (sectionId: string) => {},
  markAsCompleted: async (sectionId: string, partIds?: string[]) => {},
  status: 'INITAL',
  isPublic: false,
});

export default CourseContext;