import React from 'react';
// @ts-ignore
import videojs from '@mux/videojs-kit/dist/index.js';
import '@mux/videojs-kit/dist/index.css';
import './customVideo.css';

interface IVideoPlayerProps {
  options: videojs.PlayerOptions;
  playbackId: string;
  videoJWT: string;
  thumbnailJWT?: string;
  storyboardJWT?: string;
}

const initialOptions: videojs.PlayerOptions = {
  controls: true,
  fluid: true,
  controlBar: {
    volumePanel: {
      inline: false
    }
  }
};


export const VideoJS = (props: IVideoPlayerProps) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const playerRef = React.useRef<videojs.Player>();
  const {options} = props;

  React.useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;
      
      const player = playerRef.current = videojs(videoElement, {
        ...initialOptions,
        // ...options
      }, () => {
        videojs.log('player is ready');
      });

      player.src({
        src: `${props.playbackId}?token=${props.videoJWT}`,
        type: 'video/mux'
      })
      player.httpSourceSelector();
      player.timelineHoverPreviews({
        enabled: true, 
        src: `https://image.mux.com/${props.playbackId}/storyboard.vtt?token=${props.storyboardJWT}`
      });

    // You could update an existing player in the `else` block here
    // on prop change, for example:
    } else {
      // const player = playerRef.current;

      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [playerRef]);

  return (
    <video
      ref={videoRef}
      id="mux-default"
      className="video-js vjs-16-9 vjs-big-play-centered"
      preload="auto"
      width="100%"
      poster={`https://image.mux.com/${props.playbackId}/thumbnail.jpg?token=${props.thumbnailJWT}`}
    >
      {/* <source src={`${props.playbackId}?token=${props.videoJWT}`} type="video/mux" /> */}
    </video>
  );
}

export default VideoJS;