import ApiRequest from "../apiRequest";
import Course from "../../types/Course";

interface getCourseOut {
  error?: string;
  course?: Course;
}
export default async function getCourse(courseId: string, getPublic?: boolean, skipSections?: boolean): Promise<getCourseOut> {

  try {
    let result = await ApiRequest(
      `/api/${getPublic ? 'public-course' : 'course'}/${courseId}${skipSections ? '?skipSections=true' : ''}`,
      'GET'
    )

  
    return {
      // @ts-ignore
      error: result.error,
      // @ts-ignore
      course: result.course
    }
  } catch (err) {
    console.error("could not fetch course details", err);
    return {
      error: 'Unable to get course'
    }
  }
}