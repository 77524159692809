import {
  Breadcrumbs,
  Button,
  Fab,
  Grid,
  Link,
  Typography,
  Drawer,
  IconButton,
  Box,
  Toolbar,
  Theme,
  Divider,
  Container,
  useMediaQuery,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import Course, { BasePart, CourseSection } from "../../types/Course";
import CourseExerciseUpload from "./CourseExerciseUpload";
import CourseCodePlayground from "./CourseCodePlayground";
import CourseImage from "./CourseImage";
import CourseMarkdown from "./CourseMarkdown";
import CourseVideo from "./CourseVideo";
import { Link as DomLink, useHistory } from "react-router-dom";
import { Check, Menu, ChevronLeft, ChevronRight } from "@mui/icons-material";
import CourseQuiz from "./CourseQuiz";
import Metadata from "../Metadata";
import CourseContext from "../../context/CourseContext";
import { styled, useTheme } from "@mui/material/styles";
import DrawerHeader from "../DrawerHeader";
import ModuleDrawerSection from "./ModuleDrawerSection";
import HourMinText from "../HourMinText";
import { useQuery } from "@tanstack/react-query";
import getSection from "../../api/courses/getSection";
import YoutubeSearch from "./YoutubeSearch";
import ChatContext from "../../context/ChatContext";
import ChatBox from "../ChatBox";
import useUser from "../../hooks/useUser";
import AlertDialog from "../AlertDialog";
import { Done } from "@mui/icons-material";
import FeedbackButton from "./FeedbackButton";
import 'react-chat-elements/dist/main.css';

const drawerWidth = 430;

const Main = styled("main", {
  shouldForwardProp: (prop: any) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }: { theme: Theme; open?: boolean }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: "0px",
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
  }),
}));

function typeToComponent(type: BasePart["type"]) {
  switch (type) {
    case "MARKDOWN":
      return CourseMarkdown;
    case "VIDEO":
      return CourseVideo;
    case "IMAGE":
      return CourseImage;
    case "EXERCISE_UPLOAD":
      return CourseExerciseUpload;
    case "CODE":
      return CourseCodePlayground;
    case "QUIZ":
      return CourseQuiz;
    case "YOUTUBE_SEARCH":
      return YoutubeSearch;
    default:
      throw "unsupported type: " + type;
  }
}

export default function CourseSectionPage() {
  const theme = useTheme();
  const userStore = useUser();
  const isUserSignedIn = !!userStore.user;
  const history = useHistory();
  const {
    course,
    status: courseStatus,
    markAsCompleted,
    courseProgress,
    isPublic,
  } = useContext(CourseContext);
  //@ts-ignore
  const { moduleId, sectionId } = useParams();
  const module = React.useMemo(() => {
    if (!course || !course.modules) {
      return undefined;
    }
    return course.modules.find((module) => module.id === moduleId);
  }, [course, moduleId]);

  const section: CourseSection | undefined = React.useMemo(() => {
    if (!course || !course.modules) {
      return undefined;
    }
    const module = course.modules.find((module) => module.id === moduleId);
    if (!module) {
      throw "No module found";
    }
    return module?.sections.find((section) => section.id === sectionId);
  }, [course, moduleId, sectionId]);

  const [previousSectionId, nextSectionId] = React.useMemo(() => {
    if (!section || !module) return [];

    const indexOf = module.sections.indexOf(section);
    const nextSection = module.sections[indexOf + 1];
    const result = [];
    if (indexOf > 0) {
      result.push(module.sections[indexOf - 1].id);
    } else {
      result.push(null);
    }
    if (nextSection) {
      result.push(nextSection.id);
    }
    return result;
  }, [section, module]);
  const [completed, setCompleted] = useState(
    courseProgress?.completed?.[sectionId] != null
  );
  useEffect(() => {
    setCompleted(courseProgress?.completed?.[sectionId] != null);
  }, [sectionId, courseProgress?.completed]);
  const smallWidth = useMediaQuery("(max-width:920px)");

  const [mobileOpen, setMobileOpen] = React.useState(!smallWidth);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  useEffect(() => {
    if (smallWidth && mobileOpen) {
      handleDrawerToggle();
    } else if (!smallWidth && !mobileOpen) {
      handleDrawerToggle();
    }
  }, [smallWidth]);

  const handlePopUpSubmit = () => {
    history.push(
      `/edu/course/${course?.courseId}/module/${module?.id}/section/${sectionId}`
    );
  };

  const sectionQuery = useQuery(
    ["section", course?.courseId, module?.id, section?.id],
    () => {
      if (!course?.courseId || !module?.id || !section?.id) {
        return undefined;
      }

      return getSection(course.courseId, module.id, section.id, isPublic);
    }
  );

  // a memo that is a text of all parts of the section title and content (for MARKDOWN only)
  const sectionPartsContext = React.useMemo(() => {
    let parts = sectionQuery.data?.section?.parts;
    if (!parts) return "";

    let context = "";

    // add course, module, section title
    context += "Course: " + course?.title + "\n";
    context += "Module: " + module?.title + "\n";
    context += "Section: " + section?.title + "\n";

    // add all parts
    for (let part of parts) {
      if (
        part.type === "MARKDOWN" &&
        part.content &&
        !["GENERATING", "INITIAL"].includes(part.content)
      ) {
        context += part.content + "\n";
      } else if (part.title) {
        context += part.title + "\n";
      }
    }

    return context;
  }, [
    course?.title,
    module?.title,
    section?.title,
    sectionQuery.data?.section?.parts,
  ]);

  const chatContext = useContext(ChatContext);

  // set chat context for moduleMarkdownText
  useEffect(() => {
    if (!sectionPartsContext) return;
    chatContext.setContext(sectionPartsContext);
  }, [sectionPartsContext]);

  return !course ? null : (
    <>
      <Toolbar
        style={{
          position: "absolute",
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ marginLeft: "-10px", ...(mobileOpen && { display: "none" }) }}
        >
          <Menu />
        </IconButton>
      </Toolbar>
      <Container style={{minHeight:'90vh'}}>
        {isUserSignedIn && <ChatBox />}
        <Box style={{ display: "flex", }}>
          <Drawer
            anchor="left"
            variant="persistent"
            classes={{
              paper: "chat-box-scroll-style", //scrollbar styling 
            }}
            PaperProps={{
              style: {
                top: "auto",
                padding: "0.5em",
                paddingBottom: "10em",
                backgroundColor:'#101828',
                color:'white',
                borderRight:'1px solid black'
              },
            }}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            open={mobileOpen}
            onClose={handleDrawerToggle}
          >
            <DrawerHeader >
              <Typography
                variant="h5"
                
                component={DomLink}
                color="white"
                to={`/edu/course/${course.courseId}`}
              >
                {course.title}
              </Typography>
              <IconButton onClick={handleDrawerToggle}>
                {theme.direction === "ltr" ? <ChevronLeft style={{color:'white'}}/> : <ChevronRight style={{color:'white'}}/>}
              </IconButton>
            </DrawerHeader>
            <Divider style={{ marginTop: "0.5em", marginBottom: "0.5em" }} />
            {course.modules?.map((module, i) => {
              return (
                <ModuleDrawerSection
                  module={module}
                  course={course}
                  currentSectionId={section?.id}
                  indexOfModule={i}
                  courseProgress={courseProgress}
                  isPublic={isPublic}
                  key={i}
                />
              );
            })}
          </Drawer>
        </Box>
        <Main open={mobileOpen}>
          <Grid container spacing={2}>
            <Metadata
              title={(section?.title || course.title) + " - Learnly"}
              description=""
            />
            <Grid
              item
              xs={12}
              sx={{
                "@media (max-width: 1249px)": {
                  marginLeft: "2em",
                },
                ...(!isUserSignedIn &&
                  !mobileOpen && {
                    marginLeft: "2em",
                  }),
              }}
              style={{ marginTop: "1em", marginBottom: "1em" }}
            >
              <Breadcrumbs aria-label="breadcrumb" style={{color: '#5D4682'}}>
                <Link
                  component={DomLink}
                  color="primary"
                  to={`/edu/course/${course.courseId}`}
                >
                  {course.title}
                </Link>
                <Link
                  color="primary"
                  component={DomLink}
                  to={`/edu/course/${course.courseId}#${module?.id}`}
                >
                  {module?.title}
                </Link>
                <Typography color="primary">{section?.title}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                {section?.title}
                {!!section?.time && section.time > 0 && (
                  <Typography variant="caption">
                    {" "}
                    &#8212; <HourMinText seconds={section.time} />
                  </Typography>
                )}
              </Typography>
            </Grid>
            {(sectionQuery.isFetching || !sectionQuery.data) && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Grid>
            )}
            {sectionQuery.isError && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Something went wrong while getting the section
              </Grid>
            )}
            {sectionQuery.data?.section?.parts.map((part, i) => {
              const Component = typeToComponent(part.type);
              return (
                <Grid item xs={12} key={part.id}>
                  {part.title && (
                    <Typography variant="h5" style={{ marginBottom: "1em" }}>
                      {part.title}
                    </Typography>
                  )}
                  <Component
                    part={part}
                    refetchSection={() => sectionQuery.refetch()}
                  />
                </Grid>
              );
            })}

            {isUserSignedIn && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="success"
                  disabled={completed}
                  onClick={async () => {
                    if (!section || completed) return;
                    setCompleted(true);
                    await markAsCompleted(
                      section.id,
                      (sectionQuery.data?.section?.parts || section.parts)?.map(
                        (p) => p.id
                      )
                    );
                  }}
                >
                  {completed && <Check />} Mark as Completed
                </Button>
              </Grid>
            )}

            {!isUserSignedIn && (
              <Grid item xs={12}>
                <AlertDialog
                  buttonLabel="Mark as completed"
                  buttonProps={{
                    disabled: completed,
                    color: "success",
                    variant: "outlined",
                    startIcon: <Done />,
                  }}
                  onYes={handlePopUpSubmit}
                  title="Sign In"
                  confirmationText="Do you want to sign in to track your progress?"
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <span style={{display: "flex" }}>
                {previousSectionId && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    component={DomLink}
                    to={`/edu/course/${course.courseId}/module/${module?.id}/section/${previousSectionId}`}
                  >
                    Previous
                  </Button>
                )}
                {/* space btw two buttons */}
                {previousSectionId && (
                  <span style={{ marginRight: "5px" }}> </span>
                )}
                {nextSectionId && (
                  <Button
                    variant="contained"
                    color="primary"
                    component={DomLink}
                    to={`/edu/course/${course.courseId}/module/${module?.id}/section/${nextSectionId}`}
                  >
                    Next
                  </Button>
                )}

                {isUserSignedIn && (
                  <span
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <FeedbackButton
                      entityId={
                        course.courseId + "-" + module?.id + "-" + section?.id
                      }
                    />
                  </span>
                )}
              </span>

              {/* {!completed ?
            <FormControlLabel style={{marginLeft: '1em'}}
              value={completed}
              control={<Checkbox defaultChecked />} 
              label={"Mark as completed"} />
              : 
              <span>
                <Check/> Completed
              </span>
            } */}
            </Grid>
          </Grid>
        </Main>
      </Container>
    </>
  );
}
