import './ContactUs.css';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import learnlyRobot from '../../assets/robo-small.svg'
import EmailInquiry from '../../components/EmailInquiry';
import Metadata from '../../components/Metadata';
import NewNavBar from '../../components/NewNavBar';


function ContactUs() {

  return (
    <Container className="App contact-us">
      <CssBaseline />
      <Metadata
        title="Contact us - Learnly"
        description={`
          Have any questions? Reach out to us directly!
        `}
      />
      {/* Navigation */}
      <Container>
        <NewNavBar/>
      </Container>
      <div style={{
        backgroundImage: "url(" + learnlyRobot + ")",
        backgroundPosition: 'right',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        minHeight: '80vh',
      }}>
        <Container style={{textAlign: 'center', fontSize: '20px'}}>
            <h1>Contact <span style={{color:'#B7DBE7'}}>Us</span></h1>
        </Container>
        <Container className="contactForm">
          <EmailInquiry subjectPrefix="Contact Us: "/>
        </Container>
      </div>
      
     
    </Container>
  );
}

export default ContactUs;