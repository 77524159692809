import ChatCollection from "../../types/chatCollection";
import ApiRequest from "../apiRequest";

export interface CreateCollectionResponse {
  document?: ChatCollection;
}

export default async function createCollectionRequest(name: string): Promise<CreateCollectionResponse|undefined> {


  try {
    let result: CreateCollectionResponse | undefined = await ApiRequest(
      `/api/collectionMetadata`,
      'POST',
      JSON.stringify({
        collectionName:name
      })
    )
    return result as CreateCollectionResponse;
  } catch (err) {
    console.error("could not create collection", err);
    return undefined;
  }
};