import ChatDocument from "../../types/ChatDocument";
import ApiRequest from "../apiRequest";

export interface CreateDocumentResponse {
  document?: ChatDocument;
  presignedUrl?: {
    url: string;
    fields: {[key: string]: string}
  };
}

export default async function createDocumentRequest(file: File, isCollection: boolean,collectionId:string|undefined): Promise<CreateDocumentResponse|undefined> {

  // send filename, file type, length to backend
  // get back ChatDocument
  if(!isCollection){
    try {
      let result: CreateDocumentResponse | undefined = await ApiRequest(
        `/api/docMetadata`,
        'POST',
        JSON.stringify({
          filename: file.name,
          contentType: file.type,
          length: file.size
        })
      )
      return result as CreateDocumentResponse;
    } catch (err) {
      console.error("could not create document", err);
      return undefined;
    }
  }else{
    try {
      let result: CreateDocumentResponse | undefined = await ApiRequest(
        `/api/docMetadata`,
        'POST',
        JSON.stringify({
          filename: file.name,
          contentType: file.type,
          length: file.size,
          collectionId: collectionId
        })
      )
      return result as CreateDocumentResponse;
    } catch (err) {
      console.error("could not create document", err);
      return undefined;
    }
  }
};