import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Theme,Container, Typography, Hidden, Fab, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useUser from "../hooks/useUser";
import { Code, MoreVertRounded, Star } from "@mui/icons-material";
import SubscriptionContext from "../context/SubscriptionContext";
import { useContext, useState } from "react";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { Link as DomLink, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import DrawerHeader from "./DrawerHeader";
import GrainIcon from "@mui/icons-material/Grain";
import MenuIcon from "@mui/icons-material/Menu";
import { ThemeProvider } from "styled-components";
import customTheme from "./customTheme";
import NewGetStartedToSignIn from "./NewGetStartedToSignIn";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: 'rgba(16,24,40,.8)',
    backdropFilter: 'saturate(185%) blur(5px)',
    padding:'20px 0',
    height: "50px",
    display: "flex",
    justifyContent: "center",
  },
  left: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  right: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    
  },
  item: {
    display: "flex",
    color: "white",
    fontSize: "1.3em",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
    marginRight: theme.spacing(4),
    '@media (max-width: 910px)': {
      marginRight:'22px',    
    },

  },
  dropdownItem: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
    color: "white",
    fontSize: "1.3em",
  },

  subMenuItem:{
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
    color: "white",
    fontSize: "1.1em",
  },
  dropdownMenu: {
    border:'1px solid white',
    width: "275px",
    background: "rgb(31, 47, 79,0.90)",
    backdropFilter: 'saturate(185%) blur(10px)',
    display: "flex",
    justifyContent: "center",
  },
  appBarButton: {
    height:'40px',
    color: "white",
    textTransform: "none",
    width: "85px",
    border:'1px solid rebeccapurple',
    borderRadius:'3px',
    background: theme.palette.info.main,
    "&:hover": {
      background: theme.palette.info.dark,
    },
  },
  dropdownCenter: {
    justifyContent: "center",
    marginBottom: "5px",
    color:'white',
  },
  codeButton: {
    color: "white",
    background: "black",
    marginRight: "10px",
  },
  name:{
    fontSize: "1.3em",
    display: "flex",
    alignItems: "center",
    marginRight:"20px",
    minWidth:"130px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    }
    
  },
  dropdownMenuTwo:{
    border:'1px solid white',
    width: "200px",
    background: "rgb(31, 47, 79,0.90)",
    backdropFilter: 'saturate(185%) blur(10px)',
    display: "flex",
    justifyContent: "center",
    marginTop:'5px',
  },
  noBgChange:{
    justifyContent: "center",
    "&:hover": {
      background: '#1f2f4f',
      cursor:'default',
    },
  },
  list:{
    width:'100%',
  },
  noHover:{
    '&:hover, &:active': {
      backgroundColor: 'inherit', 
    },

  }
}));

export default function NewNavBar() {
  const classes = useStyles();
  const subscriptionContext = useContext(SubscriptionContext);
  const { user } = useUser();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [useCaseAnchorEl, setUseCaseAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const menuOpen = Boolean(menuAnchorEl);
  const useCaseOpen = Boolean(useCaseAnchorEl)
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUseCaseMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUseCaseAnchorEl(event.currentTarget);
  };

  const handleUseCaseClose = () => {
    setUseCaseAnchorEl(null);
  };

  const [showSubmenu, setShowSubmenu] = useState(false);
  const toggleUseCaseInMenu = () => {
    setShowSubmenu(!showSubmenu); // Toggles the submenu visibility
  };



  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} elevation={1}>
      <Container style={{maxWidth:'1920px'}}>
        <Toolbar>
          <div className={classes.left}>
            <Link component={DomLink} to={user?"/edu/home":"/"} className={classes.item}>
              <GrainIcon sx={{ fontSize: "2rem" }} />
              <Typography style={{color: 'white',width:'115px'}} variant="h6" >
              Learnly <i>edu</i>
            </Typography>
            </Link>
            <Hidden mdDown>
              <Typography>
                <Link
                  component={DomLink}
                  className={classes.item}
                  to="/edu/home"
                >
                  {" "}
                  Home{" "}
                </Link>
              </Typography>
              {/* <IconButton aria-controls="usecase-menu" aria-haspopup="true" onClick={handleUseCaseMenuClick} className={`${classes.item} ${classes.noHover}`} style={{marginRight:'25px'}}>
                Use Cases <ArrowDropDownIcon/>
              </IconButton>
              <Menu
                id="usecase-menu"
                anchorEl={useCaseAnchorEl}
                open={useCaseOpen}
                onClose={handleUseCaseClose}
                MenuListProps={{
                  "aria-labelledby": "usecase-menu",
                }}
                PaperProps={{ className: classes.dropdownMenu }}
                classes={{list:classes.list}}
              >
                  <MenuItem
                    onClick={() =>{ handleClose(); history.push("/employers")}}
                    className={classes.dropdownCenter} 
                  >
                    For Employers
                  </MenuItem>
                  <MenuItem
                    onClick={() =>{ handleClose(); history.push("/employees")}}
                    className={classes.dropdownCenter} 
                  >
                    For Employees
                  </MenuItem>
                  <MenuItem
                    onClick={() =>{ handleClose(); history.push("/students")}}
                    className={classes.dropdownCenter} 
                  >
                    For Students
                  </MenuItem>
                </Menu>
              {!subscriptionContext.isSubscribed && <Typography>
                  <Link component={DomLink} className={classes.item} to="/pricing"> Pricing </Link>
              </Typography>}
              <Typography>
                <Link
                  component={DomLink}
                  className={classes.item}
                  to="/faqs"
                >
                  {" "}
                  FAQs{" "}
                </Link>
              </Typography> */}
            </Hidden>
          </div>
          <div className={classes.right}>            
                {user && (
                  <>
                  <Button 
                    component={DomLink}
                    variant="contained"
                    className={'hide-in-mobile ' + classes.codeButton}
                    disableRipple={true}
                    to="/edu/playground">
                    <Code/>
                  </Button>
                  
                  <Typography className={classes.name}>
                    Hello {user?.admin ? "Admin" : ""} {user?.firstName}!
                    {subscriptionContext.isSubscribed && (
                        <>
                        <Star />
                        </>
                    )}
                  </Typography>
                  </>
                
                )}
            
            {!user && <Hidden mdDown>
              <Fab
                variant="extended"
                className={classes.appBarButton}
                style={{borderRadius:'30px'}}
                onClick={() => history.push("/edu/signin")}
              >
                Login
              </Fab>
                <NewGetStartedToSignIn marginLeft="30px" height="40px"/>
              
            </Hidden>}
            <Hidden mdUp>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                aria-controls={menuOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? "true" : undefined}
                onClick={handleMenuClick}
              >
                <MenuIcon sx={{ fontSize: "2rem" }} />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{ className: classes.dropdownMenu }}
                classes={{list:classes.list}}
              >
                
                <MenuItem
                  onClick={() =>{ handleClose(); history.push("/edu/home")}}
                  className={classes.dropdownCenter} 
                >
                  <Typography className={classes.dropdownItem}>
                    Home
                  </Typography>
                </MenuItem>
                {/* {!subscriptionContext.isSubscribed && <MenuItem
                  onClick={() =>{ handleClose(); history.push("/pricing")}}
                  className={classes.dropdownCenter} 
                >
                    <Typography className={classes.dropdownItem}>
                        Pricing
                    </Typography>
                </MenuItem>}
                <MenuItem onClick={() =>{ toggleUseCaseInMenu()}} className={classes.dropdownCenter} style={{marginBottom:showSubmenu? 0: '5px'}}>
                  <Typography className={classes.dropdownItem} >
                    Use Cases 
                  </Typography>
                  {showSubmenu?<ArrowDropUpIcon/>:<ArrowDropDownIcon/>}
                </MenuItem>
                {showSubmenu && (
                <div>
                  <MenuItem onClick={() => { handleClose(); history.push("/employers")}} className={classes.dropdownCenter} style={{marginBottom:0}}>
                    <Typography className={classes.subMenuItem}>
                      For Employers
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => { handleClose(); history.push("/employees")}} className={classes.dropdownCenter} style={{marginBottom:0}}>
                    <Typography className={classes.subMenuItem}>
                      For Employees
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => { handleClose(); history.push("/students")}} className={classes.dropdownCenter} >
                    <Typography className={classes.subMenuItem}>
                      For Students
                    </Typography>
                  </MenuItem>
                </div>
              )} */}
                {!user &&
                  <MenuItem
                    onClick={() =>{ handleClose(); history.push("/contact-us")}}
                    className={classes.dropdownCenter} 
                  >
                    <Typography className={classes.dropdownItem}>
                      Contact
                    </Typography>
                  </MenuItem>
                }
                {/* <MenuItem
                  onClick={() =>{ handleClose(); history.push("/faqs")}}
                  className={classes.dropdownCenter} 
                >
                  <Typography className={classes.dropdownItem}>
                    FAQs
                  </Typography>
                </MenuItem> */}

                {/* if user isnt logged in then display this */}
                {!user && <>
                  <MenuItem onClick={handleClose}  className={classes.noBgChange}>
                      <Fab
                      variant="extended"
                      className={classes.appBarButton}
                      style={{borderRadius:'30px',width:'200px',minHeight:'inherit'}}
                      onClick={() => history.push("/edu/signin")}
                    >
                      Login
                    </Fab>
                    
                  </MenuItem>
                  <MenuItem onClick={handleClose}  className={classes.noBgChange}>
                    <NewGetStartedToSignIn/>
                  </MenuItem>
                </>}

                {/* if user is logged in then display this */}
                {user && <MenuItem onClick={handleClose} className={classes.noBgChange}>
                    <Fab
                        variant="extended"
                        className={classes.appBarButton}
                        onClick={() => {history.push("/edu/profile");}}
                    >
                        My profile
                    </Fab>
                    <Fab
                        variant="extended"
                        className={classes.appBarButton}
                        onClick={() => {Auth.signOut();window.location.reload();}}
                        style={{marginLeft:'30px'}}
                    >
                        Logout
                    </Fab>
                                
                                
                    </MenuItem>}
              </Menu>
            </Hidden>
          </div>


          
          <Hidden mdDown>
            {user && (
                <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                style={{ color: "white",display: "flex",alignItems: "center", }}
                onClick={handleClick}
    
                >
                <MoreVertRounded />
                </IconButton>
            )}
          
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{ className: classes.dropdownMenuTwo }}
                classes={{list:classes.list}}
            >
                <MenuItem
                className={classes.noBgChange}
                onClick={() => {
                    handleClose();
                }}
                >
                <Fab
                    variant="extended"
                    className={classes.appBarButton}
                    onClick={() => {history.push("/edu/profile");}}
                >
                    My profile
                </Fab>
                </MenuItem>
                <MenuItem
                className={classes.noBgChange}
                onClick={() => {
                    Auth.signOut();
                }}
                >
                <Fab
                    variant="extended"
                    className={classes.appBarButton}
                    onClick={() => {window.location.reload();}}
                    style={{marginTop:'10px'}}
                >
                    Logout
                </Fab>
                </MenuItem>
            </Menu>
          </Hidden>

          
        </Toolbar>
        </Container>
      </AppBar>
      <DrawerHeader />
    </div>
  );
}
