import {Elements, CardElement} from '@stripe/react-stripe-js';
import { Alert, Button, CircularProgress, Container, Grid, Paper, Typography, styled } from '@mui/material';
import {loadStripe} from '@stripe/stripe-js';
import React, { useContext } from 'react';
import SubscriptionContext from '../context/SubscriptionContext';
import CreateSubscription from '../components/Subscription';
import Metadata from '../components/Metadata';
import { Link, useHistory } from 'react-router-dom';
import { Accessibility, Check } from '@mui/icons-material';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.NODE_ENV === 'development' ? 'pk_test_51Ih4Q9LIR5k13E3HwHg8ZcQdyHuopb4IUY1QAimldyC8GWIZHXBl31gcJnSmlxFvw0Yhw8ieZNU0TKj7xJoQBkag00A0Ycvpdy' :
  'pk_live_51Ih4Q9LIR5k13E3HngE7bqF5ttb14WFNSKGMb1AtHZLaeQWPRqBZKiZ9xsYnYlU3XSCeN3ruYoodxnU7WmiLpXvX00fKjFcOPe');

function PaymentCard() {
  const subscriptionContext = useContext(SubscriptionContext);
  const history = useHistory();
  return <Elements stripe={stripePromise}>
    <CreateSubscription onSuccess={() => {
      subscriptionContext.fetchSubscription();
      history.push("/edu/home");
    }}/>
  </Elements>
}

/**
 * A page that shows the user features that are only available with a paid plan. It allows the user to upgrade their plan to a paid plan. The features available in the paid plan are:
 * - Unlimited course creation
 * - Maximum file size of 200MB
 * - YouTube video uploads
 * - Unlimited Gurus
 * - Share courses
 * 
 * Features in the free plan:
 * - Maximum file size of 5MB
 * - Maximum of 5 courses
 * - Maximum of 5 Gurus
 * - No YouTube video uploads
 * - No sharing courses
 * 
 * The page compares the free and paid plans and allows the user to upgrade to a paid plan.
 */
export default function UpgradePlan() {
  const subscriptionContext = useContext(SubscriptionContext);

  return subscriptionContext.status === "FETCHING" ? (
    <CircularProgress className="center-circular-progress" />
  ) : (
    <Container style={{marginBottom: '1em'}}>
      <Metadata
        title="Upgrade Plan - Learnly"
        description={`
          Upgrade your plan to get access to more features!
        `}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" style={{ textAlign: "center" }}>
            Upgrade Plan
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <PricingCard>
            <Grid container style={{justifyContent: 'center'}}>
              <Grid item xs={12} sm={5} className="priceDiv">
                <p
                  style={{
                    fontSize: 50,
                    marginBottom: 0,
                    marginTop: 0,
                  }}
                >
                  $11 <span style={{ fontSize: 20 }}>USD</span>
                  <br />
                </p>
                <span>per month</span>                
              </Grid>
              <Grid className="pricingDetails" item xs={12}>
                <ul>
                  <li>
                    <Check />
                    Maximum file size of 200MB
                  </li>
                  <li>
                    <Check />
                    YouTube video uploads
                  </li>
                  <li>
                    <Check />
                    Unlimited Gurus
                  </li>
                  <li>
                    <Check />
                    Unlimited course creation
                  </li>
                  <li>
                    <Check />
                    Share courses
                  </li>
                </ul>
              </Grid>
            </Grid>
          </PricingCard>
        </Grid>
        {/* Past due */}
        <Grid item xs={12} md={6}>
          {subscriptionContext.subscriptionStatus === "past_due" && (
            <Alert severity="warning" id="subscription-warning">
              Your account is past due.{" "}
              <Link to="/edu/profile">
                Click here to update your payment method
              </Link>
            </Alert>
          )}
          {!subscriptionContext.isSubscribed && <PaymentCard />}
        </Grid>
      </Grid>
    </Container>
  );
}

const PricingCard = styled(Paper)`
  max-width: 1000px;
  margin: 0 auto;
  .priceDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #cbe9f3;
  }
  .pricingDetails {
    text-align: left;
  }
  li svg:first-child {
    color: green;
    margin-right: 10px;
  }
  .pricingDetails li {
    list-style-type: none;
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 599px) {
    .pricingDetails {
      padding: 1em;
    }
  }


`;