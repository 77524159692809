import ChatYoutubeVideo from "../../types/ChatYoutubeVideo";
import ApiRequest from "../apiRequest";

export interface CreateVideoResponse {
  document?: ChatYoutubeVideo;
  presignedUrl?: {
    url: string;
    fields: {[key: string]: string}
  };
}

export default async function createVideoRequest(file: File,video_url: string, isCollection: boolean,collectionId:string|undefined): Promise<CreateVideoResponse|undefined> {

  // send filename, file type, length to backend
  // get back ChatYouTubeVideo

  if(!isCollection){
    try {
      let result: CreateVideoResponse | undefined = await ApiRequest(
        `/api/docMetadata`,
        'POST',
        JSON.stringify({
          filename: file.name,
          contentType: 'video/youtube',
          length: file.size,
          url:video_url
        })
      )
      return result as CreateVideoResponse;
    } catch (err) {
      console.error("could not create document", err);
      
      return undefined;
    }
  }else{
    try {
      let result: CreateVideoResponse | undefined = await ApiRequest(
        `/api/docMetadata`,
        'POST',
        JSON.stringify({
          filename: file.name,
          contentType: 'video/youtube',
          length: file.size,
          url:video_url,
          collectionId:collectionId,
        })
      )
      return result as CreateVideoResponse;
    } catch (err) {
      console.error("could not create document", err);
      
      return undefined;
    }
  }
};