import {Auth} from "@aws-amplify/auth";

export default async function ApiRequest<T>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE', // add more as needed
  body?: any,
  headers?: {[key: string]: string}
): Promise<T> {
  headers = headers || {};
  headers['Content-Type'] = 'application/json';
  let jwt = '';
  try {
    jwt = (await Auth.currentSession())?.getIdToken()?.getJwtToken();
  } catch(err) {
    console.log('error getting jwt', err);
  }
  if (jwt) {
    console.log("Jwt", jwt);
    headers['Authorization'] = jwt;
  }
  const apiUrl = '';
  // // TODO: hack until rewrite urls is done
  // if (url.startsWith('/api/')) {
  //   url = apiUrl + url.substr('/api/'.length);
  // }

   if (url.startsWith('/api/')) {
    url = (process.env.NODE_ENV === 'development' ? '/test/' : '/prod/') + url.substr('/api/'.length);
  }
  const response = await fetch(url, {
    method,
    body,
    headers,
  });

  if (response.status >= 500) {
    throw "unable to complete request";
  }

  const contentType = response.headers.get("content-type");

  if (contentType && contentType.indexOf('application/json') >= 0) {
    return response.json();
  } else {
    try {
      let text = await response.text();
      // @ts-ignore
      return text as T;
    } catch(err) {
      // not sure what this is just return empty;
      // @ts-ignore
      return null;
    }
  }

}