import ChatCollection from "../../types/chatCollection";
import ApiRequest from "../apiRequest";


export default async function getCollection(collectionId: string): Promise<ChatCollection|undefined> {


  try {
    let result: ChatCollection | undefined = await ApiRequest(
      `/api/collectionMetadata/${collectionId}`,
      'GET'
    )
    return result as ChatCollection;
  } catch (err) {
    console.error("could not get collection", err);
    return undefined;
  }
};