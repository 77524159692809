import ApiRequest from "../apiRequest";
import Coupon from "../../types/coupon";

interface getCouponOut {
  error?: string;
  coupon?: Coupon;
}
export default async function getCoupon(coupon: string): Promise<getCouponOut> {

  try {
    let result = await ApiRequest(
      '/api/coupon',
      'POST',
      JSON.stringify({
        coupon,
      })
    )

  
    return {
      // @ts-ignore
      error: result.error,
      // @ts-ignore
      coupon: result.coupon
    }
  } catch (err) {
    console.error("could not fetch coupon details", err);
    return {
      error: 'Unable to validate coupon'
    }
  }
}