import { Typography } from "@mui/material";
import { Alert } from "@mui/lab";
import { Field, FieldProps } from "formik";
import { useContext, useEffect } from "react";
import CourseContext from "../../../context/CourseContext";
import useProgramRunnner from "../../../hooks/useProgramRunner";
import ProgrammingChallenge from "../../playground/ProgramingChallenge";
import QuestionComponentProps from "./QuestionComponentProps";

export default function CodeQuizQuestion({
  question,
  index,
  handleChange,
  answer,
  values,
}: QuestionComponentProps) {
  const {course} = useContext(CourseContext);
  const programRunner = useProgramRunnner(question?.baseLanguage || course?.baseLanguage || 'NODE_LTS', values.answers?.[index]?.userInputs[0]?.attemptText || question?.baseCode || '');

  useEffect(() => {
    if (answer?.userInputs?.[0].attemptText) {
      programRunner.setLanguage(answer.userInputs?.[0].codeLanguage || 'NODE_LTS');
      programRunner.setProgram(answer.userInputs?.[0].attemptText);
    }
  }, [answer?.userInputs?.[0]?.attemptText, answer?.userInputs?.[0].codeLanguage])
  useEffect(() => {
    if (handleChange) {
      handleChange(`answers[${index}].userInputs[0].codeLanguage`)(programRunner.executionArgs.languageChoice);
      handleChange(`answers[${index}].userInputs[0].attemptText`)(programRunner.executionArgs.program);
    }
  }, [
    index,
    programRunner.executionArgs.program,
    programRunner.executionArgs.languageChoice,
    handleChange
  ]);
  return (
    <>
       <Field 
        type="hidden"
        name={`answers[${index}].userInputs[0].codeLanguage`} 
        value={question.baseLanguage}>
       </Field>
      <Field name={`answers[${index}].userInputs[0].attemptText`} 
        value={programRunner.executionArgs.program}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }: FieldProps<string>) => {
          
          return (
            <>
              <div>
                <ProgrammingChallenge 
                quizMode disabled={!!answer}
                programRunner={programRunner}
                SubmissionResult={!answer ? null :
                  <>
                    <Alert severity={answer.numOfFailedTestCases === 0 ? "success" : "error"}>
                      {answer.numOfCorrectTestCases} correct test cases out of {(answer.numOfCorrectTestCases || 0) + (answer.numOfFailedTestCases || 0)}
                    </Alert>
                    {(answer.numOfFailedTestCases || 0) > 0 && answer.failedTestCase && (

                      <div>
                        <Typography variant="h6">
                          Failed Test Case
                        </Typography>
                        
                        <Typography variant="subtitle2">
                          Input
                        </Typography>
                        <div style={{
                          maxHeight: '300px',
                          background: 'rgb(240, 240, 240)',
                          overflow: 'scroll'}}>
                          <pre>
{answer.failedTestCase.input}
                          </pre>
                        </div>

                        <Typography variant="subtitle2">
                          Expected output
                        </Typography>
                        <div style={{
                          maxHeight: '300px',
                          background: 'rgb(240, 240, 240)',
                          overflow: 'scroll'}}>
                          <pre>
{answer.failedTestCase.expectedOutput}
                          </pre>
                        </div>
                        <Typography variant="subtitle2">
                          Actual output
                        </Typography>
                        <pre style={{
                          padding: '10px',
                          backgroundColor: 'rgb(240, 240, 240)',
                          border: 'red 1px solid',
                          borderRadius: '5px',
                          overflow: 'scroll'
                        }}>
                          {answer.failedTestCase?.actualOutput}
                          {answer.failedTestCase?.errors && (
                            <>
                              <h4 style={{color: 'red'}}>Errors</h4>
                              {answer.failedTestCase.errors}
                            </>
                          )}
                        </pre>
                      </div>
                    )} 
                  </>
                }
                />
              </div>
              {meta.touched && meta.error && (
                <div className="error">{meta.error}</div>
              )}
            </>
          )
        }}
      </Field>
    </>
  )
}