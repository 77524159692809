import ChatYoutubeVideo from "../../types/ChatYoutubeVideo";
import ApiRequest from "../apiRequest";


export default async function getVideo(documentId: string): Promise<ChatYoutubeVideo|undefined> {


  try {
    let result: ChatYoutubeVideo | undefined = await ApiRequest(
      `/api/docMetadata/${documentId}`,
      'GET'
    )
    return result as ChatYoutubeVideo;
  } catch (err) {
    console.error("could not get document", err);
    
    return undefined;
  }
};