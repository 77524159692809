import { Helmet } from "react-helmet";

export default function Metadata({
  title,
  description,
}: {
  title: string,
  description: string
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
}