import { BasePart, VideoPart } from "../../types/Course";
import ReactPlayer from 'react-player'
import VideoJS from "../videojs/VideoJs";
import PartComponentProps from "./PartComponentPropType";

export default function CourseVideo({
  part
}: PartComponentProps) {
  const videoPart: VideoPart | undefined = part as VideoPart;
  return (
    <div>
      {videoPart?.content ?
        <>
          {videoPart.playbackId ? 
            <>
                <VideoJS
                  key={videoPart.playbackId}
                  playbackId={videoPart.playbackId}
                  videoJWT={videoPart.videoJWT!}
                  thumbnailJWT={videoPart.thumbnailJWT}
                  storyboardJWT={videoPart.storyboardJWT}
                  options={{
                    sources: [
                      {
                        src: `${videoPart.playbackId}?token={${videoPart.videoJWT}}`,
                        type: 'video/mux',
                      }
                    ],
                    // @ts-ignore
                    // httpSourceSelector: {}
                  }}
                />
            </>
            :
            <ReactPlayer style={{
                border: 'black 1px solid',
                backgroundColor: 'black',
                maxWidth: '90vw',
              }}
              config={{ file: { 
                attributes: {
                  controlsList: 'nodownload'
                }
              }}}
              light controls url={part?.content}/>
          }
        </>
        :
        "Video unavailable"
      }
    </div>
  ); 
}