import { Fab,Theme } from "@mui/material";
import { useHistory } from "react-router";
import useUser from "../hooks/useUser";
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import { makeStyles } from '@mui/styles';

const useStyles=makeStyles((theme:Theme)=>({
    button:{
        color:'white',
        textTransform: 'none',
        width:'85px',
        background:theme.palette.info.main,
        '&:hover': {
            background:theme.palette.info.dark, 
            },
    },
}))



export default function NewGetStartedToSignIn(props:any) {
  const classes=useStyles();
  const history = useHistory();
  const userStore = useUser();
  const isUserSignedIn = !!userStore.user;
  
  const loginLink = isUserSignedIn ? "/edu/home" : "/edu/signin";

  return (
        <Fab variant="extended"
            onClick={() => history.push(loginLink)}
            className={classes.button}
            style={{
                height:props.height,
                width:'200px',
                marginLeft:props.marginLeft||'0',
            }}>
            Get started

            {props.includeArrow && <ArrowForwardTwoToneIcon/>}
        </Fab>
  );
}