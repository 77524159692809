
import { Alert, Button, CircularProgress, Container, Grid, Paper, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import {Elements, CardElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import deleteSubscription from '../api/subscription/deleteSubscription';
import AlertDialog from '../components/AlertDialog';
import Metadata from '../components/Metadata';
import CreateSubscription from '../components/Subscription';
import SubscriptionContext from '../context/SubscriptionContext';
import useUser from '../hooks/useUser';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.NODE_ENV === 'development' ? 'pk_test_51Ih4Q9LIR5k13E3HwHg8ZcQdyHuopb4IUY1QAimldyC8GWIZHXBl31gcJnSmlxFvw0Yhw8ieZNU0TKj7xJoQBkag00A0Ycvpdy' :
  'pk_live_51Ih4Q9LIR5k13E3HngE7bqF5ttb14WFNSKGMb1AtHZLaeQWPRqBZKiZ9xsYnYlU3XSCeN3ruYoodxnU7WmiLpXvX00fKjFcOPe');

function fromSubscriptionStatus(status: undefined | string) {
  if (!status) {
    return 'Unsubscribed';
  }
  switch(status) {
    case 'trialing':
      return 'Trial';
    case 'active':
      return 'Active';
    case 'incomplete':
      return 'Incomplete';
    case 'incomplete_expired':
      return 'Incomplete expired';
    case 'past_due':
      return 'Past due';
    case 'canceled':
      return 'Canceled';
    case 'unpaid':
      return 'Unpaid';
    default: 
      return 'Unsubscribed';
  }
}
export default function MyProfile() {
  const subscriptionContext = useContext(SubscriptionContext);
  useEffect(() => {
    if (subscriptionContext.isSubscribed && !subscriptionContext.includeInvoice) {
      subscriptionContext.setIncludeInvoice(true);
    }
    
  }, [subscriptionContext.isSubscribed, subscriptionContext.includeInvoice]);
  const userState = useUser();
  const [deleting, setDeleting] = useState(false);
  const cancelSubscription = async () => {
    setDeleting(true);

    try {
      const result = await deleteSubscription();
      subscriptionContext.fetchSubscription();
    } finally {
      setDeleting(false);
    }
  }
  const subscriptionStatus = subscriptionContext.subscription?.subscription.status;
  return (
    <Container>
      <Metadata
        title={"Profile - Learnly"}
        description={"Edit your profile"}
      />
            <Grid container>
              

                <Grid item xs={12} style={{marginTop: '3em'}}>
                  <Typography variant="h4" style={{marginBottom: '1em'}}>
                    Profile
                  </Typography>
                  <strong>Name:</strong> {userState.user?.firstName} {userState.user?.lastName} <br/>
                  <strong>Email:</strong> {userState.user?.email} <br/>
                  <strong>Username:</strong> {userState.user?.username} <br/>

                  <Typography variant="h4" style={{marginBottom: '1em', marginTop: '1em'}}>
                    Subscription
                  </Typography>
                  {subscriptionContext && !subscriptionContext?.isSubscribed &&
                    <Grid item xs={12} style={{marginTop: '1em', marginBottom: '1em'}}>
                      <Typography variant="subtitle2" style={{marginTop:'1em', marginBottom: '1em'}}>
                        7 days free trial for first time subscribers, the first charge will be after 7 days. Cancel anytime before to avoid charges.
                      </Typography>
                      <Elements stripe={stripePromise}>
                        <CreateSubscription onSuccess={() => subscriptionContext.fetchSubscription()}/>
                      </Elements>
                    </Grid>
                  }
                  <strong>Subscription Status:</strong> 
                  {' '} {fromSubscriptionStatus(subscriptionStatus)}
                  {subscriptionStatus === 'trialing' && (
                    <>
                    {' '} ends on {moment((subscriptionContext.subscription?.subscription?.trial_end || 0) * 1000).format('MMM D, YYYY')}
                    </>
                  )}
                  <Button 
                    component='a'
                    style={{marginLeft: '10px', marginRight: '10px'}} 
                    href={subscriptionContext.sessionUrl}
                    color="primary"
                    variant='contained'>Manage</Button>
                  {' '} {subscriptionContext.isSubscribed && (
                    <>
                      <AlertDialog
                          buttonLabel="Cancel"
                          buttonProps={{
                            disabled: deleting,
                            color: "secondary",
                            startIcon: <Delete />
                          }}
                          onYes={cancelSubscription}
                          title="Cancel subscription confirmation"
                          confirmationText="Are you sure you want to cancel your subscription?"
                          dangerousAction
                      />
                      <br/>
                      {subscriptionContext.subscriptionStatus === 'past_due' && 
                        <Alert severity="warning">You account is past due, please update your payment method immediately by clicking the "manage" button above.</Alert>
                      }
                      <br/>
                      {subscriptionContext.latestInvoice &&
                        <>
                          <Typography component={'a'} target="_blank" rel="noreferrer" href={subscriptionContext.latestInvoice.hosted_invoice_url} variant="subtitle1">
                            View last invoice
                          </Typography> of $ {(subscriptionContext.latestInvoice.total/100).toFixed(2)} USD
                          <br/>
                          <strong>Payment Date</strong> {!subscriptionContext.latestInvoice.period_start ? 'Never' : 
                          moment(subscriptionContext.latestInvoice.period_start*1000).format('MMM D, YYYY')}
                        </>
                        
                      }
                      <Typography variant="h5" style={{marginTop: '1em', marginBottom: '1em'}}>
                        Upcoming Invoice
                      </Typography>
                      {subscriptionContext.upcomingInvoices?.filter(invoice => !!invoice).map((invoice, i) =>
                        <div key={i}>
                          <Typography variant="h6">
                            Invoice
                          </Typography>
                          <br/>
                          <strong>Amount</strong> $ {(invoice.total/100).toFixed(2)} USD
                          <br/>
                          {invoice.next_payment_attempt &&
                            <>
                            <strong>Next payment Date</strong> {moment(invoice.next_payment_attempt*1000).format('MMM D, YYYY')}
                            </>
                          }
                          
                        </div>
                        
                      )}
                    </>
                  )}
                  
                  {deleting && 
                          <CircularProgress size="20px" style={{marginRight: '10px'}}/>}
                
                </Grid>
              
            </Grid>
            
    </Container>
    
  );
}