/* eslint-disable react-hooks/exhaustive-deps */

// a page where user can upload a document

import { Box, Button, CircularProgress, Container, Grid, TextField, Typography } from "@mui/material";
import { Link, useHistory, useParams } from "react-router-dom";
import createDocumentRequest, { CreateDocumentResponse } from "../api/chatDocument/createDocumentRequest";
import { Toast } from "react-toastify/dist/components";
import { toast } from "react-toastify";
import useVideoDocument from "../hooks/useVideoDocument";
import { useContext, useEffect, useState } from "react";
import ChatProvider from "../context/ChatProvider";
import ChatContext from "../context/ChatContext";
import ChatWindow from "../components/ChatWindow";
import getDocumentS3Url from "../api/chatDocument/getDocumentS3Url";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import ChatBox from "../components/ChatBox";
import { textToFile } from "../components/textToFile/textToFile";
import VideoBox from "../components/VideoBox";
import getYoutubeMeta from "../api/chatVideo/getYoutubeMeta";
import SubscriptionContext from "../context/SubscriptionContext";
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(()=>({
  
  linkText:{
    color:'black',
    marginTop:'20px',
    width:'70%',
    backgroundColor:'white',
    borderRadius:'30px',
    '@media (max-width: 900px)': {
    width:'85%',   
    },
    '& .MuiOutlinedInput-root': {
        border: 'none', // Remove the default border
        borderRadius:'30px',
      },
  },
  linkButton:{
      backgroundColor:'#5D4682',
      color:'white',
      borderRadius:'30px',
      width:'80px',
      "&:hover": {
          backgroundColor:'white',
          color:'#5D4682',
          border:'1px solid #5D4682'
        },
  },
}))
// then chat with the document
export default function ChatVideoPage() {

  const history = useHistory();

  const [file, setFile] = useState<File | null>(null);
  const [fileStatus, setFileStatus] = useState<'INITIAL' | 'PENDING_ID_CREATION' | 'UPLOAD_FILE' | 'UPLOADED' | 'ERROR'>('INITIAL');
  const [presignedDownloadUrl, setPresignedDownloadUrl] = useState<string|undefined>();
  const [presignedUploadUrl, setPresignedUploadUrl] = useState<CreateDocumentResponse['presignedUrl']|undefined>();
  const [youtubeLink, setYoutubeLink] = useState('');
  const [videoId, setVideoId] = useState<string>("");
  let subscriptionContext = useContext(SubscriptionContext);
  const [highlightFileSizeError, setHighlightFileSizeError] = useState(false);
  const [youtubeLinkError, setYoutubeLinkError] = useState<string|undefined>(undefined);
  const classes = useStyles();
  // get document id from url

  const {documentId}: {
    documentId: string
  } = useParams();

  

  const documentStore = useVideoDocument(documentId);
  const document = documentStore.document;
  const {height, width} = useWindowDimensions();

  // if document is present and vectorization status is INITIAL or pending vectorization, keep polling every 15 seconds
  useEffect(() => {
    
    if (document && ['INITIAL', 'PENDING_VECTORIZE'].includes(document.vectorizationStatus)) {
      const interval = setInterval(() => {
        documentStore.fetchDocument();
      }, 15000);
      return () => clearInterval(interval);
    }
  }, [document?.vectorizationStatus])

  // if document is present and vectorized, fetch the presigned download url
  useEffect(() => {
    async function fetchPresignedDownloadUrl() {
      if (!document) {
        return;
      }
      try {
        const presignedUrl = await getDocumentS3Url(document.id);
        setPresignedDownloadUrl(presignedUrl);
      } catch (err) {
        console.log("unable to fetch presigned download url", err);
        toast.error('unable to get document');
      }
    }
    if (document && document.vectorizationStatus === 'VECTORIZED') {
      fetchPresignedDownloadUrl();
    }
  }, [document?.vectorizationStatus])


  const extractYouTubeVideoId = (link: string): string => {
    const regex = /(?:youtube\.com\/(?:[^/]+\/[^/]+\/|(?:v|e(?:mbed)?)\/|[^#]*[?&]v=|youtu\.be\/|[^#]*[?&]v=))([^&#?/ ]{11})/;
    const match = link.match(regex);
    if (match) {
      return match[1];
    }else{
      return ""
    }
    
  }

  const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>) => {
    setYoutubeLinkError(undefined);
    setHighlightFileSizeError(false);
    setYoutubeLink(event.target.value)
    
  };

  const handleSubmit = () => {
    const id = extractYouTubeVideoId(youtubeLink);
    if (!id) {
      toast.error("Invalid YouTube link.");
      setYoutubeLinkError("Invalid YouTube link.");
      return;
    }
    setVideoId(id)
  };

  useEffect(()=>{
    if(videoId){
      toFile()
    }
    
  },[videoId])

  const toFile= async () => {
    try {
      const videoObject=await getYoutubeMeta(youtubeLink)
      if (!videoObject) {
        toast.error("Invalid YouTube link.");
        setYoutubeLinkError("Invalid YouTube link.");
        return;
      }
      const maxVideoLength = subscriptionContext.isSubscribed ?
        60 * 60 // 1 hour if subscribed
        : 5 * 60; // 5 minutes if not subscribed;
        if (videoObject.length > maxVideoLength) {
          toast.error("Video length too long.");
          setYoutubeLinkError("Video length too long. Please upload a video less than " + maxVideoLength + " seconds.");
          setHighlightFileSizeError(true);
          return;
        }
      const file = await textToFile(youtubeLink,videoId);
      // const fileContent = await readFileContent(file);
      setFile(file)
      createDocument(file)
      // Further processing or usage of the file object
    } catch (error) {
      console.error('Error creating file:', error);
    }
  }

  async function readFileContent(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        const content = reader.result;
        resolve(content);
      };
  
      reader.onerror = () => {
        reject(reader.error);
      };
  
      reader.readAsText(file);
    });
  }

  //if a documnet is received, get its id which we can use in the iframe of the video
  const idOnly = document?.filename.substring(0, document?.filename.lastIndexOf('.'));

  

  // create document function, takes in filename, length, and type, creates a document, forwards to /edu/chatdocument/:documentId
  const createDocument = async (file: File): Promise<void> => {
    // calls api to create document
    try {
      setFile(file);
      setFileStatus('PENDING_ID_CREATION');
      const documentResp = await documentStore.createDocument(file,youtubeLink,false,undefined);
      if (documentResp?.document?.id) {
        // redirect to /edu/chatvideo/:documentId
        history.push(`/edu/chatvideo/${documentResp?.document.id}`);
        setPresignedUploadUrl(documentResp.presignedUrl);
        toast.info('Uploading document');
      }
    } catch (err) {
      console.log("unable to create document", err);
      toast.error('unable to upload')
    }
  };


  useEffect(() => {
    async function uploadFile() {
      try {
        if (!presignedUploadUrl) {
          return;
        }
        documentStore.uploadFile(file as File, presignedUploadUrl);
        setFileStatus('UPLOADED');
        setPresignedUploadUrl(undefined);
      } catch (err) {
        console.log("unable to upload file", err);
        toast.error('unable to upload')
        setFileStatus('ERROR');
      }
    }

    if (file && fileStatus === 'PENDING_ID_CREATION' && documentId !== 'new' && presignedUploadUrl) {
      setFileStatus('UPLOAD_FILE');
      uploadFile();
    }

  }, [documentId, fileStatus, file, presignedUploadUrl])
  const isNew = documentId === 'new';
  const isLoading = documentStore.status === 'LOADING' 
  || documentStore.status === 'INITIAL'
  || [ 'PENDING_UPLOAD' ].includes(documentStore.document?.uploadStatus || '')
  || [ 'PENDING_VECTORIZE'].includes(documentStore.document?.vectorizationStatus || '')
  ;
  const uploadingFile =  ['PENDING_ID_CREATION', 'UPLOAD_FILE'].includes(fileStatus) ||
          ['PENDING_UPLOAD'].includes(documentStore.fileUploadStatus);
  


  return (
    
    <ChatProvider currentEntityId={documentId} mode='CHAT_DOCUMENT'>
      <Container  maxWidth={isNew ? "md" : "xl"} 
        // center the contents
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: isNew ? 'center' : 'flex-start',
            alignItems: 'center',
            minHeight: '100vh',
            marginTop:'20px',
        }}>
            <Typography variant="h3" style={{marginBottom: '1rem'}}>
              { isNew ? 'Paste a YouTube link' : documentStore.document?.title || documentStore.document?.title }
            </Typography>

            {isLoading && <CircularProgress/>}

            {isNew && <>
              <TextField
                placeholder="YouTube URL"
                value={youtubeLink}
                sx={{ boxShadow: 5 }}
                onChange={(e) => handleLinkChange(e)}
                className={classes.linkText}
                error={!!youtubeLinkError}
                InputProps={{
                  endAdornment: (
                    <Button
                      onClick={handleSubmit}
                      className={classes.linkButton}
                    >
                      Submit
                    </Button>
                  ),
                  style: { color: "black" }
                }}
              />
              {youtubeLinkError && (
                <Typography variant="caption" style={{color: '#ff5757'}} onClick={() => {}}>
                  {youtubeLinkError}
                </Typography> 
              )}
              <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                <Typography variant="caption" style={{padding: '1em'}} onClick={() => {}}>
                    {subscriptionContext.isSubscribed ? "Max video length is 1 hour" : 
                        <span>
                              <span style={{color: highlightFileSizeError ? '#ff5757' : 'inherit'}}>Max video length is 5 minutes.</span>
                            <Button
                                variant="contained"
                                style={{marginLeft: '5px', marginRight: '5px'}}
                                size="small"
                                id="upgradePlan"
                                component={Link} to="/edu/upgradePlan">Upgrade Plan</Button>
                            to upload larger videos.
                        </span>
                    }
                </Typography>
                </div>
            </>
            }
          
            {documentStore.document?.vectorizationStatus === 'PENDING_VECTORIZE' 
            && documentStore.document?.uploadStatus === 'UPLOADED'
            && <div>Processing video</div>
            }
            {documentStore.document?.vectorizationStatus === 'VECTORIZED' &&
              <Grid container spacing={2} style={{marginTop: '1rem'}}>
                
                {width >= 900 && ( <>
                  <Grid item xs={12} md={6}>
                    <Box style={{display: 'flex', minHeight: '350px'}}>
                      <ChatWindow/>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box  style={{display: 'flex', minHeight: '350px'}}>
                      <VideoBox id={idOnly || videoId} />
                    </Box>
                  </Grid>
                  
                  </>)}
                {width < 900 && (<>
                  <Grid>
                    <Box display="flex" minHeight="350px">
                        <ChatWindow/>
                      </Box>
                      <Box display="flex"  marginLeft="16px" marginTop="20px">
                        <VideoBox id={idOnly || videoId}/>
                      </Box>
                  </Grid>
                </>)
                }
              </Grid>
            }
      </Container>
    </ChatProvider>
  )
}

