// probably should reverse the name with current ChatBox

// shows the chat as block that will be in the page
import { Error, Send } from '@mui/icons-material';
import { Button, CircularProgress, FormHelperText, TextField } from '@mui/material';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { MessageBox, Input } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import ChatContext, { Message } from '../context/ChatContext';

// interface MessageBoxProps {
//   position: 'left' | 'right';
//   text: string;
// }

// function MessageBox({
//   position,
//   text,
// }: MessageBoxProps) {
//   return (
//     <div className={`message-box message-${position}`}>
//       <div className="message-box-content">{text}</div>
//     </div>
//   );
// }


const ChatWindow = () => {

  const chatContext = useContext(ChatContext);

  const [text, setText] = useState('');
  const [textError, setTextError] = useState('');

  const validateText = (text: string) => {
    // max limit of 250 characters
    if (text.length > 250) {
      setTextError('Text must be less than 250 characters');
      return false;
    }

    // verify not blank
    if (text.trim().length === 0) {
      setTextError('Text cannot be blank');
      return false;
    }

    // make sure there is no forbidden characters
    if (!/^[a-zA-Z0-9:'.,? \n\t]*$/.test(text)) {
      setTextError('Text can only contain letters, numbers, and the following characters: \'.,?');
      return false;
    }


    setTextError('');
    return true;
  };

  // 

  const handleTextChange = (text: string) => {
    setText(text);
    validateText(text);
  };

  const onSend = () => {
    let message = text?.trim() || '';
    if (message && validateText(message)) {
      chatContext.sendMessage(message);
      setText('');
    }
  };

  const getMessagePosition = useMemo(() => (message: Message) => {
    if (message.user.id === chatContext.currentUserId) {
      return 'right';
    } else {
      return 'left';
    }
  }, [chatContext.currentUserId]);


  // end of messages ref
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    // @ts-ignore
    // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    let chatWindow = document.getElementById('chat-window'); 
    if (!chatWindow) return;
    let xH = chatWindow.scrollHeight; 
    chatWindow.scrollTo(0, xH);
  };
  // scroll to bottom on new message
  useEffect(() => {
    scrollToBottom();
  }, [chatContext.messages]);


  return (
    <div className={``} style={{display: 'flex', flex: 1, flexDirection: 'column',boxShadow: '0 0 19px 7px #5D4682',
    borderTopRightRadius:'8px',borderTopLeftRadius:'8px',border:'2px solid #5D4682'}}>

      <div id="chat-window" style={{flex: 1, maxHeight: '70vh', backgroundColor: '#171F30', opacity: 1, overflowX: 'hidden', overflowY: 'scroll',
      borderTopRightRadius:'8px',borderTopLeftRadius:'8px',paddingTop:'10px'}}
       className="chat-box-scroll-style">
        {chatContext.messages.map((message) => (
          <>
            <MessageBox
              key={message.id}
              // @ts-ignore
              position={getMessagePosition(message)}
              type="text"
              text={message.text}
              // light blue shade for background where user.id === "bot"
              className="chat-box-message"
              styles={{
                backgroundColor: message.sendStatus === 'ERROR' && message.user.id !== "bot" ? '#21345f' :
                message.user.id === 'bot' ? '#172543' : '#21345f',
              }}
              />
            {message.sendStatus === 'ERROR' && message.user.id !== "bot" && (
              <div style={{color: 'red', display: 'flex', justifyContent: 'right', alignContent: 'center', fontSize: '11px', paddingRight: '1em'}}>
                <Error fontSize={'small'}/> Error sending message
              </div>
            )}
          </>
          
        ))}
        <div style={{
          // display center
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          {(chatContext.status === 'LOADING'
           // or last message sendStatus is LOADING
            || (chatContext.messages.length > 0 && chatContext.messages[chatContext.messages.length - 1].sendStatus === 'LOADING'))
          && <CircularProgress size="20px" />}
          <div style={{ float:"left", clear: "both" }}
             ref={messagesEndRef}>
        </div>
        </div>
        
      </div>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor:'white',
      borderTop:'2px solid #5D4682',
    }}>
      <div style={{flex: '1'}}>
        <textarea
          rows={2}
          wrap="soft"
          // cols={100}
          value={text}
          placeholder='Type a message...'
          onChange={(e) => handleTextChange(e.target.value)}
          style={{
            resize: 'none',
            width: '100%',
            border: 'none',
            outline: 'none',
            padding: '10px',
            fontSize: '13px',
            fontFamily: 'inherit',
          }}
          // add enter key to send except when shift is pressed
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              onSend();
            }
          }}
        />
        {textError && (
          <FormHelperText error>{textError}</FormHelperText> 
        )}
      </div>
      
      <Button disabled={!!textError} onClick={onSend}><Send/></Button>
    </div>
      
      
    </div>
  );
};

export default ChatWindow;