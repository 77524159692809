import ApiRequest from "../apiRequest";
import { Quiz } from "../../types/Quiz";

interface getAllQuizOut {
  error?: string;
  quizes?: Quiz[];
}
export default async function getAllQuizMetadata(): Promise<getAllQuizOut> {

  try {
    let result = await ApiRequest(
      '/api/quiz',
      'GET'
    )

  
    return {
      // @ts-ignore
      error: result.error,
      // @ts-ignore
      quizes: result.quizes
    }
  } catch (err) {
    console.error("could not fetch all quiz details", err);
    return {
      error: 'Unable to get all quiz'
    }
  }
}