import { Button, CircularProgress, Container, Grid, Paper, Typography } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useHistory, Link } from "react-router-dom";
import Metadata from '../components/Metadata';
import Course from '../types/Course';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import FeedbackButton from '../components/course/FeedbackButton';


const aTheme = createTheme({
  palette:{
    primary:{
      main:'#5D4682'
    },

  }
});


const useStyles=makeStyles(()=>({
  initialContainer:{
    display:'flex',
    flexDirection:'column',
    maxWidth:'615px',
    margin:'120px 0',
    padding:'20px 0', // more clicking area
  },
  initialContainerMainHeading:{
    color:'#F5F5F5',
    fontSize:'3em',
    '@media (max-width: 501px)': {
        fontSize:'2em',
      }, 
  },
  initialContainerSecondaryHeading:{
      color:'#8B63CB',
      fontSize:'1.2em',
      marginBottom:'20px',
  },
  initialContainerExtraText:{
      color:'#979797',

  },
  upperDiv:{
    display:'flex',
    fontSize:'8px',
    height:'25px',
    justifyContent: 'space-between',
    margin:'5px 0',
  },
  dateText:{
    color:'black',
    height:'25px',
    marginTop:'4px',
  },
  mainHeadingDiv:{
    margin:'8px 0 5px',
    textAlign:'left',
  },
  mainHeading:{
    fontSize:'1rem',
    lineHeight:'1.2',
    fontWeight:'600',
  },
  captionDiv:{
    paddingTop:'5px',
  },
  caption:{
    color:'#8B63CB',
    width:'100%',
    verticleAlign:'center',
    fontSize: '0.7rem',
    display: 'inline-block',
    lineHeight: '1.3',
    letterSpacing: '0.01111em',
    textAlign:'left',
  },
  lowerDiv:{
    display:'flex',
    fontSize:'8px',
    height:'25px',
    justifyContent: 'space-between',
    alignItems:'center',
    position: 'absolute',
    bottom: '15px',
    width: '90%',
  },
  continueButton:{
    backgroundColor:'#0F1D47',
    color:'#ffffff',
    fontSize:'10px',
    borderRadius:'25px',
    width:'105px',
    padding:'2px',
    '&:hover': {
      backgroundColor:'#040915',
      },
  },
  addMainHeading:{
    fontSize:'1.4rem',
    lineHeight:'1.2',
    fontWeight:'600',
    margin:'3px 0 10px'
  },
  addCaption:{
    color:'#8B63CB',
    fontSize:'0.9rem',
    display: 'inline-block',
    lineHeight: '1.3',
  },
}))

interface CourseMainPageProps {
  myCourses: Course[];
  myCoursesLoading: boolean;
}

export default function CourseMainPage(props: CourseMainPageProps) {
  const classes=useStyles();
  const history = useHistory();
  const hasCourses = props.myCourses && props.myCourses.length > 0;
  
  return (
    <ThemeProvider theme={aTheme}>
      {(!hasCourses)    ? 
      <div style={{cursor:'pointer',display:'flex',justifyContent:'center'}} onClick={() => history.push('/edu/course/new')}>
        <Container className={classes.initialContainer} >
            <div>
                <AddCircleOutlineOutlinedIcon style={{fontSize:'40px',stroke:'#101828',strokeWidth:'1'}}/>
            </div>
            <div>
                <Typography className={classes.initialContainerMainHeading}>Create Your First Course!</Typography>
                <Typography className={classes.initialContainerSecondaryHeading}>Generate AI powered courses to learn anything you want.</Typography>
                <Typography className={classes.initialContainerExtraText}>Create courses from any topic or from an existing Guru (multiple PDFs and YouTube links)</Typography>
            </div>
        </Container>
      </div>
      :
      <Container style={{padding:0, maxWidth:'1920px'}}>
          <Metadata
          title={"Learnly edu"}
          description={"Start working on your programming journey today!"}
        />
              <Grid container style={{ minHeight: '600px' }} >
                <Grid item xs={12} style={{minHeight: '4em', marginTop: '2em'}} >
                  <Grid  container spacing={4} style={{display:'flex', justifyContent:'center'}}>
                    <Grid item>
                      <InitialCourseCard  link={"/edu/course/new"}/>
                    </Grid>
                  {/* Display loading indicator for myCourses and then display my courses once loaded */}
                  {props.myCoursesLoading && <CircularProgress />}
                  {hasCourses && props.myCourses.map((course,index) => {
                    return (
                      <Grid item>
                      <CourseCard key={index} title={course.title} description={course.description} createdOn={course.createdOn||''} fromCollection={!!course.collectionId} courseId={course.courseId} link={`/edu/course/${course.courseId}`}/>
                      </Grid>
                    )
                  })}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} style={{marginTop: '3em', padding:'0 16px'}}>
                  <Typography variant="h4">
                    Learnly developer bootcamp
                  </Typography>
                  <Typography variant="subtitle1">
                    A paid bootcamp that is currently in pilot mode. It focuses on teaching fundamentals of computer science and problem solving.
                  </Typography>
                  <Button component={Link} style={{margin: '0.5em'}} to="/edu/bootcamp" variant="text">See bootcamp courses</Button>
                </Grid>

              </Grid>
              
      </Container>
    }
    </ThemeProvider>
  );
}

function CourseCard({title, description, createdOn, fromCollection, courseId, link}: {title: string, description: string, createdOn: string, fromCollection: boolean, courseId: string, link: string}) {
  const classes=useStyles()

  //date formating
  const inputDate: Date = new Date(createdOn);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate: string = inputDate.toLocaleDateString(undefined, options);

  if(description.length>150){
    description=description.substring(0, 147) + '...';
  }
  if(title.length>55){
    title=title.substring(0, 55) + '...';
  }

  return (
    <Paper elevation={3} style={{
      width: '300px',
      height: '195px',
      padding: '10px 15px',
      backgroundColor:'white',
      color:'#211F54',
      borderRadius:'20px',
      textTransform: 'none',
      position:'relative',
    }}>
      <div className={classes.upperDiv}>
        <div>
          <p className={classes.dateText}>Created On: {formattedDate}</p>
        </div>
        {fromCollection && 
          <div>
            <div style={{border:'1px solid #0F1D47',color:'#0F1D47', borderRadius:'10px', width:'135px',fontSize:'10px'}}>
              Created From Guru
            </div>
          </div>
        }
      </div>
      <div className={classes.mainHeadingDiv}>
        <Typography variant="h6" component="h1" className={classes.mainHeading}>{title}</Typography>
      </div>
      <div className={classes.captionDiv}>
        <Typography variant="caption" className={classes.caption}>{description}</Typography>
      </div>
      <div className={classes.lowerDiv}>
        <div>
          <FeedbackButton entityId={courseId}/>
        </div>
        <div>
          <Button 
          variant='contained' 
          component={Link} to={link} 
          className={classes.continueButton}
          endIcon={<ArrowRightAltIcon/>}
          >
            Continue
          </Button>
        </div>
      </div>

    </Paper>
  )
}

export function InitialCourseCard({link}:{ link: string }){
  const classes=useStyles()
  return (
    <Button component={Link} to={link} style={{padding:0, margin:0}}>
      <Paper elevation={3} style={{
        width: '300px',
        height: '195px',
        padding: '10px 15px',
        backgroundColor:'white',
        color:'#211F54',
        borderRadius:'20px',
        textTransform: 'none',
        display:'flex',
        alignItems:'center',
      }}>
        <div>
          <div>
              <AddCircleOutlineOutlinedIcon style={{fontSize:'60px',strokeWidth:'0'}}/>
          </div>
          <div>
            <Typography variant="h6" component="h1" className={classes.addMainHeading}>Create a course</Typography>
          </div>
          <div>
            <Typography variant="caption"  className={classes.addCaption}>Generate AI powered courses to learn anything you want.</Typography>
          </div>
        </div>
      </Paper>
    </Button>
  )
}