import ApiRequest from "../apiRequest";
import Course from "../../types/Course";

export interface CourseProgress {
  error?: string;
  courseId: string;
  completed?: {
    [key: string]: string;
  }
}
export default async function getCourseProgress(courseId: string): Promise<CourseProgress> {

  try {
    let result = await ApiRequest<CourseProgress>(
      `/api/course/${courseId}/navigation`,
      'GET'
    )
    return result;
  } catch (err) {
    console.error("could not fetch course details", err);
    return {
      error: 'Unable to get course',
      courseId
    }
  }
}