import { Button, CircularProgress, Divider, Typography } from "@mui/material";
import { Cancel, Done, Save, Warning } from "@mui/icons-material";
import { Alert } from "@mui/lab";
import { Formik } from "formik";
import { useState } from "react";
import { useEffect } from "react";
import { FunctionComponent, useMemo } from "react";
import { Prompt } from "react-router-dom";
import postQuizAttempt from "../../../api/quiz/postQuizAttempt";
import useQuiz from "../../../hooks/useQuiz";
import { AnswerInput, AttemptAnswer, QuizAttempt, QuizQuestion } from "../../../types/Quiz";
import AlertDialog from "../../AlertDialog";
import MarkdownToHTML from "../../MarkdownToHTML";
import CodeQuizQuestion from "./CodeQuizQuestion";
import MCQMultiQuestion from "./MCQMultiQuestion";
import MCQSingleQuestion from "./MCQSingleQuestion";
import QuestionComponentProps from "./QuestionComponentProps";
import TextQuizQuestion from "./TextQuizQuestion";

function getQuestionRenderer(questionType: QuizQuestion['answerType']): FunctionComponent<QuestionComponentProps> {
  switch(questionType) {
    case 'CODE':
      return CodeQuizQuestion;
    case 'MCQ_MULTI':
      return MCQMultiQuestion;
    case 'MCQ_SINGLE':
      return MCQSingleQuestion;
    case 'TEXT':
      return TextQuizQuestion;
    default:
      alert('Unsupported question type: ' + questionType);
      throw 'unsupported question'
  }
}

export default function AttemptQuiz({
  quizId,
  onFinish,
  previousAttempt
}: {
  quizId: string,
  previousAttempt?: QuizAttempt,
  onFinish: () => void
}) {

  const quizState = useQuiz(quizId);
  const [attempt, setAttempt] = useState<QuizAttempt|undefined>();
  const [lastSaved, setLastSaved] = useState<string|undefined>();
  const [initialAttempt, setInitialAttempt] = useState<QuizAttempt>({
    assessmentType: 'EXERCISE',
    status: 'IN_PROGRESS',
    quizId,
    answers: []
  });
  useEffect(() => {
    if (previousAttempt) {
      setInitialAttempt(previousAttempt);
      if (previousAttempt.assessmentType !== 'EXERCISE' || previousAttempt.status !== 'IN_PROGRESS')
        setAttempt(previousAttempt);
    }
  }, [previousAttempt]);
  const [sumbissionError, setSubmissionError] = useState<string|undefined>(undefined);

  const answerByQuestionId: {
    [key: string]: AttemptAnswer
  } = useMemo(() => {
    if (!attempt || !attempt.answers) {
      return {};
    }
    return attempt.answers.reduce((obj, ans) => {
      // @ts-ignore     
      obj[ans.questionId] = ans;
      return obj;
    }, {});
  }, [attempt]); 
  useEffect(() => {

    if (!quizState.quiz || previousAttempt) {
      return;
    }
    setInitialAttempt({
      assessmentType: quizState.quiz.assessmentType,
      status: 'IN_PROGRESS',
      quizId,
      answers: 
        quizState
          .quiz
          .questions
          .map(question => ({
            questionId: question.id,
            userInputs: question.answerType === 'MCQ_MULTI' ?
                question
                  .options
                  ?.map(option => ({
                    optionId: option.id,
                    attemptText: '',
                    selected: false,
                  })) :
                  [{
                    optionId: '',
                    attemptText: '',
                    codeLanguage: question.baseLanguage,
                    fileUrl: ''
                  }]
            
          }))
    });
  }, [quizId, quizState.quiz, previousAttempt]);
  if (quizState.status === 'FETCH_ERROR') {
    return <p>{'Unable to get quiz: ' + quizState.error}</p>;
  }

  const assessmentName = quizState.quiz?.assessmentType === 'QUIZ' ? 'Quiz' : 'Exercise';
  return quizState.status === 'FETCHING' ? <p>Loading</p> : (
    <div >
      <Divider style={{marginTop: '1em', marginBottom: '1em'}}/>
      <Typography variant="h5" style={{marginBottom: '1em'}}>
        {assessmentName}: {quizState.quiz?.title}
      </Typography>

      <Formik
        initialValues={initialAttempt}
        enableReinitialize={true}
        onSubmit={async (values) => {
          setSubmissionError(undefined);
          console.log("Submitting quiz attempt", values);
          const resp = await postQuizAttempt({
            ...values,
            timestamp: undefined, // let backend figure out timestamp
            status: 'COMPLETED'
          });
          if (resp.attempt) {
            setAttempt(resp.attempt);
          } else {
            setSubmissionError('Was not able to submit quiz');
          }
          console.log("Response " + JSON.stringify(resp));
        }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            dirty
          }) => (
            <>
              {
                quizState.quiz?.questions
                  .map( (question, i) => {
                    const QuestionAnswerOption = getQuestionRenderer(question.answerType);
                    const answerToQuestion = answerByQuestionId[question.id];
                    const questionWeight = question.weight || 1;
                    return (
                      <div key={question.id}>
                        <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}>
                          {(quizState.quiz?.questions?.length || 0) > 1 ? `Question ${i + 1}` : ''} {answerToQuestion && (
                            <>
                              {answerToQuestion.score === questionWeight &&
                                <Done style={{color: 'green', marginLeft: '0.2em'}}/>
                              }
                              {(answerToQuestion.score && answerToQuestion.score !== questionWeight) ?
                                <Warning style={{color: 'orange', marginLeft: '0.2em'}}/> : null
                              }
                              {(!answerToQuestion.score || answerToQuestion.score === 0) &&
                                <Cancel style={{color: 'red', marginLeft: '0.2em'}}/>
                              }
                            </>
                          )} 
                        </Typography>
                        <Typography variant="subtitle2">
                          {answerToQuestion && `${(answerToQuestion.score || 0) % 1 !== 0 ? (answerToQuestion.score || 0).toFixed(2) : answerToQuestion.score} /`} ({questionWeight} points)
                        </Typography>
                        <MarkdownToHTML content={question.problemStatement}/>
                        <div>
                          {answerToQuestion?.feedback &&
                            <Alert severity="info">{answerToQuestion.feedback}</Alert>
                          }
                          {answerToQuestion?.rightFeedback &&
                            <Alert severity="success">{answerToQuestion.rightFeedback}</Alert>
                          }
                          {answerToQuestion?.wrongFeedback &&
                            <Alert severity="error">{answerToQuestion.wrongFeedback}</Alert>
                          }
                        </div>
                        <QuestionAnswerOption
                         handleChange={handleChange}
                         question={question}
                         index={i}
                         values={values}
                         answer={answerToQuestion}/>
                        <Divider style={{marginTop: '1em', marginBottom: '1em'}}/>
                      </div>
                    )
                  })
              }
              {attempt && attempt.score !== undefined &&
                <span style={{marginRight: '0.5em'}}>
                  <strong>Score:</strong> {`${attempt.score % 1 === 0 ? attempt.score : attempt.score?.toFixed(2)}/${attempt.maxScore}`}
                </span>
              }
              {sumbissionError &&
                <Alert severity="error">{sumbissionError}</Alert>
              }
              <Prompt
                when={dirty && !attempt}
                message={"Your progress is not saved, are you sure you want to leave?"}
              />
              {!attempt && values.assessmentType === 'QUIZ' &&
               <AlertDialog
                  buttonLabel="Submit Quiz"
                  buttonProps={{
                    disabled: isSubmitting,
                    color: "primary",
                    variant: "contained",
                    startIcon: <Done />
                  }}
                  onYes={handleSubmit}
                  title="Submit Quiz"
                  confirmationText="Are you sure you want to submit this quiz?"
                />
              }
              {!attempt && values.assessmentType === 'EXERCISE' &&
                <>
                  <Button
                    onClick={async () => {
                      await postQuizAttempt({
                        ...values,
                        timestamp: undefined, // let backend figure out timestamp
                        status: 'IN_PROGRESS'
                      });
                      setInitialAttempt({
                        ...values,
                        status: 'IN_PROGRESS'
                      })
                    }}
                  >
                    Save 
                  <Save/>
                  </Button>
                  <Button
                    style={{
                      marginLeft: '0.5em'
                    }}
                    disabled={isSubmitting}
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit()}
                  >
                    Submit Exercise
                    {isSubmitting &&
                      <CircularProgress size={10} />
                    }
                  </Button>


                </>
               
              }
              {attempt &&
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setAttempt(undefined);
                    }}
                  >
                    Retry {assessmentName}
                  </Button>
                  {values.assessmentType === 'QUIZ' && 
                    <Button
                      color="primary"
                      style={{marginLeft: '1em'}}
                      variant="outlined"
                      onClick={() => {
                        onFinish();
                      }}
                    >
                      Close {assessmentName}
                    </Button>
                  }
                </>
              }
            </>
          )}
        </Formik>
    </div>
  );
}