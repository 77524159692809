import { DetailedHTMLProps, HTMLAttributes, ReactNode, useState } from "react";
import ReadOnlySyntaxHighlighter from "../ReadOnlySyntaxHighlighter";


export default function MarkdownCode(
  props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
) {
  let children: ReactNode[];
  if (!props.children 
    || !Array.isArray(props.children) 
    || (children = props.children as Array<ReactNode>).length !== 1
    ) {
    return <pre>
      {props.children}
    </pre>
  }

  return <ReadOnlySyntaxHighlighter
    // @ts-ignore
    codeContent={children[0].props.children}
    language="javascript"/>
}