import { Card, CardContent, Link, Typography } from "@mui/material";
import { Link as DomLink } from "react-router-dom";


interface ActionTileProps {
  title: string;
  link: string;
  description?: string;
}
export default function ActionTile(
  {
    title,
    link,
    description
  }: ActionTileProps
) {
  return (
    <Link component={DomLink} to={link} style={{
    }}>
      <Card style={{ 
          margin: '0.5em',
          width: '15em',
          display: 'inline-block',
          height: '10em'
        }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Link> 
  );
}