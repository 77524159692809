export default function HourMinText({
  seconds
}: {
  seconds: number
}) {

  let min = Math.ceil(seconds/60);
  let hours = Math.floor(min/60);
  min = min % 60;

  return <>
    {`${hours > 0 ? `${hours} h ` : ''}${min > 0 ? `${min} min` : ''}`}
  </>
}