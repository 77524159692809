
import { Alert, Button, CircularProgress, Container, Grid, Paper, Typography } from '@mui/material';
import {Elements, CardElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import CreateSubscription from '../components/Subscription';
import SubscriptionContext from '../context/SubscriptionContext';
import DiscordLogo from "../assets/discord_logo.svg";
import Metadata from '../components/Metadata';
import ReactGA from 'react-ga';
import StartTrialButton from '../components/subscription/StartTrialButton';
import { getMyCourses } from '../api/courses/getMyCourses';
import Course from '../types/Course';
import '../styles/CircularProgress.css'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.NODE_ENV === 'development' ? 'pk_test_51Ih4Q9LIR5k13E3HwHg8ZcQdyHuopb4IUY1QAimldyC8GWIZHXBl31gcJnSmlxFvw0Yhw8ieZNU0TKj7xJoQBkag00A0Ycvpdy' :
  'pk_live_51Ih4Q9LIR5k13E3HngE7bqF5ttb14WFNSKGMb1AtHZLaeQWPRqBZKiZ9xsYnYlU3XSCeN3ruYoodxnU7WmiLpXvX00fKjFcOPe');

export default function DeveloperBootcampHome() {
  const subscriptionContext = useContext(SubscriptionContext);

  // fetch my coureses using a hook

  const [myCourses, setMyCourses] = React.useState<Course[]>([]);
  const [myCoursesLoading, setMyCoursesLoading] = React.useState(true);
  const hasCourses = myCourses && myCourses.length > 0;
  const noAvailableCourses = !myCoursesLoading && !hasCourses;
  React.useEffect(() => {
    async function fetchMyCourses() {
      const courses = await getMyCourses();
      setMyCourses(courses);
      setMyCoursesLoading(false);
    }
    fetchMyCourses();
  }, []);

  return subscriptionContext.status === 'FETCHING' ? <CircularProgress className="center-circular-progress"/>
    :
  (
    <Container>
        <Metadata
        title={"Learnly edu"}
        description={"Start working on your programming journey today!"}
      />
            <Grid container>
              {/* <Grid item xs={12} style={{marginTop: '3em'}}>
                <Typography variant="h4">
                  Learnly developer bootcamp
                </Typography>
                <Typography variant="subtitle1">
                  A paid bootcamp that is currently in pilot mode. It focuses on teaching fundamentals of computer science and problem solving.
                </Typography>
              </Grid> */}


              {false && !subscriptionContext.isSubscribed &&
                <Grid item xs={12} style={{marginTop: '2em'}}>
                  <Typography variant="h5">
                    Start your subscription 
                  </Typography>
                  <Typography variant="subtitle2" style={{marginTop:'1em', marginBottom: '1em'}}>
                    {subscriptionContext.subscriptionStatus === 'past_due' && 
                       <Alert severity="warning" id="subscription-warning">
                          Your account is past due.
                          {' '}<Link to="/edu/profile">Click here to update your payment method</Link>
                        </Alert>
                    }
                    {/* <b>7 days free</b> trial for first time subscribers, the first charge will be after 7 days. Cancel anytime before to avoid charges. */}
                  </Typography>
                  {!subscriptionContext.subscriptionStatus  && 
                    <>
                      <Typography variant="subtitle1">
                        Start your 7 day trail <b>without</b> entering credit card details <StartTrialButton/>
                      </Typography>
                      <Typography variant="h6" style={{marginBottom: '1em', marginTop: '1em'}}>
                        OR apply a coupon code below
                      </Typography>
                    </>
                  }
                  
                  <Elements stripe={stripePromise}>
                    <CreateSubscription onSuccess={() => {
                      subscriptionContext.fetchSubscription();
                     
                    }}/>
                  </Elements>
                  <Typography style={{marginTop: '1em'}} variant="body1">
                    For any questions, <a href="https://calendly.com/zaidq" target='_blank' rel="noreferrer">schedule a call 🤙🏽
                      </a>
                  </Typography>
                  
                </Grid>
              }
               <>
                {/* <Grid item xs={12}>
                  <Typography variant="h5" style={{
                    display: 'flex',

                    alignItems: 'center',
                    marginTop: '1em',
                    marginBottom: '0.5em'}}>
                    Join the <a rel="noreferrer" href="https://discord.gg/eaJFWSHExA" target="_blank"><img style={{
                      maxHeight: '40px',
                      marginLeft: '0.5em'
                    }} src={DiscordLogo} alt="Discord logo"/></a>
                  </Typography>

                  All updates regarding the platform and course is posted on the discord. Join by clicking this <a rel="noreferrer" href="https://discord.gg/eaJFWSHExA" target="_blank">link</a>.
                </Grid>
                <Grid item xs={12}>
                  <Link to="/edu/shareYourProgress">
                    <Typography variant="h5" style={{
                      display: 'flex',

                      alignItems: 'center',
                      marginTop: '1em',
                      marginBottom: '0.5em'}}>
                      Share your progress 🚀🚀🚀
                    </Typography>
                  </Link>
                  <Typography variant="h6" style={{
                    display: 'flex',

                    alignItems: 'center',
                    marginTop: '1em',
                    marginBottom: '0.5em'}}>
                    👆👆 Super important if you want to increase your chances of landing a job
                  </Typography>
                </Grid> */}
                <Grid item xs={12}>
                  <Typography variant="h5" style={{
                    display: 'flex',

                    alignItems: 'center',
                    marginTop: '1em',
                    marginBottom: '0.5em'}}>
                    👋 New or have any questions?
                  </Typography>
                  <Typography variant="body1" style={{
                    alignItems: 'center',
                    marginTop: '1em',
                    marginBottom: '0.5em'}}>
                      If your are new or have any questions, feel free to reach out to me on email: <a href="mailto:zaid@learnly.dev">zaid@learnly.dev</a>
                      {/* or <a href="https://calendly.com/zaidq" style={{marginLeft: '5px'}} target='_blank' rel="noreferrer">schedule a call 🤙🏽 */}
                      {/* </a> */}
                  </Typography>
                </Grid>
                <Grid item xs={12}>

                  <Typography style={{marginTop: '1em', marginBottom: '1em'}} variant="h4">
                    Courses
                  </Typography>

                  
                  
                  <Button component={Link} to="/edu/course/intro-to-comp-sci-1-js">
                    <Paper elevation={3} style={{
                      maxWidth: '600px',
                      padding: '10px'
                    }}>
                      <Typography variant="h6" component="h1">
                        Introduction to Computer Science (JS)
                      </Typography>
                      <Typography variant="caption">Building blocks of computer science & software development.</Typography>
                    </Paper>
                  </Button>

                  {/* <Button component={Link} to="/edu/course/intro-to-comp-sci-2-js">
                    <Paper elevation={3} style={{
                      maxWidth: '600px',
                      padding: '10px'
                    }}>
                      <Typography variant="h6" component="h1">
                        Introduction to Computer Science 2 (JS)
                      </Typography>
                      <Typography variant="caption">Getting started with Object Oriented Programming</Typography>
                    </Paper>
                  </Button> */}
                  {/* <Button component={Link} to="/edu/course/intro-to-comp-sci-1">
                    <Paper elevation={3} style={{
                      maxWidth: '600px',
                      padding: '10px'
                    }}>
                      <Typography variant="h6" component="h1">
                        Introduction to Computer Science (Java)
                      </Typography>
                      <Typography variant="caption" style={{color: 'orange'}}>New students please use the JS course</Typography>
                    </Paper>
                  </Button> */}
                </Grid>
                {/* <Grid item xs={12} style={{marginTop: '1em'}}>
                  <Typography variant="subtitle2">
                    Please note Learnly is still in <b>PILOT</b> phase, the program and platform are being developed actively. More courses will be added as we go along this program. Visit <HashLink smooth to="/#program-outline">program outline</HashLink> to see details of all courses that will be added.
                  </Typography>
                </Grid> */}
                </>
            </Grid>
            
    </Container>
    
  );
}

function CourseCard({title, description, link}: {title: string, description: string, link: string}) {
  return (
    <Button component={Link} to={link}>
      <Paper elevation={3} style={{
        maxWidth: '600px',
        padding: '10px'
      }}>
        <Typography variant="h6" component="h1">
          {title}
        </Typography>
        <Typography variant="caption">{description}</Typography>
      </Paper>
    </Button>
  )
}