import { Authenticator, CheckboxField, Heading, useAuthenticator, useTheme, withAuthenticator } from "@aws-amplify/ui-react";
import { Redirect, Route, Switch } from "react-router-dom";
import NavBar from "../components/NavBar";
import SubscriptionProvider from "../context/SubscriptionProvider";
import useAuthState from "../hooks/useAuthState";
import AdminDashboard from "./admin/AdminDashboard";
import ManageQuizes from "./admin/quizes/ManageQuizes";
import QuizForm from "./admin/quizes/QuizForm";
import ViewQuizAttempts from "./admin/quizes/ViewQuizAttempts";
import CourseCreation from "./CourseCreation";
import CoursePage from "./CoursePage";
import DeveloperBootcampHome from "./DeveloperBootcampHome";
import EduHome from "./EduHome";
import MyProfile from "./MyProfile";
import Playground from "./Playground";
import ShareYourProgress from "./ShareYourProgress";
import ChatDocumentPage from "./ChatDocumentPage";
import ChatVideoPage from "./ChatVideoPage";
import CollectionChat from "./CollectionChat";
import NewNavBar from "../components/NewNavBar";
import FileUploadPage from "./FileUploadPage";
import DocVidMainPage from "./DocVidMainPage";
import UpgradePlan from "./UpgradePlan";
import TestCollectionChat from "./TestCollectionChat";


function EduRoutes() {

  const {isUserSignedIn} = useAuthState();

  const doNothing=()=>{} //need this as a prop
  return (
    <SubscriptionProvider>
      <div>
        <NewNavBar/>
        <Switch>
          <Route path="/edu/signin">
            {isUserSignedIn &&
              <Redirect to="/edu/home"/>
            }
          </Route>
          <Route path="/edu/home">
              {/* <DeveloperBootcampHome/> */}
              <DeveloperBootcampHome/>
          </Route>
          <Route path="/edu/docvid">
              <DocVidMainPage/>
          </Route>
          {/* <Route path="/edu/courses">
              <CourseMainPage/>
          </Route> */}
          <Route path="/edu/bootcamp">
              <DeveloperBootcampHome/>
          </Route>
          <Route path="/edu/shareYourProgress">
              <ShareYourProgress/>
          </Route>
          <Route path="/edu/upgradePlan">
            <UpgradePlan/>
          </Route>
          <Route path="/edu/profile">
            <MyProfile/>
          </Route>
          <Route path="/edu/playground">
            <Playground/>
          </Route>
          <Route path="/edu/admin/dashboard">
            <AdminDashboard/>
          </Route>
          <Route exact path="/edu/admin/quizes">
            <ManageQuizes/>
          </Route>
          <Route exact path="/edu/admin/quizes/:quizId/attempts">
            <ViewQuizAttempts/>
          </Route>
          <Route exact path={["/edu/admin/quizes/new", "/edu/admin/quizes/:quizId"]}>
            <QuizForm/>
          </Route>
          <Route exact path="/edu/course/new">
            <CourseCreation/>
          </Route>
          <Route path="/edu/course/:courseId">
            <CoursePage/>
          </Route>
          <Route path="/edu/chatdocument/:documentId">
            <ChatDocumentPage/>
          </Route>
          <Route path="/edu/chatvideo/:documentId">
            <ChatVideoPage/>
          </Route>
          <Route path="/edu/file-upload">
            <FileUploadPage chatCollectionId="" onClose={doNothing}/>
          </Route>
          <Route path="/edu/collection-chat/:collectionId">
            <CollectionChat/>
          </Route>
          <Route path="/edu/test-collection-chat/:collectionId">
            <TestCollectionChat/>
          </Route>
          

        </Switch>
      </div>
    </SubscriptionProvider>
  )
}

export default withAuthenticator(EduRoutes, {
  socialProviders: ['google'],
  loginMechanisms: ['username', 'email'],
  formFields: {
    signUp: {
      given_name: {
        // label: 'First name',
        placeholder: "First name",
        autocomplete: "given-name",
        isRequired: true,
        order: 1
      },
      family_name: {
        // label: 'Last name',
        placeholder: "Last name",
        autocomplete: "family-name",
        isRequired: true,
        order: 2
      },
      // password: {
      //   placeholder: "Password",
      //   autocomplete: "new-password"
      // },
      // confirm_password: {
      //   placeholder: "Confirm Password",
      //   autocomplete: "new-password"
      // }
    }
  },
  // initialState={showSignup ? "signUp" : "signIn"}
  components: {
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Confirm Email address by checking <b>confirmation code</b>:
          </Heading>
        );
      }
    },
    SignUp: {
        FormFields() {
          const { validationErrors, error} = useAuthenticator();
          console.log(validationErrors, error)
          return (
            <>
              {/* Re-use default `Authenticator.SignUp.FormFields` */}
              <Authenticator.SignUp.FormFields />

              {/* Append & require Terms & Conditions field to sign up  */}
              <CheckboxField
                errorMessage={validationErrors.acknowledgement as string}
                hasError={!!validationErrors.acknowledgement}
                name="acknowledgement"
                value="yes"
                label={<>
                  By creating an account you agree with our <a target="_blank" href="/tc">Terms and Conditions</a>
                </>}
              />
            </>
          );
      },
    }
  },
  services: {
    async validateCustomSignUp(formData, touchData) {
      if (!formData.acknowledgement) {
        return {
          acknowledgement: 'You must agree to the Terms & Conditions',
        };
      }
    },
  }
});