import { FormControl, Checkbox } from "@mui/material";
import { Alert } from "@mui/lab";
import { Field, FieldProps } from "formik";
import { useMemo } from "react";
import { AnswerInput } from "../../../types/Quiz";
import OptionContent from "./OptionContent";
import QuestionComponentProps from "./QuestionComponentProps";

export default function MCQMultiQuestion({
  question,
  index,
  answer
}: QuestionComponentProps) {

  const answerInputByOptionId: {
    [key: string]: AnswerInput
  } = useMemo(() => {
    if (!answer || !answer.userInputs) {
      return {};
    }
    return answer.userInputs.reduce((obj, opt) => {
      // @ts-ignore
      obj[opt.optionId] = opt;
      return obj;
    }, {});
  }, [answer]);
  return (

        <FormControl component="fieldset" >
            {
              question.options
                ?.map((option, optionIndex) => (
                  <div key={optionIndex} >
                  <div style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                     <Field name={`answers[${index}].userInputs[${optionIndex}].selected`}>
                        {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                        }: FieldProps<boolean>) => (
                            <>
                              <Checkbox
                                {...field}
                                disabled={!!answer}
                                checked={answer?.userInputs?.[optionIndex]?.selected || field.value}
                              />
                              <OptionContent option={option}/>
                              {meta.touched && meta.error && (
                                <div className="error">{meta.error}</div>
                              )}
                            
                            </>
                        )}
                      </Field>
                  </div>
                     {answerInputByOptionId[option.id] && (
                                <>
                                  {answerInputByOptionId[option.id].feedback &&
                                    <Alert severity="info">{answerInputByOptionId[option.id].feedback}</Alert>
                                  }
                                  {answerInputByOptionId[option.id].rightFeedback &&
                                    <Alert severity="success">{answerInputByOptionId[option.id].rightFeedback}</Alert>
                                  }
                                  {answerInputByOptionId[option.id].wrongFeedback &&
                                    <Alert severity="error">{answerInputByOptionId[option.id].wrongFeedback}</Alert>
                                  }
                                </>
                              )}
                  </div>
                ))
            }
        </FormControl>
    
  )
}