import ApiRequest from "../apiRequest";


export interface ExecutionResult {
  executionArgs: ExecutionArgs;
  result?: string;
  warnings?: string;
  errors?: string;
  stats?: string;

}

export interface ExecutionArgs {
  program: string;
  languageChoice: 'JAVA' | 'JAVASCRIPT' | 'PYTHON' | 'NODE_LTS';
  inputArgs?: string;
  compilerArgs?: string;
}

const languageToNumber = {
  JAVA: 4,
  JAVASCRIPT: 23,
  NODE_LTS: 48,
  PYTHON: 5
}
export default async function executeProgram(executionArgs: ExecutionArgs): Promise<ExecutionResult> {

  try {
    let response = await ApiRequest<any>(
      `/api/programExecutor`,
      'POST',
      JSON.stringify({
        LanguageChoice: languageToNumber[executionArgs.languageChoice],
        Program: executionArgs.program,
        Input: executionArgs.inputArgs,
        CompilerArgs: executionArgs.compilerArgs,
      })
    )
    return {
      result: response.Result as string,
      warnings: response.Warnings as string,
      errors: response.Errors as string,
      stats: response.Stats as string,
      executionArgs,
    };
  } catch (err) {
    console.error("could not execute program", err);
    return {
      errors: "failed to execute",
      executionArgs
    }
  }
}