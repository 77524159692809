import { Alert, CircularProgress, Container, CssBaseline, Grid } from "@mui/material";
import React, { useContext } from "react";
import { useParams } from "react-router";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import CourseOverview from "../components/course/CourseOverview";
import CourseSectionPage from "../components/course/CourseSection";
import GettingStartedFab from "../components/GettingStartedFab";
import LoggedOutNavBar from "../components/LoggedOutNavBar";
import Metadata from "../components/Metadata";
import CourseContext from "../context/CourseContext";
import CourseProvider from "../context/CourseProvider";
import SubscriptionContext from "../context/SubscriptionContext";
import useCourse from "../hooks/useCourse";
import NewNavBar from "../components/NewNavBar";



export default function PublicCoursePage() {

  const params: any = useParams();
  const courseId: string = params.courseId;

  let match = useRouteMatch();

  // REMEMBER to add to CoursePage.tsx
  return (
    <>
      <Container className="App">
        <CssBaseline />

        {/* Navigation */}
        <Container>
          <NewNavBar/>
        </Container>
        <Grid container style={{marginTop: '5em'}}>
          <CourseProvider courseId={courseId} isPublic>
            <CourseMetadata/>
            <LoadingIndicator/>
            <Grid item xs={12}>
            <Switch>
                    <Route path={`${match.path}/module/:moduleId/section/:sectionId`}>
                      <CourseSectionPage />
                    </Route>
                    <Route path={match.path}>
                      <CourseOverview />
                    </Route>
                  </Switch>
            </Grid>
          </CourseProvider>              
        </Grid>
        <GettingStartedFab/>
      </Container>
    </>
  );
}
function CourseMetadata() {
  const courseState = useContext(CourseContext);

  return (
    <Metadata
          title={courseState.course?.title || `Course ${courseState.courseId}`}
          description={courseState.course?.description || `
            Join the learnly program to accelarate your journey to become a software engineer.
          `}
        />
  )
}
function LoadingIndicator() {
  const courseState = useContext(CourseContext);

  // REMEMBER to add to CoursePage.tsx
  return (
    <Grid xs={12} item>
      {['INITIAL', 'LOADING'].includes(courseState.status) && <CircularProgress />}
      {courseState.status === 'INVALID_COURSE' && <Alert severity="error">Course not found or you don't have permissions</Alert>}
    </Grid>
  )
}