import { QuestionOption } from "../../../types/Quiz";
import MarkdownToHTML from "../../MarkdownToHTML";
import ReadOnlySyntaxHighlighter from "../../ReadOnlySyntaxHighlighter";

export default function OptionContent({
  option
}: {
  option: QuestionOption
}) {
  return (
    <div style={{display: 'inline-block'}}>
      {option.contentType === 'TEXT' &&
        <MarkdownToHTML content={option.content}/>
      }
      {option.contentType === 'IMAGE' &&
        <img src={option.content} alt={''}/>
      }
      {option.contentType === 'CODE' && 
      
        <ReadOnlySyntaxHighlighter
          codeContent={option.content}
          language={(option.codeLanguage || 'JAVA')}
        />
      }
      {option.contentType === 'USER_INPUT' &&
          'Tell Zaid user input is not supported'
      }
    </div>
  )
}