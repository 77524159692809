import Course from "../../types/Course";
import ApiRequest from "../apiRequest";

export async function getMyCourses(): Promise<Course[]> {
  try {
    let result: any = await ApiRequest(
      `/api/course/myCourses`,
      'GET'
    )
    return result;
  } catch (err) {
    console.error("could not fetch my courses", err);
    return [];
  }
}