// a page that allows the user to create a new course by entering the course title and description
// uses courseGenerationRequest to create a new course
// uses the AlertDialog component to confirm the user's choice to create a new course
// uses the useHistory hook to redirect the user to the course page after the course is created
// uses formik to manage the form state
// uses yup to validate the form where a title has maximum length of 100 characters and a description has maximum length of 500 characters
// uses @mui/material to style the form
// uses @mui/material toast to display a toast message when the course is created
// uses react-toastify to display a toast message when the course is created
import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory,useParams } from 'react-router-dom';
import { ErrorMessage, Field, Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { courseGenerationRequest } from '../api/courses/courseGenerationRequest';
import AlertDialog from '../components/AlertDialog';
import InputField from '../components/formik/InputField';
import { Button, CircularProgress, Container, Grid, IconButton, Typography, Switch, MenuItem, Menu, Select, SelectChangeEvent, FormControl, FormHelperText, sliderClasses} from '@mui/material';
import { toast } from 'react-toastify';
import { Done, OpenInBrowser, OpenInNew } from '@mui/icons-material';
import getDocument from '../api/chatDocument/getDocument';
import { makeStyles } from '@mui/styles';
import getCollection from '../api/chatCollection/getCollection';
import useQuery from '../hooks/userQuery';
import ChatDocument from '../types/ChatDocument';
import ChatCollection from '../types/chatCollection';
import getAllCollections from '../api/chatCollection/getAllCollections';
import 'react-chat-elements/dist/main.css';
import AddIcon from '@mui/icons-material/Add';

const useStyles=makeStyles(()=>({

    '@global': {
        '*::-webkit-scrollbar': {
          width: 10,
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'wheat',
          borderRadius: 10,
        },
        '*::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'white',
        },
        '*::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
      },
    formContainer:{
        backgroundColor:'#171F30',
        marginTop:'20px',
        padding:'18px',
        paddingLeft:'0',
        '@media (max-width: 600px)': {
            marginLeft:'-8px',    
          },
    },
    field: {
        width: '100%',
        marginBottom:'20px',
        '& input': {
          color: 'white',
        },
        '& .MuiInputLabel-root': {
            color: 'white',
            fontSize:'110%', 
            transition: 'transform 200ms', // Add transitions for smooth animation
          },
        '& .MuiInputLabel-root.Mui-focused': {
            fontSize:'150%',
            transform: 'translate(0, -1.5rem)', // Adjust the translateY value to increase the space
        },
      },
    extraText:{
        marginBottom:'20px'
    },
    guruHeadline:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:'10px',
    },
    createFromGuruSpan:{
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        padding: '0 10px',
        borderRadius: '10px',
        background: '#283142',
    },
    hiddenDiv:{
        background: '#283142',
        borderRadius: '10px',
        marginTop:'20px',
        padding:'10px',
    },
}))





const validCharacters = /^[a-zA-Z0-9:'.,()? ]*$/;
const validationSchema = yup.object({
    // validates title and description are a set of [a-zA-Z0-9'.,?]
    title: yup.string().max(100, 'Title must be 100 characters or less').required('Title is required').matches(validCharacters, 'Can only contain letters, numbers, and the following characters: \'.,?'),
    description: yup.string().max(500, 'Description must be 500 characters or less').required('Description is required')
        .matches(validCharacters, 'Can only contain letters, numbers, and the following characters: \'.,?'),
    collectionId: yup.string().max(100, 'Document ID must be 100 characters or less'),
});




export default function CourseCreation() {

    const [isLoading, setIsLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    let query = useQuery();
    let documentId = query.get("documentId") || undefined;
    let [documentMetadata, setDocumentMetadata] = useState<ChatDocument | ChatCollection | undefined>(undefined);
    const [myCollections, setMyCollections] = useState<ChatCollection[]>([]);
    const [myCollectionsLoading, setMyCollectionsLoading] = useState(true);
    const [collectionIdChosen, setCollectionIdChosen]= useState('')
    let [srcIsGuru, setSrcIsGuru] = useState<boolean>(false);
    const [selectFromGuru, setSelectFromGuru] = useState(false);
    const [selectGuruError,setSelectGuruError]= useState(false);
    
    // load the document metadata if the documentId is provided
    useEffect(() => {
        async function loadDocumentMetadata() {
            if (!documentId) {
                return;
            }
            setIsLoading(true);
            try {
                let documentMetadataResp: ChatCollection | ChatDocument | undefined = await getCollection(documentId);
                if(!documentMetadataResp){
                    documentMetadataResp = await getDocument(documentId);
                    setSrcIsGuru(false);
                } else {
                    setSrcIsGuru(true);
                }
                setDocumentMetadata(documentMetadataResp);
            } finally {
                setIsLoading(false);
            }
        }
        loadDocumentMetadata();
    }, [documentId]);

    useEffect(() => {
  
        async function fetchMyCollections(){ 
          let collections = await getAllCollections();
          setMyCollections(collections);
          setMyCollectionsLoading(false);
        }
    
        fetchMyCollections();
        
      }, []);

    const handleSwitchChange = () => {
        setSelectFromGuru(!selectFromGuru);
      };

    
    const handleChange = (event: SelectChangeEvent) => {
        setCollectionIdChosen(event.target.value);
        if(event.target.value)setSelectGuruError(false)
        else setSelectGuruError(true)
        console.log(event.target.value)
    };

    return (
        <Container maxWidth="md" 
            // center the contents
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '70vh',
            }}
        >
            <Grid container justifyContent="center" alignItems="center" direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="h4" component="h1" align="center">
                        Create a new course
                    </Typography>
                </Grid>
                <Grid item>
                    <Formik
                        initialValues={{
                            documentTitle: '',
                            title: '',
                            description: '',
                            collectionId:documentId,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting, setFieldError }) => {
                                setIsLoading(true);
                                
                                if (values.collectionId && values.collectionId.length > 0) {
                                  let documentMetadata = srcIsGuru ? 
                                  await getCollection(values.collectionId)
                                  : await getDocument(values.collectionId);


                                  if (!documentMetadata) {
                                    setFieldError(
                                      "documentTitle",
                                      "Document not found"
                                    );
                                    setIsLoading(false);
                                    return;
                                  }
                                }
                                if(selectFromGuru){
                                    if(!collectionIdChosen){
                                        setSelectGuruError(true);
                                        setIsLoading(false);
                                        return;
                                    }else{
                                        values.collectionId=collectionIdChosen;
                                    }
                                }
                                
                                await courseGenerationRequest(values.title, values.description, values.collectionId)
                                    .then((course) => {
                                        if (course.errors && course.errors?.length > 0) {
                                            toast.error(course.errors[0].message || 'Error creating course, please try again');
                                            return;
                                        }
                                        toast.success('Course created successfully');
                                        history.push('/edu/course/' + course.courseId);
                                    })
                                    .catch((error) => {
                                        toast.error('Error creating course, please try again');
                                    })
                                    .finally(() => {
                                        setIsLoading(false);
                                    });
                        }}
                    >
                        {({isSubmitting, handleSubmit, isValid}) => (
                        <Grid container spacing={2} className={classes.formContainer}>
                            {documentId && <Grid item xs={12}>
                                <Typography variant="h6" component="h2" align="center" className={classes.guruHeadline}>
                                    <b>{srcIsGuru ? 'Learning from Guru' : 'Source document'}</b>:{' '}{// @ts-ignore
                                    documentMetadata?.title || documentMetadata?.name}
                                    {documentMetadata && <IconButton
                                      component="a"
                                      style={{marginLeft:'10px'}}
                                      target="_blank"
                                      href={`/edu/${srcIsGuru ? 'collection-chat' : 
                                          (documentMetadata as ChatDocument).contentType === 'video/youtube' ? 
                                            'chatvideo' : 'chatdocument'
                                        }/${documentId}`}>
                                      <OpenInNew/></IconButton>}
                                </Typography>
                                <ErrorMessage
                                    name='documentTitle'
                                    render={(msg) => (
                                        <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                                            {msg}   
                                        </div>
                                    )}
                                />
                            </Grid>}
                            
                            {!documentId && <Grid item xs={12}>
                            <div>
                                <div>
                                    <span className={classes.createFromGuruSpan}>
                                        <Typography>
                                            Create Course from Guru 
                                        </Typography>
                                        <Switch
                                            checked={selectFromGuru}
                                            onChange={handleSwitchChange}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'toggle div' }}
                                            disabled={isLoading?true:false}
                                            />
                                    </span>
                                    <FormHelperText style={{marginBottom:'40px',}} hidden={selectFromGuru?true:false}>
                                            Create a unique course with knowledge from your collection of files
                                    </FormHelperText>
                                </div>

                                {selectFromGuru && (
                                    <>
                                    <div className={classes.hiddenDiv}>
                                        <Grid container spacing={1}>
                                        <Grid item>
                                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                <Select
                                                    name="selectFromGuru"
                                                    value={collectionIdChosen}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        },
                                                    }}
                                                    disabled={isLoading?true:false}
                                                >
                                                <MenuItem value="">
                                                    <em>Choose Guru</em>
                                                </MenuItem>
                                                {myCollections.map((collection) => (
                                                    <MenuItem key={collection.id} value={collection.id}>
                                                        {collection.name}
                                                    </MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText style={{color:selectGuruError?'red':'rgba(255, 255, 255, 0.7)'}}>
                                                    {selectGuruError?"Please select a Guru":"Choose from your existing Gurus"}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" style={{margin:'8px 8px 0px 8px',height:'56px'}} 
                                                endIcon={<AddIcon />} 
                                                onClick={() => history.push("/edu/file-upload")} 
                                                disabled={isLoading?true:false}
                                            >
                                                Create a Guru
                                            </Button>
                                            
                                        </Grid>
                                        </Grid>
                                    </div>
                                    <FormHelperText style={{marginBottom:'40px',}} hidden={!selectFromGuru?true:false}>
                                            Create a unique course with knowledge from your collection of files
                                    </FormHelperText>
                                    </>
                                )}
                                </div>
                            </Grid>}

                            <Grid item xs={12}>
                                <Field
                                    name="title"
                                    label="Title"
                                    placeholder="What do you want to learn about?"
                                    required
                                    variant="filled"
                                    className={classes.field}
                                    component={InputField}
                                    />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name="description"
                                    label="Description"
                                    variant="filled"
                                    className={classes.field}
                                    component={InputField}
                                    required
                                    rows={4}
                                    wrap="soft"
                                    cols={100}
                                    as="textarea"
                                />    
                                <Typography className={classes.extraText}>Include details about objectives, or if you are a beginner, etc.</Typography>
                            </Grid>
                            {/* {!documentId && <Grid item xs={12}>
                                <Field
                                    name="collectionId"
                                    label="Document ID (optional)"
                                    placeholder="A document ID to generate a course from"
                                    variant="filled"
                                    className={classes.field}
                                    component={InputField}
                                    />
                            </Grid>} */}
                            <Grid item xs={12}>
                                <AlertDialog
                                    // disable the button if the formik form is invalid or if the form is submitting
                                    buttonLabel="Create my course"
                                    buttonProps={{
                                        disabled: isSubmitting || !isValid,
                                        color: "primary",
                                        variant: "contained",
                                        type: "submit",
                                        startIcon: isLoading ? <CircularProgress/> : <Done />
                                    }}
                                    onYes={handleSubmit}
                                    title="Create my course"
                                    confirmationText="Ready to learn?"
                                    />
                            </Grid>
                        </Grid>
                        )}
                        
                    </Formik>
                </Grid>
            </Grid>
        </Container>
    );
}