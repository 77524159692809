import { Container, Typography } from "@mui/material";

export default function ShareYourProgress() {

  return (
    <Container>
      <Typography variant="h3">
        Share your progress
      </Typography>
      <Typography variant="body1">
        This journey to learning doesn't have to be alone. If you are part of the right company, your growth will accelerate in ways you can't imagine.
        <br/>
        In order to make the most out of your journey, I recommend you to join the <a href="https://www.100daysofcode.com/" target="_blank">#100DaysOfCode</a> challenge. By doing so, you will be joining <b>thousands</b> of other people that are learning to code!
        <br/>
        The benefits:
        <ul>
          <li>
            Coding will become a habit 📈
          </li>
          <li>
            You will meet like minded 🧠 people
          </li>
          <li>
            You will get noticed 👀👀 and network!
          </li>
          <li>
            You will end up with a 💪💪💪💪💪 portfolio!
          </li>
        </ul>
      </Typography>
      <Typography variant="h4">
        The steps
      </Typography>
      <Typography variant="body1">
        The #100DaysOfCode challenge can be done on Instagram or Twitter. I strongly recommend you join Twitter. Tech Twitter space is huge and it has lot of people to learn from.
        <br/>
        What you need to do:
        <ol>
          <li><a href="https://twitter.com/intent/tweet?text=I%27m%20publicly%20committing%20to%20the%20100DaysOfCode%20Challenge%20starting%20today!%20Learn%20More%20and%20Join%20me!%20hey%20@ka11away%20@zqureshi_&url=https://100DaysOfCode.com&hashtags=100DaysOfCode,LearnlyDev" target="_blank" rel="noreferrer">📢 Annouce your commitment</a> 👈 Click that link to prefill your tweet (it won't tweet).
            <br/>
            It will look like <a href="https://twitter.com/ayeshacodes/status/1482167604152348675?s=20" target="_blank" rel="noreferrer">this</a>
            <br/>
          </li>
          <li>📝 Share your progres everyday! Tag <a href="https://twitter.com/zqureshi_" target="_blank" rel="noreferrer">@zqureshi_</a>. Add #100DaysOfCode and #LearnlyDev hastags, and post what you did.
          <br/>
          <br/>
          You can either post a screenshot, or beautified image using <a href="ray.so" target="_blank" rel="noreferrer">ray.so</a>. The result will be something like <a href="https://twitter.com/ayeshacodes/status/1482218212767019008?s=20" target="_blank" rel="noreferrer">this</a>
          <br/>
          Make sure to add #Day1.. for each day
          </li>
          <li>🔁🔁🔁🔁 Repeat</li>
        </ol>
        You could do the same on Instagram too!
      </Typography>
    </Container>
  )
}