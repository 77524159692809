import { Message } from "../../context/ChatContext";
import ApiRequest from "../apiRequest";
import { ChatSendMessageResponse } from "../chatDocument/sendBotMessageSync";

export async function sendBotMessageSync(chatHistory: Message[], context: string): Promise<ChatSendMessageResponse | undefined> {
  try {
    let result: {response?: string} = await ApiRequest(
      `/api/chat/bot`,
      'POST',
      JSON.stringify({
        chatHistory,
        context
      })
    )
    return result as ChatSendMessageResponse;
  } catch (err) {
    console.error("could not request course generation", err);
    throw err;
  }
}