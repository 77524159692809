import ApiRequest from "../apiRequest";

interface getSignedUrlOut {
  error?: string;
  signedUrl?: string;
}
export default async function getSignedUrl(url: string): Promise<getSignedUrlOut> {

  try {
    let result = await ApiRequest(
      '/api/urlSigner?url=' + encodeURI(url),
      'GET'
    );
  
    return {
      // @ts-ignore
      error: result.error,
      // @ts-ignore
      signedUrl: result.signedUrl
    }
  } catch (err) {
    console.error("could not fetch signed url", err);
    return {
      error: 'Unable to get signed url'
    }
  }
}