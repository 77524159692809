import { DetailedHTMLProps, HTMLAttributes, ReactNode, useState } from "react";
import ReadOnlySyntaxHighlighter from "../ReadOnlySyntaxHighlighter";


export default function MarkdownInlineCode(
  props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
) {
  let children: ReactNode[];
  if (!props.children 
    || !Array.isArray(props.children) 
    || (children = props.children as Array<ReactNode>).length !== 1
    ) {
    return <pre>
      {props.children}
    </pre>
  }

  return <ReadOnlySyntaxHighlighter
    preTag={'span'}
    customStyle={{
      display: 'inline',
      padding: '0.2em',
      lineHeight: '2em',
      borderRadius: '5px'
    }}
    // @ts-ignore
    codeContent={children[0]}
    language="javascript"/>
}