import ApiRequest from "./apiRequest";

export interface EmailDetails {
  firstName: string;
  lastName?: string;
  email: string;
  body: string;
  subject: string;
}
interface EmailResponse {
  error?: string;
}
export default async function sendEmail(emailDetails?: EmailDetails): Promise<EmailResponse> {

  try {
    let result = await ApiRequest(
      '/api/sendEmail',
      'POST',
      JSON.stringify(emailDetails)
    )
    // @ts-ignore
    return result || {};
  } catch (err) {
    console.error("could not send email", err);
    return {
      error: 'Unable to send email'
    }
  }
}