import React, { useEffect } from "react";
import getAllQuizMetadata from "../api/quiz/getAllQuizMetadata";
import { Quiz } from "../types/Quiz";


export interface QuizState {
  status: 'FETCHING' | 'FETCH_ERROR' | 'SUCCESS';
  error?: string;
  quizes?: Quiz[];
};

export default function useQuizes() {
  
  const [quizState, setQuizState] = React.useState<QuizState>({
    status: 'FETCHING'
  });

  const fetchQuizes = React.useMemo(() => async () => {
    setQuizState((prevState) => ({
      ...prevState,
      status: 'FETCHING'
    }));
    const resp = await getAllQuizMetadata();
    if (resp.error || !resp.quizes) {
      setQuizState({
        status: 'FETCH_ERROR',
        error: resp.error
      })
    } else {
      setQuizState({
        status: 'SUCCESS',
        error: '',
        quizes: resp.quizes
      })
    }
  }, []);
  useEffect(() => {
    fetchQuizes()
  }, [fetchQuizes]);
  const value = React.useMemo(() => {
    return {
      ...quizState,
      fetchQuizes
    }
  }, [quizState, fetchQuizes]);
  return value;
}