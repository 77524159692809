import { Alert, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import PartComponentProps from "./PartComponentPropType";


export default function withGeneratedLoading(
  WrappedComponent: React.ComponentType<PartComponentProps>,
  generatingDescription?: string,
  initialDescription?:string
) {
  
  return function GeneratedLoadingHOC ({
    part,
    refetchSection
  }: PartComponentProps) {
    const content = part?.content;

    const isGenerating = content === 'GENERATING' || content === 'INITIAL';

    // a useEffect that refetches quiz every 5 seconds if it's still generating or initial
    useEffect(() => {
      if (!content || !isGenerating)  {
        return; // no reason to interval
      }  
      const interval = setInterval(() => {
        if (isGenerating) {
          refetchSection();
        } else {
          clearInterval(interval);
        }
      }, 5000);
      return () => clearInterval(interval);
    }, [content, isGenerating, refetchSection]);


    if (!content) {
      return null;
    }

    // if it's still generating, show a loading spinner
    if (isGenerating) {
      return <div style={{width: '100%'}}>{initialDescription || generatingDescription || 'Generating...'} <CircularProgress size={20}/></div>;
    } else if (content === 'FAILED_TO_GENERATE') {
      // show material UI error message
      return <Alert severity="error">Failed to generate</Alert>;
    } else {
      return <WrappedComponent part={part} refetchSection={refetchSection}/>
    }
  }
}