import './FAQ.css';
import { useState, useEffect, useMemo } from 'react'; 
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import devShapes from '../../assets/devShapesTwo.png';
import {Typography} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoggedOutNavBar from '../../components/LoggedOutNavBar';
import { Link } from 'react-router-dom';
import Metadata from '../../components/Metadata';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


function FAQ() {
  const questions = [
    {
      question: 'When will the content be available?',
      answer: 'As this program is in the pilot phase, I am making the content as we go, and will adjust it based on the progress of the students. I will commit to post content that will require the students roughly 10-15 hours/week to complete, including exercises (it takes time to create and for you folks to complete them).'
    },
    {
      question: 'What are the possible career outcomes?',
      answer: <div>
        <ul>
          <li>Software engineer</li>
          <li>Mobile app developer</li>
          <li>Full stack developer</li>
          <li>Web developer</li>
        </ul>
        <p>
          Out of all the careers I posted above, these are some of the careers which will require you to specialize in before you can land a job in them, they include:
        </p>
        <ul>
          <li>Data scientist</li>
          <li>Data analyst</li>
          <li>Game Developer</li>
          <li>Cyber security</li>
          <li>QA automation Engineer</li>
          <li>Database administrator</li>
        </ul>
        <p>
          They are all more like specializations on what you can do with your computer science skills, you will learn to specialize in web development in this program, which is a prerequisite to some programs (e.g cyber security), but may not be for things like data science, where you will need to take more data analytics courses to specialize. Nonetheless, this program will be a building block in that direction.
          <br/>
  In short, the specialization of this program will be in web development and related professions (back end, front end, or full stack development).
        </p>
      </div>
    },
    {
      question: 'Which programming languages will I learn?',
      answer: <div>
        <p>The goal of this program is to make you into a language agnostic problem solver so that you can apply computer science skills to any language. Nonetheless, these are the following languages that will be covered in the program
        </p>
        <ul>
          <li><b>Javascript</b>: This is one of the most popular languages in the last decade. It is mainly used in the front end and back end side of web applications.</li>
          <li><b>Typescript</b>: Strongly typed version of Javascript.</li>
          <li><b>HTML</b>: All browsers you use today, all web pages you visit, represent some sort of HTML</li>
          <li><b>CSS</b>: This language is used for styling your web pages</li>
          <li><b>SQL</b>: This language is used to retrieve (query) data from our databaes (storage systems).</li>
        </ul>

        <p>
        Because you will be taught fundamentals of computer science, I actually challenge you to use the skills taught in this program, and apply them to other languages that you are interested in, like Python.
        </p>
      </div>
    },
    {
      question: 'Which frameworks and libraries will I learn?',
      answer: <div>
        <p>You will be taught computer science and fundamental software engineering skills so that you can apply them to any langauge or framework.</p>
        <ul>
          <li><b>Node.js</b></li>
          <li><b>NextJS</b></li>
          <li><b>ExpressJS</b></li>
          <li><b>ReactJS</b></li>
          <li><b>AWS Amplify</b></li>
        </ul>
        <p>You will also learn to deploy your applications to the web using Github, Heroku, and AWS</p>
      </div>
    },
    {
      question: 'How much time will this program require?',
      answer: 'This program is self paced, so you will be able to watch and practice anytime. I will usually upload content (videos and exercises) every week which will usually require 10-15 hours to complete. When I have done this before offline, I had a student that was able to finish in 5 months and another in 7, but can take more time based on users speed and results.'
    },
    {
      question: 'What kind of exercises are included?',
      answer: 'Assignments will be a core part of the program. At the end of the day, software development is a practical field, and requires us to use our skills on a daily basis. The exercises will help teach the practical part of the course so you can actually build and design software, and truly engineer software. You will be spending a lot of time doing exercises and final assignments. The end of the course will most likely include a capstone project (a final project that will require you to use all the skills you learned to make a product of your choice).'
    },
    {
      question: 'Who will help me if I am stuck?',
      answer: "We are closely monitoring all the pilot students, in the case you are stuck on an exercise, we will first try to give you suggestions through chat. If that doesn't help, we will schedule a 1:1 session to understand the blocker and help you get through it. At the end of the day, we want you to become the problem solver and software engineer you can, we will do everything we can to unblock you."
    },
    {
      question: 'How will I develop my portfolio?',
      answer: 'As we go deeper into the program, we will slowly increase the types of projects we are doing, and each one can go into your portfolio that you will use to land potential interviews, and then use the skills learned in this program to potentially land the job! I will show you guys on how to publish your work and share it.'
    }
  ]
  const { height, width } = useWindowDimensions();
  const headerSize = useMemo(() => {
    if (width > 999) {
      return "h1";
    } else if (width > 750) {
      return "h2";
    } else {
      return "h3"
    };
  }, [width]);
  return (
    <Container>
      <Metadata
        title="FAQs - Learnly"
        description={`
        Have any questions? Checkout these FAQs, or contact us for anything specific.
        `}
      />
      <CssBaseline />

      {/* Navigation */}
      <Container>
        <LoggedOutNavBar/>
      </Container>

      {/* accordian */}
      <Container>
      <h1 className="faqHeading">FAQs</h1>
          <img
          src={devShapes}
          alt="devshapes"
          className="faqShape"
          />
            {
              questions.map((q, i) => (
                <Accordion key={i} className="accordianFAQ">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      {q.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {q.answer}
                  </AccordionDetails>
                </Accordion>
              ))
            }
        <p className="moreQuestions"
        >Still have more questions, <Link to="/contact-us">Contact me</Link></p>

      </Container>

      <Container style={{marginTop: '50px'}}>
        <h1 style={{textAlign: 'center', color: '#98D4B5'}}>Want to become a software engineer? <Link to="/getting-started"><span className="contactButton">JOIN NOW</span></Link></h1> 
      </Container>
    </Container>
  );
}

export default FAQ;
