import ChatDocument from "../../types/ChatDocument";
import ApiRequest from "../apiRequest";


export default async function getDocsFromCollection(collectionId:string): Promise<ChatDocument[]> {


  try {
    let result: {
      documents?: ChatDocument[]
     } = (await ApiRequest(
        `/api/fromCollectionId/${collectionId}`,
        'GET'
      )) || {};
    
    result.documents = result.documents?.sort((a, b) => {
      // if createdAt is undefined, put it at the end
      if (!a.createdAt) {
        return 1;
      }
      if (!b.createdAt) {
        return -1;
      }
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
    return result.documents || [];
  } catch (err) {
    console.error("could not get documents", err);
    
    return [];
  }
};