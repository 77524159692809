import React, { useEffect } from "react";

import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { User } from "../types/user";


// const getAWSCredentials = async (googleUser: any) => {
//   const { id_token, expires_at } = googleUser.get();
//   const profile = googleUser.getBasicProfile();
//   let user = {
//       email: profile.getEmail(),
//       name: profile.getName()
//   };
  
//   const credentials = await Auth.federatedSignIn(
//       'google',
//       { token: id_token, expires_at },
//       user
//   );
//   console.log('credentials', credentials);
// }

function toUser(user: any): User {
  const attributes = user.attributes || user.signInUserSession?.idToken?.payload;
  const groups: string[] = user.signInUserSession?.idToken?.payload?.['cognito:groups'] || [];
  return {
    firstName: attributes?.given_name,
    lastName: attributes?.family_name,
    email: attributes?.email,
    username: user.username,
    id: attributes?.sub,
    admin: groups.includes('superuser'),
    groups
  }
}

interface UserState {
  status: 'LOADING' | 'FAILED' | 'LOADED';
  user?: User;
  error?: string;
}
export default function useUser() {


  const [currentUserState, setCurrentUserState] = React.useState<UserState>({
    status: 'LOADING'
  });


  useEffect(() => {

    Auth.currentAuthenticatedUser()
    .then( async user => {
      console.log("learnlyuser", user);
      // let creds = await getAWSCredentials(user);
      // console.log("creds", creds);
      const learnlyUser = toUser(user);
      setCurrentUserState({
        status: 'LOADED',
        user: learnlyUser,
        error: ''
      })
    })
    .catch(err => {
      console.error("Failed to load user info", err);
      setCurrentUserState({
        status: 'FAILED',
        user: undefined,
        error: 'Failed to load user info'
      });
    });
  }, []);


  return currentUserState;
}