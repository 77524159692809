/**
 * React hook that connects to the websocket and provides api for sending and receiving messages
 * 
 * It takes the following parameters:
 * 
 * - currentUserId - the id of the current user
 * - websocketUrl - the url of the websocket to connect to
 * - filterMessage - a function that takes a message and returns true if the message should be processed
 * - onMessage - a function that takes a message and returns a promise that resolves when the message is processed
 * - onMessageError - a function that takes a message and an error and returns a promise that resolves when the error is processed
 * 
 * It returns the following:
 * 
 * - isConnected - true if the websocket is connected
 * - sendMessage - a function that takes a message and sends it to the websocket
 * 
 */

import { Auth } from "aws-amplify";
import { async } from "q";
import { useEffect, useRef, useState } from "react";
import { send } from "react-ga";

const websocketUrl = process.env.NODE_ENV === 'development' ? 'wss://ss3ypdjgm9.execute-api.us-east-1.amazonaws.com/test' : 'wss://ysp1z1xjk0.execute-api.us-east-1.amazonaws.com/prod';

export default function useWebsocket(
  filterMessage: (message: string) => boolean,
  onMessage: (message: string) => Promise<void>,
  onMessageError: (message: string, error: Event) => Promise<void>,
): {
  isConnected: boolean;
  sendMessage: (message: string) => void;
} {
  const [isConnected, setIsConnected] = useState(false);

  
  const ws = useRef<WebSocket|undefined>(undefined);

  useEffect(() => {
    if (!isConnected) {
      const connect = async () => {
        console.log('connecting to WSS');
        const token = (await Auth.currentSession())?.getIdToken()?.getJwtToken();
        if (!token) {
          console.log('no token, not connecting to WSS');
          return;
        }
        const socket = new WebSocket(websocketUrl + '?authorization=' + token);

        socket.onopen = () => setIsConnected(true);
        socket.onclose = () => setIsConnected(false);
        socket.onmessage = (event) => {
          if (filterMessage(event.data)) {
            onMessage(event.data);
          }
        };
        socket.onerror = (event) => onMessageError('Connection error', event);

        ws.current = socket;
      }
      connect();
    }else if(ws.current){
      const socket=ws.current;
      socket.onopen = () => setIsConnected(true);
        socket.onclose = () => setIsConnected(false);
        socket.onmessage = (event) => {
          if (filterMessage(event.data)) {
            onMessage(event.data);
          }
        };
        socket.onerror = (event) => onMessageError('Connection error', event);
    }
  }, [filterMessage, isConnected, onMessage, onMessageError]);

  useEffect(() => {
    return () => {
      if (ws.current) {
        console.log('closing websocket');
        ws.current.close();
      }
    };
  }, []);
  return {
    isConnected,
    sendMessage: (message: string) => {
      if (ws.current) {
        ws.current.send(message);
      }
    }
  }
}