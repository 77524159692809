import { BasePart } from "../../types/Course";
import MarkdownToHTML from "../MarkdownToHTML";
import withGeneratedLoading from "./GeneratedLoadingHOC";
import PartComponentProps from "./PartComponentPropType";


export default withGeneratedLoading(CourseMarkdown, 'Generating additional text...');
export function CourseMarkdown({
  part
}: PartComponentProps) {
  return <MarkdownToHTML content={part?.content} />; 
}