import Amplify, { Auth } from 'aws-amplify';

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

const AuthValues = {

    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'us-east-1_KCtamGokh',

    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-1_rSqi9Rw56',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: 'h8i6pn5ss7arbg052kktfssia',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: process.env.NODE_ENV === 'development' ?
    {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
          domain: 'localhost',
      // OPTIONAL - Cookie path
          path: '/',
      // OPTIONAL - Cookie expiration in days
          expires: 365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
          sameSite: "strict",
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
          secure: false
    } :
    {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: 'learnly.dev',
    // OPTIONAL - Cookie path
        path: '/',
    // OPTIONAL - Cookie expiration in days
        expires: 365,
    // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: "strict",
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true
    },
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    // // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },
    googleClientId: "953522728556-0tvhgfs885i9echdv607ndsv9e23hlb4.apps.googleusercontent.com",
    //  // OPTIONAL - Hosted UI configuration
    oauth: {
        domain: 'cognito.learnly.dev',
        // googleClientId: "953522728556-0tvhgfs885i9echdv607ndsv9e23hlb4.apps.googleusercontent.com",
        // clientId: "953522728556-0tvhgfs885i9echdv607ndsv9e23hlb4.apps.googleusercontent.com",
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: isLocalhost ? 'http://localhost:3000/edu/home' : 'https://www.learnly.dev/edu/home',
        redirectSignOut: isLocalhost ? 'http://localhost:3000/' : 'https://www.learnly.dev/',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
}
// Amplify.configure({
//     Auth: AuthValues
// });

// You can get the current config object
const currentConfig = {
    Auth: AuthValues
};

export default currentConfig;