import { Alert, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useAttempt, { QuizAttemptState, QuizAttemptStore } from "../../hooks/useAttempt";
import useQuiz, { QuizState } from "../../hooks/useQuiz";
import { BasePart } from "../../types/Course";
import withGeneratedLoading from "./GeneratedLoadingHOC";
import PartComponentProps from "./PartComponentPropType";
import AttemptQuiz from "./quiz/AttemptQuiz";
import useUser from "../../hooks/useUser";

export default withGeneratedLoading(CourseQuiz,"Generating...","Waiting for other sections to be generated");

export function CourseQuiz({
  part
}: PartComponentProps) {
  const userStore = useUser();
  const isUserSignedIn = !!userStore.user;
  const quizId = part?.content!;
  const quizAttemptStore = useAttempt(quizId);
  const quizState = useQuiz(quizId);

  const AssessmentForm = !quizState.quiz ? null : 
    quizState.quiz.assessmentType === 'EXERCISE' ? ExerciseComponent : QuizComponent; 
  return !quizId ? null : !isUserSignedIn ?
  <Alert variant="outlined" severity="warning">You need to sign in to take a quiz!</Alert> :
  quizAttemptStore.status === 'FETCHING' || quizState.status === 'FETCHING' ?
      <div>
        <CircularProgress/>
      </div>
      : 
  quizState.status === 'FETCH_ERROR' ? <div>Could not fetch assessment</div>  :   
      (
   <>
    {AssessmentForm && <AssessmentForm quizId={quizId} quizAttemptStore={quizAttemptStore} quizState={quizState}/>}
   </>
  ); 
}

interface AssessmentComponentProps {
  quizId: string;
  quizAttemptStore: QuizAttemptStore;
  quizState: QuizState;
}

function ExerciseComponent({
  quizId,
  quizAttemptStore,
  quizState
}: AssessmentComponentProps) {

  return quizAttemptStore.status === 'FETCHING' ? <div><CircularProgress/></div> : (
    <div>
      {quizAttemptStore.status === 'FETCH_ERROR' && 
        'Was not able to get your exercise attempt: ' + (quizAttemptStore.error || '') 
      }
      <AttemptQuiz 
        quizId={quizId}
        previousAttempt={quizAttemptStore.attempt}
        onFinish={() => {
          quizAttemptStore.fetchQuizAttempt();
        }}
      />
    </div>
  )
}

function QuizComponent({
  quizId,
  quizAttemptStore,
  quizState
}: AssessmentComponentProps) {
  const [startQuiz, setStartQuiz] = useState(false);
  return (
    <div style={{marginBottom: '1em'}}>
      {!startQuiz &&
        <>
          <Typography variant="h5" style={{marginBottom: '1em'}}>
            Quiz: {quizState.quiz?.title || ''}
          </Typography>
          {quizAttemptStore.status === 'FETCH_ERROR' && 
            'Was not able to get your quiz attempt: ' + (quizAttemptStore.error || '') 
          }
          {quizAttemptStore.status === 'SUCCESS' && quizAttemptStore.attempt && quizAttemptStore.attempt.score !== undefined &&

            <span style={{marginRight: '0.5em', marginTop: '1em', marginBottom: '1em'}}>
              <strong>Score:</strong> {`${quizAttemptStore.attempt.score % 1 === 0 ? quizAttemptStore.attempt.score : quizAttemptStore.attempt.score?.toFixed(2)}/${quizAttemptStore.attempt.maxScore}`}
            </span>
          }
          <Button
            variant="contained"
            color="primary"
            onClick={() => setStartQuiz(true)}
          >
            Attempt Quiz
          </Button>
          
        </>
      }
      
      {startQuiz &&
        <AttemptQuiz 
          quizId={quizId}
          onFinish={() => {
            quizAttemptStore.fetchQuizAttempt();
            setStartQuiz(false);
          }}
        />
      }
    </div>
  )
} 