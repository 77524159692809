import { Button, CircularProgress, FormHelperText, TextField, Container, Typography, Menu, MenuItem, IconButton, Tooltip, ClickAwayListener, Grid } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import NewNavBar from '../../components/NewNavBar';
import blurryImage from '../../assets/blurry.svg'
import courseImage from '../../assets/company-policy-course.png'
import { InitialCourseCard } from '../CourseMainPage';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';


const theme = createTheme({
    palette: {
        info:{
            main:'#5D4682',
            dark:'#4c396b'  
        }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 800,
        md: 870,
        lg: 1200,
        xl: 1920,
      },
    },
    typography: {
        fontFamily: [
          'Inter', 
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          'sans-serif'
        ].join(',')
      }
  });


const useStyles=makeStyles(()=>({

    '@keyframes fadeInAnimation': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
    pageRoot:{
        padding:'50px 0',
    },
    heading:{
        fontWeight:'500',
        fontSize:'40px',
        opacity: 0,
        animation: '$fadeInAnimation 1s ease-in forwards',
        '@media (max-width: 540px)': {
            fontSize:'30px',    
          },
    },
    secondaryHeading:{
        fontWeight:'200',
        fontSize:'20px',
        opacity: 0,
        animation: '$fadeInAnimation 1s ease-in forwards',
        '@media (max-width: 540px)': {
            fontSize:'15px',    
          },
    },
    firstContainer:{
        alignItems:'center',
        borderRadius:'30px',
        padding:'40px 10px',
        marginBottom:'50px',
        height:'auto',
        background: 'rgba(4, 10, 20, 0.6)',
        backdropFilter: 'saturate(1.5) blur(30px)',
    },
    secondContainer:{
        height:'auto',
        alignItems:'center',
        borderRadius:'30px',
        background: 'rgba(4, 10, 20, 0.6)',
        backdropFilter: 'saturate(1.5) blur(30px)',
        padding:'40px 10px',
        marginBottom:'50px',
    },
    upperGrid:{
        '@media (max-width: 870px)': {
            marginBottom:'35px',    
          },
    },
    textDiv:{
        fontSize:'18px',
        padding:'0 30px',
    },
    imageContainer:{
        
    },
    image:{
        width:'100%', 
        minWidth:'320px',
        maxWidth:'600px', 
        borderRadius:'15px',
        border:'1px solid'
    },
}))

export default function EmployerUseCase(){
    const classes=useStyles()
    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth={"xl"} sx={{backgroundImage:`url(${blurryImage})`, backgroundSize:'cover', backgroundPosition:'center', padding:0}}>
                <NewNavBar/>
            <Container maxWidth={"xl"} className={classes.pageRoot} >
                <Container sx={{textAlign:'center', marginBottom:'50px'}}>
                    <Typography className={classes.heading}>Bridge knowledge gaps for your remote teams</Typography>
                    <Typography className={classes.secondaryHeading}>Use the power of AI to boost productivity by equipping employees with domain knowledge at their fingertips</Typography>
                </Container>
                <Container>
                    <Grid container className={classes.firstContainer}>
                        <Grid item xs={12} md={7} className={classes.upperGrid} style={{display:'flex',justifyContent:'center'}}>
                            <div className={classes.textDiv}>
                                <Typography style={{marginBottom:'35px', fontWeight:'400', fontSize:'25px'}}>Key Benefits <AutoGraphIcon/></Typography>
                                <CustomRomanList text='Create tailored on-demand courses for your employees with existing files and videos'/>
                                <CustomRomanList text='Increase productivity by removing redundant meetings'/>
                                <CustomRomanList text='Allow remote teams to learn on-demand about your business specifics'/>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={5} sx={{textAlign:'center'}}>
                            <InitialCourseCard  link={"/edu/course/new"}/>
                        </Grid> 
                    </Grid>  
                </Container>
                <Container>
                    <Grid container className={classes.secondContainer}>
                        <Grid item xs={12} md={7} sx={{textAlign:'center'}} className={classes.upperGrid}>
                            <div className={classes.imageContainer}>
                                <img src={courseImage} className={classes.image} loading='lazy'/>
                            </div>
                        </Grid>  
                        <Grid item xs={12} md={5} style={{display:'flex',justifyContent:'center'}}>
                            <div className={classes.textDiv}>
                                <Typography style={{marginBottom:'35px', fontWeight:'400', fontSize:'25px'}}>Features <AutoAwesomeIcon/></Typography>
                                <CustomRomanList text='Chat with your virtual domain experts'/>
                                <CustomRomanList text='Create courses in 1 click, create experts in your domain'/>
                                <CustomRomanList text='Ensure learning with automatic assessments'/>
                                <CustomRomanList text='Completely secure, your data is encrypted at rest, only accessible by your business'/>
                            </div>
                        </Grid>
                    </Grid>  
                </Container>
                <Container>
                    <Grid container className={classes.secondContainer}>
                        <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>
                            <div className={classes.textDiv}>
                                <Typography style={{fontSize:'20px', fontWeight:'500', marginBottom:'10px'}}>Client testimonial</Typography>
                                <Typography><a href="https://www.gohoopo.com/">Hoopo</a> has a completely remote team of volunteers. We have complex domain knowledge about the way our investment products and eliminate poverty. We regularly get new volunteers and they need to know specifics about programs to execute their tasks. With Learnly, they can chat with virtual chatbots to retrieve this information. I have seen some volunteers create courses before conferences to master topics. We have seen an increase in volunteer retention and confidence in the team</Typography>
                            </div>
                        </Grid>
                    </Grid>  
                </Container>


            </Container>
            </Container>
        </ThemeProvider>
    )
}


function CustomRomanList({text}:{ text: string }){
    return(
        <Typography style={{marginBottom:'28px', lineHeight:'20px', fontWeight:'300'}}>
            {text}
        </Typography>
    )
}