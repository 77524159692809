import Course from "../../types/Course";
import ApiRequest from "../apiRequest";

export async function getCourseGenerationStatus(courseId: string): Promise<Course['generationStatus']> {
  try {
    let result: any = await ApiRequest(
      `/api/course/${courseId}/generationStatus`,
      'GET'
    )
    return result;
  } catch (err) {
    console.error("could not fetch course generation status", err);
    return 'IN_PROGRESS';
  }
}