import { Fab } from "@mui/material";
import { PlayArrow } from "@mui/icons-material";
import { useHistory } from "react-router";

export default function GettingStartedFab() {

  const history = useHistory();

  return (
    <Fab variant="extended"
      onClick={() => history.push('/edu/home')}
      style={{
        background: '#f38d64',
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 10000
      }}>
      <PlayArrow/>
      Get started
    </Fab>
  );
}