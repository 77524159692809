import ApiRequest from "../apiRequest";
import { Quiz } from "../../types/Quiz";

interface getQuizOut {
  error?: string;
  quiz?: Quiz;
}
export default async function getQuiz(quizId: string): Promise<getQuizOut> {

  try {
    let result = await ApiRequest(
      '/api/quiz/' + quizId,
      'GET'
    )

  
    return {
      // @ts-ignore
      error: result.error,
      // @ts-ignore
      quiz: result.quiz
    }
  } catch (err) {
    console.error("could not fetch quiz details", err);
    return {
      error: 'Unable to get quiz'
    }
  }
}