import ApiRequest from "../apiRequest";

export interface YoutubeMetadata {
    title: string;
    length: number; // in seconds
  }


export default async function getYoutubeMeta(ytLink: string): Promise<YoutubeMetadata|null> {
  try {
    let result: YoutubeMetadata = await ApiRequest(
      `/api/youtubeMeta?ytLink=${ytLink}`,
      'GET'
    );
    return result;
  } catch (err) {
    console.error("could not get video meta", err); 
    return null;
  }
};