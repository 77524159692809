import ApiRequest from "../apiRequest";
import { QuizAttempt } from "../../types/Quiz";

interface getQuizAttemptOut {
  error?: string;
  attempt?: QuizAttempt;
}
export default async function getQuizAttempt(quizId: string, timestamp?: string, userId?: string): Promise<getQuizAttemptOut> {

  try {
    let result = await ApiRequest(
      '/api/quiz/' + quizId + '/attempt/' + (timestamp || 'latest') + (userId ? `?userId=${userId}` : ''),
      'GET'
    )

  
    return {
      // @ts-ignore
      error: result.error,
      // @ts-ignore
      attempt: result.attempt
    }
  } catch (err) {
    console.error("could not fetch attempt details", err);
    return {
      error: 'Unable to get attempt'
    }
  }
}