import { Chip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CourseProgress } from "../../api/courses/getCourseProgress";
import Course, { CourseModule } from "../../types/Course";
import {
  ExpandLess,
  ExpandMore,
  Done,
  Lock
} from "@mui/icons-material"
import LinearProgressWithLabel from "../LinearProgressWithLabel";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import useScroll from "../../hooks/useScroll";
import HourMinText from "../HourMinText";

export default function ModuleDrawerSection({
  indexOfModule,
  course,
  module,
  courseProgress,
  currentSectionId,
  isPublic,
}: {
  indexOfModule: number,
  course: Course,
  module: CourseModule,
  courseProgress?: CourseProgress,
  currentSectionId?: string,
  isPublic?:boolean,
}) {

  const numberOfCompletedSections =
  module.sections.filter(section => !!courseProgress?.completed?.[section.id]).length;

  const allSectionsComplete = numberOfCompletedSections === module.sections.length;
  const [isExpanded, setExpanded] = useState(!allSectionsComplete);
  const [executeScroll, elRef] = useScroll();
  useEffect(() => elRef && executeScroll(), [elRef]);
  useEffect(() => {
    if (allSectionsComplete) {
      setExpanded(false);
    }
  }, [allSectionsComplete]);
  return (
    <div>
      <Typography 
        variant="h6"
        id={module.id}
        style={{
          cursor: 'pointer'
        }}
        onClick={() => setExpanded(!isExpanded)}>
        {(indexOfModule + 1)}. {module.title}  <span>{isExpanded ? <ExpandLess/> : <ExpandMore/>}</span>
        {' '}
        {allSectionsComplete && 
          <Chip 
            style={{
              backgroundColor: '#4BB543',
              color: 'white'
            }}
            icon={<Done style={{color: 'white'}}/>}
            label="Completed"/>
          }
        {!allSectionsComplete &&
          <LinearProgressWithLabel value={Math.floor((numberOfCompletedSections/module.sections.length)*100)}/>
        }
      </Typography>
      {isExpanded && (
        <div style={{
          marginTop: '0.5em',
          paddingTop: '1px',
          backgroundColor: '#202b43'
        }}>
          <Timeline>
            {module.sections.map((section, i) => {
              const isCurrentSection = currentSectionId === section.id;
              const sectionCompleted = courseProgress?.completed?.[section.id]!!;
              return (
                <TimelineItem 
                  key={section.id}
                  ref={isCurrentSection ? elRef : undefined}
                  style={{
                  minHeight: '40px'
                }}>
                  <TimelineSeparator>
                    {section.locked ? 
                      <Lock fontSize={'small'}/> :
                      <TimelineDot 
                        variant={sectionCompleted ? 'filled' : 'outlined'}
                        color="secondary"
                        style={sectionCompleted ? {backgroundColor: 'green'} : {}}/>
                    }
                    {(i + 1) !== module.sections.length && <TimelineConnector color="secondary"/>}
                  </TimelineSeparator>
                  <TimelineContent style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    minHeight: '30px',
                    alignItems: 'center',
                    margin: 'auto',
                  }}>
                    {section.locked ? 
                      <Typography variant="subtitle1" component={HashLink} smooth to={'#subscription-warning'}>
                        {section.title}
                      </Typography>
                      :
                      <Typography
                        style={{
                          fontWeight: isCurrentSection ? 'bold' : undefined,
                          fontSize: isCurrentSection ? '20px' : undefined,
                          textDecoration: isCurrentSection ? 'underline' : undefined,
                          color:'#A1C7FF'
                        }}

                      variant="subtitle1" component={Link} to={`${isPublic? "": "/edu"}/course/${course.courseId}/module/${module.id}/section/${section.id}`}>
                        {section.title}
                      </Typography>

                    }
                    {!!section.time && section.time > 0 &&
                        <Typography 
                          variant="caption" 
                          style={{
                            marginLeft: '10px',
                            color: 'white',
                            whiteSpace: 'nowrap'
                          }}>
                          <HourMinText seconds={section.time}/>
                        </Typography>
                    }
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>

        </div>
      )
      }
      
    </div>
  )
}