import React, { useEffect } from "react";
import getQuiz from "../api/quiz/getQuizMetadata";
import { Quiz } from "../types/Quiz";


export interface QuizState {
  status: 'FETCHING' | 'FETCH_ERROR' | 'SUCCESS';
  error?: string;
  quiz?: Quiz;
};

export default function useQuiz(quizId?: string) {
  
  const [quizState, setQuizState] = React.useState<QuizState>({
    status: 'FETCHING'
  });

  const fetchQuiz = React.useMemo(() => async () => {
    if (!quizId) {
      setQuizState({
        status: 'SUCCESS',
        quiz: undefined,
        error: undefined
      });
      return;
    }
    const resp = await getQuiz(quizId);
    if (resp.error || !resp.quiz) {
      setQuizState({
        status: 'FETCH_ERROR',
        error: resp.error
      })
    } else {
      setQuizState({
        status: 'SUCCESS',
        error: '',
        quiz: resp.quiz
      })
    }
  }, [quizId]);
  useEffect(() => {
    fetchQuiz()
  }, [fetchQuiz]);
  const value = React.useMemo(() => {
    return {
      ...quizState,
      fetchQuiz
    }
  }, [quizState, fetchQuiz]);
  return value;
}