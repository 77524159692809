import SyntaxHighlighter, { SyntaxHighlighterProps } from 'react-syntax-highlighter'
import * as styles from 'react-syntax-highlighter/dist/esm/styles/hljs'

export default function ReadOnlySyntaxHighlighter({
  codeContent,
  language,
  customStyle,
  preTag,
  }: {
  codeContent: string | React.ReactNode,
  language: string, 
  customStyle?: SyntaxHighlighterProps['customStyle'],
  preTag?: SyntaxHighlighterProps['PreTag']
}) {
  return (
    <SyntaxHighlighter 
      customStyle={customStyle}
      language={language.toLowerCase()}
      PreTag={preTag}
      style={styles.atomOneDark}>
      {codeContent}
    </SyntaxHighlighter>
  )
}