import ApiRequest from "../apiRequest";
import Course from "../../types/Course";
import { CourseProgress } from "./getCourseProgress";

export interface CourseProgressWithUserDetails extends CourseProgress {
  username: string;
  firstName: string;
  lastName: string;
}
export interface AllCourseProgress {
  error?: string;
  courseId: string;
  allProgress: CourseProgressWithUserDetails[]
}
export default async function getAllCourseProgress(courseId: string): Promise<AllCourseProgress> {

  try {
    let result = await ApiRequest<AllCourseProgress>(
      `/api/course/${courseId}/navigation?all=true`,
      'GET'
    )
    return result;
  } catch (err) {
    console.error("could not fetch course details", err);
    return {
      error: 'Unable to get course',
      courseId,
      allProgress: []
    }
  }
}