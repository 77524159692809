import { FieldProps, getIn } from "formik";
import { TextField } from "@mui/material";

const InputField = ({ field, form: { errors, touched }, ...props }: FieldProps) => {
  const errorMessage = getIn(errors, field.name);
  const isTouched = getIn(touched, field.name);
  const hasError = errorMessage && isTouched;
  return (
    <TextField
      error={hasError}
      helperText={errorMessage}
      {...props}
      {...field}
    />
  )
};
export default InputField;