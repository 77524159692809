// uses react-pdf to display a pdf file
// gets pdf file url from props
// shows page number and total pages
// shows a button to download the pdf file
// shows buttons to navigate to next and previous pages
// uses mui components for styling

import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Alert, Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { FileDownload } from '@mui/icons-material';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import { SizeMe } from 'react-sizeme';
import { PageCallback } from 'react-pdf/dist/cjs/shared/types';
// @ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();

export default function PDFViewer(props: {
  url: string,
  onPageChange?: (pageNumber: number) => void,
  onNewPageText?: (text: string) => void,
}) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  useEffect(() => {
    if (props.onPageChange) {
      props.onPageChange(pageNumber);
    }
  }, [pageNumber, props.onPageChange])
  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setLoading(false);
    setNumPages(numPages);
  }

  async function onPageLoadSuccess(page: PageCallback) {
    if (!props.onNewPageText) {
      return;
    }
    const textContent = await page.getTextContent();
    // @ts-ignore
    const textItems = textContent.items.filter(item => !!item?.str).map(item => item.str);
    props.onNewPageText(textItems.join(' '));
  }

  function goToPrevPage() {
    setPageNumber(pageNumber - 1);
  }

  function goToNextPage() {
    setPageNumber(pageNumber + 1);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {loading && <CircularProgress size={20} />}
        {error && <Alert severity="error">{error}</Alert>}
      </Grid>
      <Grid item xs={12}>
        <div style={{flex: 1}}>
          <SizeMe>
            {({ size }) => (
              <Document
                file={props.url}
                onLoadError={(error) => {
                  setError(error.message);
                  setLoading(false);
                }}

                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page width={size.width || undefined} pageNumber={pageNumber} onLoadSuccess={onPageLoadSuccess} />
              </Document>
            )}
          </SizeMe>
        </div>
        
      </Grid>
      <Grid item xs={12}>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginTop="16px"
          marginBottom="16px"
        >
          <Typography style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            Page{' '}
            <TextField
              key={'page-number-' + pageNumber}
              // label="Page Number"
              variant="outlined"
              size="small"
              defaultValue={pageNumber}
              type="number"
              onBlur={(e) => {
                const value = parseInt(e.target.value);
                if (value > 0 && value <= numPages) {
                  setPageNumber(value);
                }
              }}
              style={{ marginLeft: '8px', marginRight: '8px', maxWidth: `${50 + 10 * (String(numPages).length)}px`,borderRadius:'5px', }}
            />{' '}
            of {numPages}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} style={{textAlign:'center'}}>
        <Button
          variant="contained"
          size='small'
          style={{marginRight: '1rem'}}
          disabled={pageNumber <= 1}
          onClick={goToPrevPage}
        >
          Prev
        </Button>
        <Button
          variant="contained"
          size='small'
          disabled={pageNumber >= numPages}
          onClick={goToNextPage}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
}

