import ApiRequest from "../apiRequest";

export interface UserDetails {
  username: string;
  firstName: string;
  lastName: string;
  sub: string;
}
interface getAllUsers {
  error?: string;
  users?: UserDetails[];
  pageToken?: string;
}
export default async function getAllUsers(pageToken?: string): Promise<getAllUsers> {

  try {
    let result = await ApiRequest(
      '/api/users',
      'GET'
    )
    // @ts-ignore
    return result || {};
  } catch (err) {
    console.error("could not fetch users", err);
    return {
      error: 'Unable to get users'
    }
  }
}