import { Box, Button, Card, CardActions, CardContent, CardMedia, Container, Grid, TextField, Theme, Typography, createTheme } from "@mui/material";
import { useHistory,Link,useParams } from "react-router-dom";
import { useRef } from "react";
import { ThemeProvider } from "styled-components";
import { useEffect,useState } from "react";
import { makeStyles } from '@mui/styles';
import { toast } from "react-toastify";
import NewNavBar from "../components/NewNavBar";
import UploadIcon from '@mui/icons-material/Upload';
import useChatDocument from "../hooks/useChatDocument";
import createDocumentRequest, { CreateDocumentResponse } from "../api/chatDocument/createDocumentRequest";
import React from "react";
import ChatProvider from "../context/ChatProvider";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import ChatWindow from "../components/ChatWindow";
import TestNewChatWindow from "../components/TestNewChatWindow";
import ChatBox from "../components/ChatBox";
import getDocsFromCollection from "../api/chatDocument/getDocsFromCollection";
import ChatDocument from "../types/ChatDocument";
import FileUploadPage from "./FileUploadPage";

const customTheme = createTheme({
    
  palette: {
    primary: {
      main: '#101828',
      light:'#65748D',
      dark:'#040A14',
      contrastText: "#fff",
    },
    secondary: {
      main:  '#5D4682',
      contrastText: "#fff",
      
    },
    
  },
  
});


const useStyles=makeStyles(()=>({
  outerContainer:{
    backgroundColor:customTheme.palette.primary.main,
    maxWidth:'4000px',
    display:'flex',
    position:'relative',
    height:'100%',
    '@media (max-width: 640px)': {
      padding:0
    },
  },

})) 

export default function TestCollectionChat(){
    const {collectionId}: {
        collectionId: string
      } = useParams();
      
    const [myDocuments, setMyDocuments] = React.useState<ChatDocument[]>([]);
    const [myDocumentsLoading, setMyDocumentsLoading] = React.useState(true);
    const isNew = collectionId === 'new';
    const {height, width} = useWindowDimensions();
    const theme=customTheme
    const classes=useStyles();
    const [isFileUploadOpen, setIsFileUploadOpen] = useState<boolean>(false);

    const handleOpenFileUpload = () => {
      setIsFileUploadOpen(true);
    };

    const handleCloseFileUpload = () => {
      setIsFileUploadOpen(false);
    };

    useEffect(() => {
  
      async function fetchMyDocuments(){ 
        let documents = await getDocsFromCollection(collectionId);
        setMyDocuments(documents);
        setMyDocumentsLoading(false);
      }
  
      fetchMyDocuments();
      
    }, []);

    

    return(
      <ThemeProvider theme={theme}>
        {(!isFileUploadOpen)?  
        <Container className={classes.outerContainer}>
          <ChatProvider currentEntityId={collectionId} mode='CHAT_COLLECTION'>
            <TestNewChatWindow myDocuments={myDocuments} openFileUpload={handleOpenFileUpload} collectionId={collectionId}/>
          </ChatProvider>
        </Container>
        :
        <FileUploadPage chatCollectionId={collectionId} onClose={handleCloseFileUpload}/>  
      }
      </ThemeProvider>
    )
}