import React, { useEffect } from "react";
import getAllUsers, { UserDetails } from "../api/users/getAllUsers";


export interface UsersState {
  status: 'FETCHING' | 'FETCH_ERROR' | 'SUCCESS';
  error?: string;
  pageToken?: string;
  users?: UserDetails[];
};

export default function useUsers() {
  
  const [usersState, setUsersState] = React.useState<UsersState>({
    status: 'FETCHING'
  });
  // const [pageToken, setPageToken] = React.useState<string|undefined>();

  const fetchUsers = React.useMemo(() => async () => {
    setUsersState((prevState) => ({
      ...prevState,
      status: 'FETCHING'
    }));
    const resp = await getAllUsers(usersState.pageToken);
    if (resp.error || !resp.users) {
      setUsersState({
        status: 'FETCH_ERROR',
        error: resp.error
      })
    } else {
      setUsersState({
        status: 'SUCCESS',
        error: '',
        users: resp.users,
        pageToken: resp.pageToken
      })
    }
  }, [usersState.pageToken]);
  useEffect(() => {
    fetchUsers();
  }, []);
  const value = React.useMemo(() => {
    return {
      ...usersState,
      fetchUsers
    }
  }, [usersState, fetchUsers]);
  return value;
}