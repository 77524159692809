import { Button, CircularProgress, Grid, Paper, TextField } from "@mui/material";
import { Alert } from "@mui/lab";
import { CardElement, useStripe, useElements} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import createSubscription from "../api/subscription/createSubscription";
import useCoupon from "../hooks/useCoupon";
import useUser from "../hooks/useUser";
import PoweredByStripe from "../assets/power_by_stripe.svg"
import {Done} from "@mui/icons-material";
interface CreateSubscriptionProps {
  onSuccess?: () => void;
}

export default function CreateSubscription(props: CreateSubscriptionProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);
  const [coupon, setCoupon] = useState<string>('');
  const {user} = useUser();
  const {applyCoupon, couponState} = useCoupon(coupon);
  let originalAmount = 11;
  let amount = originalAmount;
  if (couponState.valid) {
    if (couponState.coupon?.amountOff) {
      amount -= couponState.coupon?.amountOff!;
    } else if (couponState.coupon?.percentOff) {
      amount -= amount*(couponState.coupon.percentOff/100);
    }
    
  }

  const [error, setError] = React.useState<string|undefined>();
  const [success, setSuccess] = React.useState<boolean|undefined>();
  const handleSubmit = async () => {

    if (!stripe || !elements) {
      return;
    }
    setSubmitting(true);

    const cardElement = elements.getElement(CardElement);


    // Use your card Element with other Stripe.js APIs
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement!,
      billing_details: {
        name: `${user?.firstName} ${user?.lastName}`,
        email: user?.email
      }
    });

    if (error) {
      setError(error.message);
      console.log('[error]', error);
      setSubmitting(false);
      return;
    } 
    
    console.log('[PaymentMethod]', paymentMethod);
    const {subError, subscription} = await createSubscription(
      paymentMethod!,
      couponState.valid ? couponState.coupon?.promoCodeId : undefined);
    console.log("subscription", subscription);  
    
    if (subError) {
      setSubmitting(false);
      setError(subError);
    } else {
      setSubmitting(false);
      setSuccess(true);
      props.onSuccess && props.onSuccess();
    }

  };
  return (
    <Paper style={{maxWidth: '400px', padding: '1em'}}>
      <Grid container>
        {success &&
          <Grid item xs={12}>
            <Alert severity="success">Subscription created!</Alert>
          </Grid>
        }
        {!success && 
          <>
            {error &&
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            }
            <Grid 
              item xs={12}
              style={{
                  marginTop: '1em',
                  marginBottom: '1em',
                  borderStyle: 'solid',
                  padding: '10px',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  borderColor: 'rgb(196 196 196)'
                }}
              >
                <CardElement
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                    />
            </Grid>
            <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                <TextField 
                  label="Coupon"
                  onChange={(e) => {
                    setCoupon(e.currentTarget.value?.toUpperCase());
                  }}
                  value={coupon}
                  error={couponState.valid !== undefined && !couponState.valid}
                  helperText={couponState.error}
                  variant="outlined" 
                  size="small"/>
                <Button
                  style={{marginLeft: '10px'}}
                  variant="outlined"
                  onClick={() => applyCoupon(coupon)}
                  size="small">Apply</Button>
            </Grid>
            
            <Grid item xs={12}
              style={{display: 'flex', alignItems: 'center', marginBottom: '0.5em'}}
            >
              {couponState.valid && 
                <>
                  <Done htmlColor="green" fontSize={"small"}/> Applied
                </>
              }
            </Grid>
                
            <Grid item xs={8} style={{display: 'flex', alignItems: 'center'}}>
                <b>Total</b>: ${amount.toFixed(2)} USD / month
            </Grid>
            <Grid item xs={4} style={{textAlign: 'right'}}>
              <Button
                disabled={!stripe || submitting}
                onClick={handleSubmit}
                variant="contained" color="primary"
              >
                {submitting && 
                  <CircularProgress size="20px" style={{color: 'white', marginRight: '10px'}}/>}
                Subscribe</Button>
            </Grid>
            {couponState.valid && couponState.coupon?.duration !== "forever" &&
              <Grid item xs={12}>
                After {couponState.coupon?.duration === "once" ? "1" : couponState.coupon?.durationInMonths} month{(couponState.coupon?.durationInMonths || 0) > 0 ? 's' : ''}, it will be $ {originalAmount.toFixed(2)} USD/month
              </Grid>
            }
          </>
        }
        <img style={{maxHeight: '25px', marginTop: '1em'}} src={PoweredByStripe} alt="powered by stripe"/>
      </Grid> 

    </Paper>
  )
}