import { Container, Chip, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import Course, { CourseModule } from "../../types/Course";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Link } from "react-router-dom";
import MarkdownToHTML from "../MarkdownToHTML";
import { CourseProgress } from "../../api/courses/getCourseProgress";
import { useEffect, useState } from "react";
import getAllCourseProgress, { AllCourseProgress } from "../../api/courses/getAllCourseProgress";
import useUser from "../../hooks/useUser";
import { Done, ExpandLess, ExpandMore, Lock } from "@mui/icons-material";
import { useContext } from "react";
import SubscriptionContext from "../../context/SubscriptionContext";
import { Alert } from "@mui/lab";
import { HashLink } from "react-router-hash-link";
import Metadata from "../Metadata";
import CourseContext from "../../context/CourseContext";
import ModuleDrawerSection from "./ModuleDrawerSection";
import { getCourseGenerationStatus } from "../../api/courses/getGenerationStatus";
import ShareCourseButton from "./ShareCourseButton";
import FeedbackButton from "./FeedbackButton";
import '../../styles/CircularProgress.css';
import { makeStyles } from '@mui/styles';

const useStyles=makeStyles(()=>({
  title:{
    '@media (max-width: 500px)': {
      fontSize:'3em',
    },
  }
}))

export default function CourseOverview() {
  const classes=useStyles()
  const {
    course,
    status,
    courseProgress: currentUserProgress,
    fetchCourse,
    isPublic,
  } = useContext(CourseContext)
  const [allProgress, setAllProgress] = useState<AllCourseProgress['allProgress']|undefined>(undefined);
  const {user} = useUser();
  const [selectedUserProgress, setSelectedUserProgress] = useState<string>('');
  const [courseProgress, setCourseProgress] = useState<CourseProgress|undefined>(currentUserProgress);
  useEffect(() => {
    if (!courseProgress && currentUserProgress) {
      setCourseProgress(currentUserProgress);
    }
  }, [courseProgress, currentUserProgress]);
  const subscriptionContext = useContext(SubscriptionContext);
  useEffect(() => {
    async function fetchData() {
      if (user?.admin && !allProgress && course?.courseId) {
        const resp = await getAllCourseProgress(course.courseId);
        setAllProgress(resp.allProgress);
      }
    }
    
    fetchData();
  }, [course, user, allProgress]);

  useEffect(() => {
    // polls for course generation status if GENERATED course, until generation is complete or fails
    let stopPolling = false;
    async function courseGenerationPoller() {
      if (!course || course.courseAuthorType !== 'GENERATED') {
        return;
      }
      while (!stopPolling) {
        const status = await getCourseGenerationStatus(course.courseId);
        if (status === 'COMPLETE' || status === 'FAILED') {
          fetchCourse();
          break;
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
    if (course && course.courseAuthorType === 'GENERATED' && ['REQUESTED', 'IN_PROGRESS'].includes(course.generationStatus)) {
      courseGenerationPoller();
    }
    return () => {
      // cleanup
      stopPolling = true;
    }
  }, [course]);

  return !course ? 
    null
  : (
    <Container>
      <Grid container style={{marginTop: '2em'}}>
        <Metadata
          title={course.title + " - Learnly"}
          description={course.description}
        />
        <Grid xs={12} item>
          <Typography variant="h2" className={classes.title}>
            {course.title}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{marginTop: '2em'}}>
          <Typography variant="h5">
            Overview
          </Typography>
          <br/>
          <MarkdownToHTML content={course.description}/>
          {course.courseAuthorType !== 'GENERATED' && isPublic &&
          <Alert severity="warning" id="subscription-warning">
          <Link to="/edu/home">Sign up now</Link> to get your access code to this course
          </Alert>}
          {/* {course.courseAuthorType !== 'GENERATED' && !subscriptionContext.isSubscribed && subscriptionContext.subscriptionStatus !== 'past_due' && 
          <Alert severity="warning" id="subscription-warning">
            {isPublic ? 'Sign up to get your access code to this course' : 'Ping me at zaid@learnly.dev to get free access code.'}
            {' '}<Link to="/edu/profile">Sign up now</Link>
          </Alert>}
          {course.courseAuthorType !== 'GENERATED' && subscriptionContext.subscriptionStatus === 'past_due' && 
          <Alert severity="warning" id="subscription-warning">
            Your account is past due.
            {' '}<Link to="/edu/profile">Click here to update your payment method</Link>
          </Alert>} */}
        </Grid>
        {course.courseAuthorType !== 'GENERATED' && user?.admin && allProgress && 
          <Grid item xs={12}>
            <FormControl style={{minWidth: '150px'}}>
              <InputLabel id="userForProgressLabel">User for progress</InputLabel>
              <Select
                labelId="userForProgressLabel"
                id="userForProgressSelect"
                value={selectedUserProgress}
                onChange={(e) => {
                  setSelectedUserProgress(e.target.value as string);
                  setCourseProgress(
                    !e.target.value ? currentUserProgress :
                    allProgress.find(p => p.username === e.target.value)
                  )
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {
                  allProgress.map(userProgress => (
                    <MenuItem 
                      key={userProgress.username}
                      value={userProgress.username}>
                        {userProgress.username}, {userProgress.firstName} {userProgress.lastName}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        }
        <Grid item xs={12}>
          <Typography variant="h5" style={{marginTop: '1em', display: 'flex', marginBottom: '1em'}}>
            Contents
            <span style={{flex: 1, display: 'flex', justifyContent:"flex-end"}}>
            {(!!user && <FeedbackButton entityId={course.courseId} />)}
              <ShareCourseButton userSignedIn={!!user} courseId={course.courseId}/>
            </span>    
          </Typography>
          {course.courseAuthorType === 'GENERATED' && course.generationStatus !== 'COMPLETE' &&
            <>
              {course.generationStatus === 'FAILED' &&
                <Alert severity="error">
                  Course generation failed, we are looking into the issue.
                </Alert>
              }
              {['REQUESTED', 'IN_PROGRESS'].includes(course.generationStatus) && <>
                <Alert severity="info">
                  Finding awesome content for you... This can take a minute or two...
                  <br/>
                  <br/>
                  We will email you when the course is ready!
                </Alert>
                <CircularProgress className="spaced-circular-progress"/>

              </>}
            </>
          }
          {course.modules?.map((module, i) => {
              return (
                <ModuleDrawerSection
                  key={i}
                  indexOfModule={i}
                  course={course}
                  courseProgress={courseProgress}
                  module={module}
                  isPublic={isPublic}
                />
              );
            })}
          
        </Grid>
        {!course.contentComplete && 
          <Grid item xs={12}>
            <Typography variant="h4" style={{marginTop: '1em', marginBottom: '1em'}}>
              This course is still under development, more content is being added as we proceed.
            </Typography>
          </Grid>
        }
        
      </Grid>
    </Container>
    
  ) 
}