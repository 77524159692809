
import { Remark } from 'react-remark';
import externalLinks from 'remark-external-links';
import remarkGfm from 'remark-gfm';
import MarkdownCode from './custom-markdown/MarkdownCode';
import MarkdownImage from './custom-markdown/MarkdownImage';
import MarkdownInlineCode from './custom-markdown/MarkdownInlineCode';

export default function MarkdownToHTML({
  content
}: {
  content?: string
}) {
  return !content ? null : (
    <div className="remark-md" style={{marginRight:'0.5em'}}>
      <Remark
        remarkPlugins={[externalLinks, remarkGfm]}
        rehypeReactOptions={{
          components: {
            img: (props: any) => <MarkdownImage {...props}/>,
            pre: (props: any) => <MarkdownCode {...props}/>,
            code: (props: any) => <MarkdownInlineCode {...props}/>
          }
        }}
      >
        {content.trim()}
      </Remark>
    </div>
  )
}