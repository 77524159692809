import { TabContext, TabPanel } from "@mui/lab";
import { Grid, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { BasePart, Link, SearchResultPart } from "../../types/Course";
import PartComponentProps from "./PartComponentPropType";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function YoutubeSearch({
  part
}: PartComponentProps) {
  const searchPart: SearchResultPart | undefined = part as SearchResultPart;
  const [value, setValue] = useState("google");

  if (!searchPart || (!searchPart.content && !searchPart.links)) return null;
  const queryString = searchPart.content?.substring(searchPart.content.indexOf('=') + 1);

  const googleSearchLink = "https://www.google.com/search?q=" + queryString;
  if (!searchPart.links || searchPart.links.length === 0) {
    return (
      <div>
      <h3>Google search</h3>
        <a rel="noreferrer"href={googleSearchLink}target="_blank">See google results</a>
        <h3>Youtube search</h3>
        <a rel="noreferrer" href={searchPart.content} target="_blank">See youtube videos</a>
      </div>
    )
  };

  const googleLinks = searchPart.links.filter(link => link.sourceType === 'GOOGLE');
  const youtubeLinks = searchPart.links.filter(link => link.sourceType === 'YOUTUBE');

  return !searchPart ? null : 
  
  <Accordion style={{backgroundColor:'#0f1624'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Search results</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div>
          <TabContext value={value}>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={(e, newVal) => setValue(newVal)} style={{fontWeight:'900', fontSize:'inherit'}}>
                <Tab label="Google" value="google"/>
                <Tab label="Youtube" value="youtube" />
              </Tabs>
            </Box>
            <TabPanel value="google">
              <Grid container spacing={2}>
                {googleLinks.map(link => {
                  return (
                    <Grid item xs={12} key={link.url}>
                      <GoogleSearchResult {...link} />
                    </Grid>
                  )
                })}
                <a 
                  rel="noreferrer" 
                  href={"https://www.google.com/search?q" + searchPart.content?.substring(searchPart.content.indexOf('='))}
                  style={{marginTop: '1.5em'}}
                  target="_blank">See google results</a>
              </Grid>
            </TabPanel>
            <TabPanel value="youtube">
              <Grid container spacing={2}>
                {youtubeLinks.map(link => {
                  return (
                    <Grid item xs={12} key={link.url}>
                      <YoutubeSearchResult {...link} />
                    </Grid>
                  )
                })}
                <a 
                  rel="noreferrer" 
                  href={searchPart.content}
                  style={{marginTop: '1.5em'}}
                  target="_blank">See youtube results</a>
              </Grid>
            </TabPanel>
          </TabContext>
        </div>
        </AccordionDetails>
      </Accordion>
   
}

// renders the search result similar to googles page with title and description
function GoogleSearchResult(link: Link) {
  
  return (
    <a href={link.url} key={link.url} target="_blank" rel="noreferrer">
      <div>
        <Typography variant="h5" style={{fontSize: '1.2em', fontWeight: 'bold'}}>{link.title}</Typography>
        <Typography variant="body1">{link.description}</Typography>
        <Typography variant="subtitle2">{(link.displayLink || link.url).substring(0, 50)}</Typography>
      </div>
    </a>
  );
}

// renders search result for youtube, sets thumbnail as background picture of the tile
function YoutubeSearchResult(link: Link) {
  let thumbnail = link.thumbnails && link.thumbnails.length > 0 ? link.thumbnails[0] : undefined; 
  return (
    <a href={link.url} target="_blank" rel="noreferrer">
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div style={!thumbnail ? undefined : {
            backgroundImage: `url(${thumbnail?.url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            width: '100%',
            display: "inline-block",
          }}>
            
          </div>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="h5" style={{fontSize: '1.2em', fontWeight: 'bold'}}>{link.title}</Typography>
          <Typography variant="body1">{link.description}</Typography>
        </Grid>
      </Grid>
      
     

    </a>
  );
}