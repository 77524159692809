import { CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import AttemptQuiz from "../../../components/course/quiz/AttemptQuiz";
import useAllAttempts from "../../../hooks/useAllAttempts";
import useUsers from "../../../hooks/useUsers";
import { QuizAttempt } from "../../../types/Quiz";
import moment from "moment";
import MarkdownToHTML from "../../../components/MarkdownToHTML";
import useQuery from "../../../hooks/userQuery";

export default function ViewQuizAttempts() {
  const {quizId} = useParams() as {
    quizId: string
  };
  let query = useQuery();
  let userIdInQuery = query.get("userId") || undefined;

  const usersState = useUsers();
  const [currentUserSub, setCurrentUserSub] = useState<string|undefined>(userIdInQuery);
  const allAttemptsState = useAllAttempts(quizId, currentUserSub);
  const [attemptTimestamp, setAttemptTimestamp] = useState<string|undefined>();

  const attempt: QuizAttempt | undefined = useMemo(() => 
    allAttemptsState?.attempts?.find(attempt => attempt.timestamp === attemptTimestamp)
  , [allAttemptsState?.attempts, attemptTimestamp]);
  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          {(usersState.status === 'FETCHING' || allAttemptsState.status === 'FETCHING') &&
            <CircularProgress/>
          }
        </Grid>
        <Grid item xs={12}>

          <FormControl style={{minWidth: '150px'}}>
              <InputLabel id="userForProgressLabel">User for quiz</InputLabel>
              <Select
                labelId="userForProgressLabel"
                id="userForProgressSelect"
                value={currentUserSub}
                onChange={(e) => {
                  setCurrentUserSub(e.target.value as string);
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {
                  usersState.users?.map(user => (
                    <MenuItem 
                      key={user.username}
                      value={user.sub}>
                        {user.username}, {user.firstName} {user.lastName}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <FormControl style={{marginLeft: '1em', minWidth: '250px'}}>
              <InputLabel id="attemptTimestampLabel">Attempt timestamp</InputLabel>
              <Select
                labelId="attemptTimestampLabel"
                id="attemptTimestamp"
                value={attemptTimestamp}
                onChange={(e) => {
                  setAttemptTimestamp(e.target.value as string);
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {
                  allAttemptsState.attempts?.map(attempt => (
                    <MenuItem 
                      key={attempt.timestamp!}
                      value={attempt.timestamp}>
                        {moment(attempt.timestamp).format('MMM D, YYYY LT')}, {attempt.score}/{attempt.maxScore}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
        </Grid>
        <Grid item xs={12}>
          {!attempt && 
            <p>Please select an attempt</p>
          }
          {attempt &&
            <AttemptQuiz 
              quizId={quizId}
              previousAttempt={attempt}
              onFinish={() => {alert('not implemented')}}
            />
          }
        </Grid>
      </Grid>
    </Container>
  )
}

