import React from "react";
import ChatContext, { IChatContext } from "./ChatContext";
import useChat from "../hooks/useChat";


export default function ChatProvider(props: { 
  mode?: IChatContext['mode'];
  currentEntityId: string;
  children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) {
  const chatStore = useChat(props.currentEntityId, props.mode);
  
  return (
    <ChatContext.Provider value={chatStore}>
      {props.children}
    </ChatContext.Provider>
  )
}