// a hook that provides an implementation of IChatContext
// on each course page setContext will be called with text of the current context
// maintain a list of messages for the entire session, save messages in local storage
// fetch messages from local storage on page load
// on each message send, add the message to the list of messages in "LOADING" status and save to local storage
// on message send success update the message status to "SUCCESS", save the response text with user id "bot" and save to local storage
// on message send error update the message status to "ERROR" and save to local storage
// on page load, fetch messages from local storage with "LOADING" status and update status to "SUCCESS"

import { useEffect, useMemo, useRef, useState } from "react";
import { IChatContext, Message } from "../context/ChatContext";
import { v4 as uuidv4 } from 'uuid';
import { sendBotMessageSync } from "../api/chat/sendBotMessageSync";
import useUser from "./useUser";
import { ChatSendMessageResponse, sendDocumentBotMessageSync } from "../api/chatDocument/sendBotMessageSync";
import { sendCollectionBotMessageSync } from "../api/chatCollection/sendColBotMessageSync";
import useWebsocket from "./useWebsocket";

interface MessageGateway {
  currentUserId: string;
  addTextMessage: (text: string) => Promise<Message>;
  addMessage: (message: Message) => Promise<Message[]>;
  updateMessage: (message: Message) => Promise<Message[]>;
  updateMessageStatus: (messageId: string, status: Message['sendStatus']) => Promise<void>;
  fetchMessages: () => Promise<Message[]>;
  getMessageById: (messageId: string) => Promise<Message | undefined>;
}

class MessageGatewayLocalStorage implements MessageGateway {
  currentUserId: string;
  currentEntityId: string;
  
  constructor(currentUserId: string, currentEntityId: string) {
    this.currentUserId = currentUserId;
    this.currentEntityId = currentEntityId;
  }
  updateMessage = async (message: Message) => {
    const messages = await this.fetchMessages();
    // find message traversing from the end
    let messageIdx = undefined;
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i].id === message.id) {
        messageIdx = i;
        break;
      }
    }

    if (messageIdx && messageIdx >= 0) {
      messages[messageIdx] = message;
      this._saveMessages(messages);
    }
    return messages;
  }

  addTextMessage = async (text: string) => {
    const message: Message = {
      sendStatus: 'LOADING',
      id: uuidv4(),
      text,
      createdAt: new Date(),
      user: {
        id: this.currentUserId
      }
    };
    await this.addMessage(message);
    return message;
  }

  _getMessagesIndex(): string {
    return 'messages-' + this.currentUserId + '-' + this.currentEntityId;
  }
  _saveMessages(messages: Message[]) {
    localStorage.setItem(this._getMessagesIndex(), JSON.stringify(messages));
  }

  addMessage = async (message: Message) => {
    const messages = await this.fetchMessages();
    messages.push(message);
    this._saveMessages(messages);
    return messages;
  }

  getMessageById = async (messageId: string) => {
    const messages = await this.fetchMessages();
    // find message traversing from the end
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i].id === messageId) {
        return messages[i];
      }
    }
    return undefined;
  }

  fetchMessages: () => Promise<Message[]> = async () => {
    const messages = localStorage.getItem(this._getMessagesIndex());
    if (messages) {
      return JSON.parse(messages);
    }
    return [];
  }

  updateMessageStatus = async (messageId: string, status: Message['sendStatus']) => {
    const messages = await this.fetchMessages();
    const message = messages.find(message => message.id === messageId);
    if (message) {
      message.sendStatus = status;
      this._saveMessages(messages);
    }
  }
}

/**
 * Filters to latest messages, such that the total number of words in the messages is less than maxWords
 * 
 * If the total number of words in the messages is greater than maxWords, the old messages are removed until the total number of words is less than maxWords
 * 
 * @param messages 
 * @param maxWords 
 */
function filterMessages(messages: Message[], maxWords: number) {
  let totalWords = 0;
  let filteredMessages: Message[] = [];
  for (let i = messages.length - 1; i >= 0; i--) {
    const message = messages[i];
    const words = message.text.split(' ').length;
    if (totalWords + words > maxWords) {
      break;
    }
    totalWords += words;
    filteredMessages.push(message);
  }
  return filteredMessages.reverse();
}

const useChat = (currentEntityId: string, mode?: IChatContext['mode']): IChatContext => {

  const userStore = useUser();

  const currentUserId = userStore.user?.id || 'anonymous';


  const messageGatewayRef = useRef<MessageGatewayLocalStorage>();

  useEffect(() => {
    messageGatewayRef.current = new MessageGatewayLocalStorage(currentUserId, currentEntityId);
    console.log("refreshing message gateway, current entity id: "+ currentEntityId);
  }, [currentUserId, currentEntityId]);


  const messageGateway = useMemo(() => messageGatewayRef.current, [messageGatewayRef]);


  const [context, setContext] = useState<string>('');
  const [status, setStatus] = useState<IChatContext['status']>('INITIAL');
  const [messages, setMessages] = useState<Message[]>([]);

  const filterMessage = useMemo(() => (message: string) => {
    try {
      const data = JSON.parse(message);
      console.log("filtering msg: " +currentEntityId)
      console.log("filtering msg, data chat Id: " +data.chatId)
      return data.chatId === currentEntityId;
    } catch (err) {
      return false;
    }
  }, [currentEntityId]);

  const onMessage = useMemo(() => async (message: string) => {
    const data = JSON.parse(message);
    if (!data.payload?.messageId) {
      console.log('no message id')
      return;
    }
    if (!messageGatewayRef.current) {
      console.log('no message gateway')
      return;
    }
    let messageGateway = messageGatewayRef.current;
    const dbMessage = await messageGateway.getMessageById(data.payload.messageId);
    if (!dbMessage) {
      let newMessages = await messageGateway.addMessage({
        sendStatus: 'SUCCESS',
        id: data.payload.messageId,
        text: data.payload.text,
        createdAt: new Date(),
        updatedAt: data.timestamp_ns,
        user: {
          id: 'bot'
        }
      });
      setMessages(newMessages);
      return;
    }
    if (dbMessage.updatedAt && dbMessage.updatedAt > data.timestamp_ns) {
      return;
    }

    dbMessage.text = data.payload.text;
    dbMessage.updatedAt = data.timestamp_ns;

    let newMessages = await messageGateway.updateMessage(dbMessage);
    setMessages(newMessages);
  }, []);
  const onMessageError = useMemo(() => async (message: string, error: Event) => {
    console.error('websocket error', message, error);
  }, []);
  const websocket = useWebsocket(
    filterMessage,
    onMessage,
    onMessageError
  );

  const sendMessage = async (text: string) => {
    if (!websocket.isConnected) {
      alert("Disconnected from server, please refresh the page");
      return;
    }
    if (!messageGatewayRef.current) {
      console.log('no message gateway')
      return;
    }
    let messageGateway = messageGatewayRef.current;
    const message = await messageGateway.addTextMessage(text);
    let latestMessages = await messageGateway.fetchMessages();
    setMessages(latestMessages);
    try {
      latestMessages = filterMessages(latestMessages, 500);

      let response: ChatSendMessageResponse | undefined = undefined;

      if (mode === 'CHAT_DOCUMENT') {
        response = await sendDocumentBotMessageSync(currentEntityId, latestMessages, context ? JSON.parse(context) : {});
      } else if(mode === 'CHAT_COLLECTION'){
        response = await sendCollectionBotMessageSync(currentEntityId, latestMessages, context ? JSON.parse(context) : {});
      }else {
        response = await sendBotMessageSync(latestMessages, context);
      }

      
      if (!response) {
        throw new Error('no response');
      }
      await messageGateway.updateMessageStatus(message.id, 'SUCCESS');

      let dbMessage = undefined
      if (response.messageId) {
        dbMessage = await messageGateway.getMessageById(response.messageId);
      }
      if (dbMessage) {
        dbMessage.text = response.response || '';
        await messageGateway.updateMessage(dbMessage);
      } else {
        await messageGateway.addMessage({
          sendStatus: 'SUCCESS',
          id: response.messageId || uuidv4(),
          text: response.response || '',
          createdAt: new Date(),
          user: {
            id: 'bot'
          }
        });
      }

    } catch (err) {
      // await messageGateway.updateMessageStatus(message.id, 'ERROR');
      // temporarily set all message status as success
      await messageGateway.updateMessageStatus(message.id, 'SUCCESS');
      console.error(err);
    } finally {
      fetchMessages();
    }
  }

  const fetchMessages = useMemo(() => async () => {
    setStatus('LOADING');
    try {
      if (!messageGatewayRef.current) {
        console.log('no message gateway')
        return;
      }
      let messageGateway = messageGatewayRef.current;
      const messages = await messageGateway.fetchMessages();
      setMessages(messages);
      setStatus('SUCCESS');
    } catch (err) {
      console.error(err);
      setStatus('ERROR');
    }
  }, [messageGateway]);

  useEffect(() => {
    if (status === 'INITIAL' && currentUserId !== 'anonymous')
      fetchMessages();
  }, [fetchMessages, currentUserId, status]);

  useEffect(() => {
    async function addBotGreeting() {
      if (!messageGatewayRef.current) {
        console.log('no message gateway')
        return;
      }
      let messageGateway = messageGatewayRef.current;
      let currentMessages = await messageGateway.fetchMessages();
      if (status === 'SUCCESS' && currentMessages.length === 0) {
        // push one bot greeting message for course
        
        await messageGateway.addMessage({
          sendStatus: 'SUCCESS',
          id: uuidv4(),
          text: `Hello! I am a bot. I can answer questions about the ${mode === 'CHAT_DOCUMENT' ? 'document' : mode === 'CHAT_COURSE' ? 'course' : 'collection'}.`,
          createdAt: new Date(),
          user: { 
            id: 'bot'
          }
        });

        fetchMessages();
      }
    }
    addBotGreeting();
  }, [status, messages, messageGateway, fetchMessages]);

  return {
    mode,
    currentEntityId,
    currentUserId,
    messages,
    sendMessage,
    setContext,
    fetchMessages,
    status
  };


}


export default useChat;