import Course from "../../types/Course";
import ApiRequest from "../apiRequest";

export async function courseGenerationRequest(title: string, description: string, collectionId?: string): Promise<Course> {
  try {
    let result: Course = await ApiRequest(
      `/api/course/generate`,
      'POST',
      JSON.stringify({
        title,
        description,
        collectionId: collectionId && collectionId.length > 0 ? collectionId : undefined
      })
    )
    return result;
  } catch (err) {
    console.error("could not request course generation", err);
    throw err;
  }
}