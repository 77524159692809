import { Typography } from "@mui/material";
import { BasePart } from "../../types/Course";
import PartComponentProps from "./PartComponentPropType";

export default function CourseImage({
  part
}: PartComponentProps) {
  return (<div style={{textAlign: part?.additionalAttributes?.textAlign || "center"}}>
    <img src={part?.content} style={part?.additionalAttributes?.style || {maxWidth: '80vw'}} alt={part?.additionalAttributes?.caption}/>
    <br/>
    <Typography variant="caption">{part?.additionalAttributes?.caption}</Typography>
  </div>); 
}