import React from "react";
import { useEffect,useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  NavLink,
} from "react-router-dom";
import './App.css';
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import HomePage from "./containers/HomePage";
import ContactUs from "./containers/ContactUs/ContactUs";
import FAQ from "./containers/FAQs/FAQs";
import SubscriptionProvider from "./context/SubscriptionProvider";
import ReactGA from 'react-ga';
import TermsAndConditions from "./containers/TermsAndConditions";
import GettingStarted from "./containers/GettingStarted/GettingStarted";
import { Container, createTheme, Divider, Grid, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { StyledEngineProvider } from "@mui/material/styles";
import { withAuthenticator, useAuthenticator, useTheme, Heading, Authenticator, CheckboxField } from '@aws-amplify/ui-react';
import EduRoutes from "./containers/EduRoutes";
import '@aws-amplify/ui-react/styles.css';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PublicCoursePage from "./containers/PublicCoursePage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./components/Footer";
import FAQs from "./containers/FAQs/NewFAQs";
import AboutUs from "./containers/AboutMe/NewAboutUs";
import Pricing from "./containers/Pricing";
import EmployerUseCase from "./containers/UseCases/EmployerUseCase";
import EmployeeUseCase from "./containers/UseCases/EmployeeUseCase";
import StudentUseCase from "./containers/UseCases/StudentUseCase";

const queryClient = new QueryClient()


const trackingId = "G-HKXP965KQW"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.addTrackers('AW-984448822');

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  ReactGA.set({ page: pathname }); // Update the user's current page
  ReactGA.pageview(pathname); // Record a pageview for the given page
  return null;
}

Amplify.configure(awsconfig);
function App() {
  // const {isUserSignedIn, user} = useAuthState();

  // const [authState, setAuthState] = React.useState<AuthState>();
  // const [user, setUser] = React.useState<any>();

  const [showFooter, setShowFooter] = useState(true);
  const newFont =  "'DM Sans', sans-serif";
  const theme = createTheme({
    
    palette: {
      mode: 'dark',
      primary: {
        main: '#5D4682',
        contrastText: "#fff",
      },
      secondary: {
        main:  '#878787'
        
      },
      info:{
        main:'#5D4682',
        dark:'#4c396b'
      },
      background: {
        default: "#222222"
      }
      
      
    },
    
  });
  
  
  
  return (
    <>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <Router>
            <ScrollToTop/>
            <div style={{display: 'flex', height: '100%', flexDirection: 'column'}}>

              <div style={{flex: 1,backgroundColor:'#101828',color:'#fff'}}>
                {/* A <Switch> looks through its children <Route>s and
                    renders the first one that matches the current URL. */}
                <Switch>
                  <Route path="/tc">
                    <TermsAndConditions/>
                  </Route>
                  <Route path="/edu">
                    <EduRoutes/>
                  </Route>
                  <Route path="/faq">
                    <FAQ/>
                  </Route>
                  <Route path="/contact-us">
                    <ContactUs/>
                  </Route>
                  <Route path="/getting-started">
                    <GettingStarted/>
                  </Route>
                  <Route path="/course/:courseId">
                    <PublicCoursePage/>
                  </Route>        
                  <Route path="/pricing">
                    <Pricing/>
                  </Route>
                  <Route path="/anything">
                    <HomePage/>
                  </Route>
                  <Route path="/faqs">
                    <FAQs/>
                  </Route>
                  <Route path="/about-us">
                    <AboutUs/> 
                  </Route>
                  <Route path="/employers">
                    <EmployerUseCase/>
                  </Route>
                  <Route path="/employees">
                    <EmployeeUseCase/>
                  </Route>
                  <Route path="/students">
                    <StudentUseCase/>
                  </Route>
                  <Route path="/">
                    <HomePage/>
                  </Route>
                </Switch>
              </div>
              <Footer/>
            </div>
          </Router>
          <ToastContainer/>
        </StyledEngineProvider>
      </ThemeProvider>
    </QueryClientProvider>
    </>
  );
}

export default App;
