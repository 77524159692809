import React from "react";
import { BasePart } from "../../types/Course";
import MarkdownToHTML from "../MarkdownToHTML";
import PartComponentProps from "./PartComponentPropType";

export default function CourseExerciseUpload({
  part
}: PartComponentProps) {
  return <div>

    <MarkdownToHTML content={part?.content} />
    <div>
      Please email <a href="mailto:zaid@learnly.dev">zaid@learnly.dev</a> with subject  "Exercise: {part?.title}" and the output (image, pdf, link, or zipped folder) of the exercise. I will respond back with any feedback.
    </div>
  </div>; 
}