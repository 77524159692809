import { Button, Container, Fab, Grid, Paper, Theme, Typography,useMediaQuery,useTheme } from "@mui/material";
import NewNavBar from "../../components/NewNavBar";
import { useHistory } from "react-router";
import { makeStyles } from '@mui/styles';
import { ThemeProvider,createTheme } from '@mui/material/styles';
import ZaidQ from '../../assets/Zaid-Qureshi.jpg'
import IhtishamQ from '../../assets/Ihtisham-Qureshi.jpg'


const theme = createTheme({
    palette: {
        info:{
            main:'#5D4682',
            dark:'#4c396b'  
        }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1920,
      },
    },
  });

const useStyles=makeStyles(()=>({
    root:{
        
    }
}))


export default function AboutUs(){
    const history = useHistory();
    const classes=useStyles();

    return(
        <ThemeProvider theme={theme}>
        <Container maxWidth={"xl"} className={classes.root}>
            <NewNavBar/>
            <Container maxWidth={"lg"} style={{margin:'20px 0'}}>
                <Typography variant="h4" component="h1" style={{marginBottom:'10px', textAlign:'center'}}>About Us</Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                    <div style={{ padding: theme.spacing(2), marginTop:'10px' }}>
                        <Typography variant="h5">Learnly's Mission</Typography>
                        <Typography style={{marginTop:'5px'}}>
                            Our mission is to make learning anything personable and accessible to all. We do this by organizing the world’s and your private information into custom interactive gurus and learning journeys.
                        </Typography>
                    </div>
                    </Grid>
                    <PersonalInformation heading={"Meet Zaid, Our Founder"} description={"Zaid is a seasoned software engineer with 10+ years of working with top tier organizations. Zaid is passionate about enabling learning journeys for all. Zaid has successfully trained many to become software engineers like himself. Each iteration took 6 months of his time. Realizing the time spent, he wanted to help streamline the process by leveraging technology. This led to the birth of Learnly. "} imageURL={ZaidQ} imgDesc={"Zaid Qureshi"}/>
                    <PersonalInformation heading={"Meet Ihtisham, Our Co-Founder"} description={"Ihtisham is the founding partner of a boutique management consulting firm. Ihtisham has overseen dozens of digital transformations and now specializes in AI transformations. As a student of knowledge Ihtisham also wanted to leverage AI to determine how learning can be made ‘fun’, personable and accessible to all. Ihtisham loves to travel the world which fuels his learning experiences exponentially. 🙂"} imageURL={IhtishamQ} imgDesc={"Ihtisham Qureshi"}/>
                    <PersonalInformation heading={"Meet Fahad, Our Developer"} description={"Fahad is primarily a dedicated researcher and a Computer Science graduate with over three years of experience in web development, he has been an integral part of our company since its inception. His journey with technology has been a dynamic one, constantly evolving with the ever-changing digital landscape. Lately, Fahad has developed a keen interest in harnessing the power of AI to enhance our solutions and streamline processes. This enthusiasm for cutting-edge technology is what led him to join Learnly, where he's excited to contribute his skills and be a part of innovative projects that push the boundaries of what's possible in our field."}/>
                </Grid>
            </Container>
        </Container>
        </ThemeProvider>
    )
}



function PersonalInformation({ heading, description, imageURL, imgDesc }:{ heading: string, description: string, imageURL?: string, imgDesc?: string }){
    return (
    <Grid item xs={12}>
        <div style={{ padding: theme.spacing(2) }}>
                {imageURL && 
                    <div>
                        <img src={imageURL} alt={imgDesc} style={{width:'120px', float:'right', marginLeft:'3px', borderRadius:'10px'}}/>
                    </div>
                }
                <div>
                    <Typography variant="h5">{heading}</Typography>
                    <Typography style={{marginTop:'5px'}}>
                        {description}
                    </Typography>
                </div>
        </div>

    </Grid>
    )
}