
import { Button, CircularProgress, Container, Grid, Paper, Typography, makeStyles } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Metadata from '../components/Metadata';
import ChatDocument from '../types/ChatDocument';
import ChatYoutubeVideo from '../types/ChatYoutubeVideo';
import getAllDocuments from '../api/chatDocument/getAllDocuments';
import getAllVideos from '../api/chatVideo/getAllVideos';
import moment from 'moment';
import { Theme } from '@mui/material';



export default function DocVidMainPage() {


  const [myDocuments, setMyDocuments] = React.useState<ChatDocument[]>([]);
  const [myVideos, setMyVideos] = React.useState<ChatYoutubeVideo[]>([]);
  const [myDocumentsLoading, setMyDocumentsLoading] = React.useState(true);
  const [myVideosLoading, setMyVideosLoading] = React.useState(true);

    
  React.useEffect(() => {
    async function fetchMyDocuments(){ 
      let documents = await getAllDocuments();
      documents = documents.filter((document) =>document.contentType === 'application/pdf');
      setMyDocuments(documents);
      setMyDocumentsLoading(false);
    }
    
    async function fetchMyVideos() {
      let videos = await getAllVideos();
      videos = videos.filter((video) =>video.contentType === 'video/youtube');
      console.log(videos)
      setMyVideos(videos);
      setMyVideosLoading(false);
    }
    fetchMyDocuments();
    fetchMyVideos();
    
  }, []);

  return (
    <Container style={{}}>
        <Metadata
        title={"Learnly edu"}
        description={"Start working on your programming journey today!"}
        />
            <Grid container style={{ minHeight: '600px' }} >
              <Grid item xs={12} style={{marginTop: '2em'}}>
                <Typography variant="h2">
                  AI powered chat
                </Typography>
                <Typography variant="subtitle1" style={{paddingLeft: '1em', fontStyle: 'italic'}}>
                  Chat with Documents or Videos to learn anything you want.
                </Typography>
                <Button component={Link} 
                        style={{margin: '0.5em'}}
                        to="/edu/chatdocument/new"
                        variant={'contained'}>Chat with a document</Button>
                        <Button component={Link} 
                          style={{margin: '0.5em'}}
                          to="/edu/chatvideo/new"
                          variant={'contained'}>Chat with a YouTube video</Button>
              </Grid>
              {/**My documents */}
              <Grid item xs={12} style={{marginTop: '3em'}}>
                <Typography variant="h4">
                  My documents
                </Typography>
              </Grid>
              <Grid item xs={12} style={{minHeight: '4em'}}>
                {/* Display loading indicator for myDocuments and then display my documents once loaded */}
                {myDocumentsLoading && <CircularProgress />}
                {myDocuments.map((document,index) => {
                  if(document.collectionId && document.collectionId!==document.id){
                    return (
                      <DocumentCard key={index} document={document} pageName='collection-chat' id={document.collectionId} />
                    )
                  }else{
                    return (
                      <DocumentCard key={index} document={document} pageName='chatdocument' id={document.id} />
                    )
                  }
                  
                })}
              </Grid>

              {/**My Videos */}
              <Grid item xs={12} style={{marginTop: '3em'}}>
                <Typography variant="h4">
                  My videos
                </Typography>
              </Grid>
              <Grid item xs={12} style={{minHeight: '4em'}}>
                {/* Display loading indicator for myDocuments and then display my documents once loaded */}
                {myVideosLoading && <CircularProgress />}
                {myVideos.map((video,index) => {
                  if(video.collectionId && video.collectionId!==video.id){
                    return (
                      <VideoCard key={index} video={video} pageName='collection-chat' id={video.collectionId} />
                    )
                  }else{
                    return (
                      <VideoCard key={index} video={video} pageName='chatvideo' id={video.id} />
                    )
                  }
                })}
              </Grid>    
            </Grid>
    </Container>
    
  );
}

function DocumentCard({document,pageName,id }: {document: ChatDocument,pageName:string,id:string}) {
  return (
    <Button component={Link} to={`/edu/${pageName}/${id}`}>
      <Paper elevation={3} style={{
        maxWidth: '600px',
        padding: '10px',
        backgroundColor:'#171F30',
        color:'white'
      }}>
        <Typography variant="h6" component="h1">
          {document.title}
        </Typography>
        <Typography variant="caption">{document.createdAt ? moment(document.createdAt).format('lll') : ''}</Typography>
      </Paper>
    </Button>
  )
}
function VideoCard({video,pageName,id}: {video: ChatYoutubeVideo,pageName:string,id:string}) {
  return (
    <Button component={Link} to={`/edu/${pageName}/${id}`}>
      <Paper elevation={3} style={{
        maxWidth: '600px',
        padding: '10px',
        backgroundColor:'#171F30',
        color:'white'
      }}>
        <Typography variant="h6" component="h1">
          {video.title}
        </Typography>
        <Typography variant="caption">{video.createdAt ? moment(video.createdAt).format('lll') : ''}</Typography>
      </Paper>
    </Button>
  )
}