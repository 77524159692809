import ApiRequest from "../apiRequest";
import { CourseSection } from "../../types/Course";

interface getSectionOut {
  error?: string;
  section?: CourseSection;
}
export default async function getSection(courseId: string, moduleId: string, sectionId: string,getPublic?: boolean): Promise<getSectionOut> {

  try {
    let result = await ApiRequest(
      `/api/${getPublic ? 'public-course' : 'course'}/${courseId}/module/${moduleId}/section/${sectionId}`,
      'GET'
    )

  
    return {
      // @ts-ignore
      error: result.error,
      // @ts-ignore
      section: result.section
    }
  } catch (err) {
    console.error("could not fetch section details", err);
    return {
      error: 'Unable to get section'
    }
  }
}