import './GettingStarted.css';
import { useState, useEffect, useMemo } from 'react'; 
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Form from '@mui/material/Container';
import Twitter from '@mui/icons-material/Twitter';
import Mail from '@mui/icons-material/Email';
import { Accessibility, Block, Check } from '@mui/icons-material';
import devShapes from '../../assets/devShapesThree.png';
import Typography from '@mui/material/Typography';
import LoggedOutNavBar from '../../components/LoggedOutNavBar';
import EmailInquiry from '../../components/EmailInquiry';
import { Button, Card, CardContent, Grid, Paper } from '@mui/material';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Metadata from '../../components/Metadata';
import ReactPlayer from 'react-player';


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


function GettingStarted() {
  const { height, width } = useWindowDimensions();
  const headerSize = useMemo(() => {
    if (width > 999) {
      return "h1";
    } else if (width > 750) {
      return "h2";
    } else {
      return "h3"
    };
  }, [width]);
  return (
    <MainContainer className="App">
      <Metadata
        title="Getting started - Learnly"
        description={`
          Join the learnly program to accelarate your journey to become a software engineer.
        `}
      />
      <CssBaseline />

      {/* Navigation */}
      <Container>
        <LoggedOutNavBar/>
      </Container>
      <div style={{
        backgroundImage: "url(" + devShapes + ")",
        backgroundPosition: 'right',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        minHeight: '80vh',
      }}>
        <div style={{
          textAlign: 'center',
          fontSize: '20px'}}>
            
            <h1 className="GetHeading">Lets Get <span style={{color:'#B7DBE7'}}>Started!</span></h1>
            <div className="GetHeading mainContent"
              style={{
                backgroundColor: 'rgba(250,250,250,0.9)',
              }}
            >
              <h3>How it works</h3>

              {/* <ReactPlayer style={{
                  border: 'black 1px solid',
                  backgroundColor: 'black',
                  margin: 'auto auto',
                  maxWidth: '90vw',
                  width: '100%'
                }}
                config={{ file: { 
                  attributes: {
                    controlsList: 'nodownload'
                  }
                }}}
                light controls url={"https://youtu.be/WmmkOwPq-hA"}/> */}
              <p style={{
                textAlign: 'start'
              }}>
                This program is currently in <strong>PILOT</strong> phase. That means, the content and platform are still being developed. At this time, we are accepting students that want to participate in the pilot program where the progress of the student is closely <b>monitored</b> to ensure success of the program.
                {/* <br/> */}
                {/* <br/> */}
                {/* Videos and exercises are uploaded on a weekly basis, requiring users to typically spend 10-15 hours/week, until the end of the entire curriculum (which should take 9-12 months with 10-15 hours/week). */}
                <br/><br/>
                As a student in the <b>pilot</b> phase, you are expected to:
                
                <ul className="student-expectations">
                  <li>reach out when stuck on exercises or content</li>
                  <li>give feedback on the content</li>
                  <li>report any issues with the website</li>
                  <li>partipate in the community (discord), all updates are posted there</li>
                  <li>finish content in a timely manner</li>
                </ul>
                
                If you proceed to sign up below, it will give you access to the portal to watch {'->'} learn {'->'} and develop your software engineering skills by practicing.
              </p>
              <h3>Benefits</h3>
              <div>
                {/* <img 
                    style={{
                      width: '600px',
                      maxWidth: '90vw'
                    }} 
                    alt='Why you should learn to code from Learnly'
                    src="/why-join.png"
                /> */}
                <PricingCard>
                  <Grid container>
                    <Grid
                      className="pricingDetails"
                      item xs={12}>
                      <ul>
                        <li><Check/><b>Unlimited access</b> to all courses, videos, and exercises</li>
                        <li><Check/><b>Learn</b> software engineering by becoming a <b>problem solver</b></li>
                        <li><Check/><b>Real world</b> insights and usecases</li>
                        <li><Check/> <b>Human</b> <Accessibility/> monitored progress</li>
                        <li><Check/><b>Graded</b> exercises</li>
                        {/* <li><Check/><b>Join</b> the Learnly community</li> */}
                      </ul>
                    </Grid>
                    <Grid item xs={12} 
                      style={{display: 'flex', justifyContent: 'center', marginBottom: '1em'}}>
                      <Button
                        className="joinNow"
                        variant="contained"
                        component={Link}
                        to="/edu/home?signup=true">
                        Join Now
                      </Button>
                    </Grid>
                  </Grid>
                  
                </PricingCard>
              </div>
              {/* <h3>PRICING</h3>
              <div>
                <p>One price for all</p>

                <PricingCard>
                  <Grid container>
                    <Grid item xs={12} sm={5}
                      className = "priceDiv"
                    >
                      <p
                        style={{
                          fontSize: 50,
                          marginBottom: 0,
                          marginTop: 0
                        }}
                      >
                        $11 <span style={{fontSize: 20}}>USD</span>
                        <br/>
                      </p>
                      <span>per month</span>
                      <br/>
                      <Button
                        className="joinNow"
                        variant="contained"
                        component={Link}
                        to="/edu/home?signup=true">
                        Join Now
                      </Button>
                    </Grid>
                    <Grid
                      className="pricingDetails"
                    
                      item xs={12} sm={7}>
                      <ul>
                        <li><Check/> <b>Human</b> <Accessibility/> monitored progress</li>
                        <li><Check/><b>Learn</b> to code by becoming a <b>problem solver</b></li>
                        <li><Check/><b>Real world</b> insights and usecases</li>
                        <li><Check/><b>Graded</b> exercises</li>
                        <li><Check/><b>Unlimited access</b> to all courses, videos, and exercises</li>
                        <li><Check/><b>Join</b> the Learnly community</li>
                        <li><Check/>7 days <b>free</b> trial</li>
                      </ul>
                    </Grid>
                  </Grid>
                </PricingCard>
              </div> */}
              {/* <h3>Get started</h3>
              <Paper style={{
                paddingTop: '1em',
                paddingBottom: '1em'
              }} className="contact-us">
                <EmailInquiry subjectPrefix="Getting started: " bodyPlaceholder='Why is this bootcamp right for you?'/>
              </Paper> */}
              <h1 style={{textAlign: 'center', color: '#98D4B5'}}>Got more questions? </h1>
              <p>
              Visit <Link style={{color: '#558a9b'}} to="/faq">FAQ's</Link>
              </p>
            </div>
        </div>
      </div>
     
    </MainContainer>
  );
}
const MainContainer = styled(Container)`
  .student-expectations li {
    list-style-type: disc;
    margin-left: 2em;
  }

`
const PricingCard = styled(Paper)`
  max-width: 1000px;
  margin: 0 auto;
  .priceDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #558a9b;
  }
  .pricingDetails {
    text-align: left;
  }
  li svg:first-child {
    color: green;
    margin-right: 10px;
  }
  .pricingDetails li {
    list-style-type: none;
  }
  @media screen and (max-width: 599px) {
    .pricingDetails {
      padding: 1em;
    }
  }


`

export default GettingStarted;