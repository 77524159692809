// probably should reverse the name with current ChatBox

// shows the chat as block that will be in the page
import { Error, Send } from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Button, CircularProgress, FormHelperText, TextField, createTheme, Container, Typography, Menu, MenuItem, IconButton, Tooltip, ClickAwayListener } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from "react-router";
import { MessageBox, Input } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import ChatContext, { Message } from '../context/ChatContext';
import { ThemeProvider } from "styled-components";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import GreenAddIcon from "../assets/add_icon.svg";
import ChatDocument from '../types/ChatDocument';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import getCollection from '../api/chatCollection/getCollection';
import circuit from '../assets/circuit.png'

interface NewChatWindowProps {
    myDocuments: ChatDocument[];
    openFileUpload: () => void;
    collectionId:string;
  }


const customTheme = createTheme({
    
    palette: {
      primary: {
        main: '#101828',
        light:'#65748D',
        dark:'#040A14',
        contrastText: "#fff",
      },
      secondary: {
        main:  '#5D4682',
        contrastText: "#fff",
      },
      
    },
    
  });

  
const useStyles=makeStyles(()=>({
    outerContainer:{
      display:'flex',
      flexDirection: 'column',
      backgroundColor:'#171F30',  
      marginTop:'5px',
      height:'85vh',
      width:'90vw',
      padding:'0',
      maxWidth:'2000px',
      borderRadius:'30px',
      border:'1px solid #5D4682',
      '@media (max-width: 640px)': {
        width:'100%',
        position:'relative',
        marginTop:'5px',
        height:'inherit',
        borderRadius:'0',
        border: 'none',
        backgroundColor:'#101828',
      },
    },
    titleBar:()=>({
        height:'57px',
        backgroundColor:'#5D4682',
        borderRadius:'30px 30px 0 0',
        color:'#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize:'20px',
        '@media (max-width: 640px)': {
          position:'fixed',
          top:'50px',
          left:'0px',
          width:'100%',
          zIndex:'99',
          borderRadius:'0',
          fontSize:'15px',
          height:'40px',
          backgroundColor:'rgb(93, 70, 130, 0.9)',
          backdropFilter: 'saturate(185%) blur(5px)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat', 
          backgroundPosition: 'center center', 
          backgroundBlendMode: 'overlay',
        },
    }),
    titleBarText:{
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      margin:'0 20px',
    },
    innerContainer:{
        display:'flex',
        flexGrow:'1',
        height:'100%',
        overflow:'hidden',
    },
    sideBar:{
        minWidth:'170px',
        backgroundColor:'#101828',
        transition: 'width 0.3s',
        flex: '0 0 auto',
        borderBottomLeftRadius:'30px',
        color:'#ffffff',
        width:'23%',
        display:'flex',
        flexDirection: 'column',
        overflowY:'auto',
    },
    courseButton:{
      color:'white',
      backgroundColor:'#5D4682',
      boxShadow: '0 0 5px 0px #000000',
      borderRadius:'8px',
      '@media (max-width:940px)':{
        fontSize:'70%',
      },
      '&:hover': {
        backgroundColor:'#5D4682',
        },

    },
    infoIcon:{
      '&:hover': {
        cursor: 'help',
        },
    },
    courseButtonSmallScreen:{
      color:'white',
      backgroundColor:'#101828',
      width: '165px',
      fontSize: '70%',
      padding:'4px',
      border: '1px solid #0f1624',
      boxShadow: '0 0 5px 1px #ffffff',
      marginBottom:'5px',
      '&:hover': {
        backgroundColor: '#070b12', 
        },
    },
    filesHeading:{
        fontSize:'30px',
        margin:'0',
        height:'fit-content',
        fontWeight:'400',
        display:'flex',
      '@media (max-width:960px)':{
        fontSize: '25px',
      },
      '@media (max-width:850px)':{
        fontSize: '23px',
      }

    },
    filesHeadingText:{
      margin:'0'
    },
    file:{
        margin:'5px 0 10px 0',
        fontSize:'14px',
        fontWeight:'100',
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    addFile:{
        marginLeft:'15px',
        '&:hover': {
            cursor: 'pointer',
          },
    },
    addFileImageDiv:{
      display:'flex',
      alignItems:'center',
    },
    addIcon:{
      width:'18px',
    },
    chatDiv:{
        display:'flex',
        flexDirection:'column',
        flexGrow:'1',
        marginTop:'1px'
    },
    chatUpperDiv:{
        display:'flex',
        flexDirection:'column',
        flex:10,
        overflowX: 'hidden', 
        overflowY: 'scroll',
        paddingTop: '20px',
        '@media (max-width: 640px)': {
            height:'100%',
            position:'fixed',   
            marginTop: '20px',
            overflowY: 'hidden',
        },
        '&::-webkit-scrollbar': {
            width: '10px', // Set the width of the scrollbar
          },
        '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#283247', // Color of the scrollbar thumb
        borderRadius: '10px', // Rounded corners of the thumb
        },
        '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#536fac', // Color of the thumb on hover
        },
        '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent', // Color of the scrollbar track
        },
    },
    messageBox:{
        '& .rce-mbox-left-notch': {
            fill: '#172543',
          },
        '& .rce-mbox-right-notch': {
            fill: '#21345f', 
          },
    },
    errorDiv:{
        color: 'red', 
        display: 'flex', 
        justifyContent: 'flex-end', 
        alignContent: 'center', 
        fontSize: '11px', 
        paddingRight: '1em',
        marginBottom:'12px'
    },
    chatLowerDiv:{
        padding:'1px 10px',
        marginBottom:'4px',
        textAlign:'center',
        '@media (max-width: 640px)': {
          padding:'0',
          position:'fixed',
          bottom:'0',
          left:'0',
          width:'100%',
          zIndex:'99',
          marginBottom:'0',
        },
    },
    mobileDesign:{
      '@media (max-width: 640px)': {
        padding:'5px 10px 10px',
        backgroundColor:'rgb(74 64 91 / 50%);',
        backdropFilter: 'saturate(185%) blur(5px)',
      },
    },
    textfieldDiv:{
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#101828',
      borderRadius: '30px',
    },
    schoolIconDiv:{
      display: 'flex',
      alignItems: 'center',
      padding: '0 5px 0 11px',
    },
    iconInTextArea:{
      fontSize:'25px',
      color:'#5D4682',
    },

    textArea:{
        width:'100%',
        backgroundColor:'#fff',
        borderRadius:'30px',
        '& .MuiOutlinedInput-root': {
            border: 'none', 
            height: '40px',
            color:'black',
            borderRadius:'30px',
            '&.Mui-focused fieldset': {
                border:'1px solid #5D4682'
              },
        },
        height: '40px',
    },
    sendButton:{
        position: 'relative',
        left: '20px',
    },
  
  }))

const TestNewChatWindow = ({ myDocuments,openFileUpload,collectionId }: NewChatWindowProps) => {

  const chatContext = useContext(ChatContext);
  const {height, width} = useWindowDimensions();
  const [text, setText] = useState('');
  const [textError, setTextError] = useState('');
  const theme=customTheme
  const history = useHistory();
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [collectionName,setCollectionName]= useState('')
  const [collectionImage,setCollectionImage]= useState('')
  const classes=useStyles()

  const titleBarStyles=makeStyles(()=>({
    titlebarImage:{
      '@media (max-width: 640px)': {
        backgroundImage:`url(${collectionImage})`
      }
    }
  }))

  //as soon as the page loads on the phone, scroll to bottom
//   useEffect(()=>{
//     if(width<641){
//         window.scrollTo(0, document.body.scrollHeight);
//     }
    
    
//   },[])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    
  };

  const sendToCourseCreation=()=>{
    setAnchorEl(null);
    history.push(`/edu/course/new?documentId=${collectionId}`)
  }

  const validateText = (text: string) => {
    // max limit of 250 characters
    if (text.length > 499) {
      setTextError('Text must be less than 500 characters');
      return false;
    }

    // verify not blank
    if (text.trim().length === 0) {
      setTextError('Text cannot be blank');
      return false;
    }

    // make sure there is no forbidden characters
    if (!/^[a-zA-Z0-9:'.,? \n\t]*$/.test(text)) {
      setTextError('Text can only contain letters, numbers, and the following characters: \'.,?');
      return false;
    }


    setTextError('');
    return true;
  };

  // 

  const handleTextChange = (text: string) => {
    setText(text);
    validateText(text);
  };

  const onSend = () => {
    let message = text?.trim() || '';
    const validated=validateText(message)
    if (message && validated) {
        setText('');
        chatContext.sendMessage(message);
    }
  };

  const handleInfoIconClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    // Prevent the click event from propagating to the button
    handleTooltipOpen()
    event.stopPropagation();
    
    // Action for the InfoOutlinedIcon
    console.log('Info icon clicked');
  };

  const getMessagePosition = useMemo(() => (message: Message) => {
    if (message.user.id === chatContext.currentUserId) {
      return 'right';
    } else {
      return 'left';
    }
  }, [chatContext.currentUserId]);

  useEffect((()=>{
    async function getCollectionNameAndImage(){
      try{
        let collection=await getCollection(collectionId);
        setCollectionName(collection?.name||'')
        setCollectionImage(collection?.imageURL||'')
      }catch(e){
        console.error(e)
      }
    };
    getCollectionNameAndImage()
  }),[])


  // end of messages ref
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    let chatWindow = document.getElementById('chat-window');
    if (!chatWindow) return;
    let xH = chatWindow.scrollHeight;
    chatWindow.scrollTo(0, xH);
    // if(width<641){
    //   window.scrollTo(0, xH);
    // }
    // else{
    //   chatWindow.scrollTo(0, xH);
    // }
  };
  // scroll to bottom on new message
  useEffect(() => {
    scrollToBottom();
  }, [chatContext.messages]);

  const openFileUploadWindow=()=>{
    setAnchorEl(null);
    openFileUpload();
  }


  const customTextField=()=>{
    return(
      <>
      <TextField className={classes.textArea}
          value={text}
          onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              onSend();
              }
          }}
          placeholder='Type a message...'
          onChange={(e) => handleTextChange(e.target.value)}
          InputProps={{endAdornment:
            <IconButton disabled={!!textError} className={classes.sendButton} onClick={onSend} > 
              <Send style={{color:!!textError?'grey':''}} className={classes.iconInTextArea}/>
            </IconButton>}}
      />
      
      </>
    )
  }


  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };



  return (
    <ThemeProvider theme={theme}>
        <Container className={classes.outerContainer} >
            <div className={[classes.titleBar, titleBarStyles().titlebarImage].join(' ')}>
              <span className={classes.titleBarText}>
                <b>Guru Chat</b>: {collectionName}
              </span>
            </div>
            <div className={classes.innerContainer}>
                {/* only display sideBar if screen width is >640px */}
                {width>640 &&
                  <div className={classes.sideBar}>
                    <div style={{padding: '10px 5px 20px 20px',flexGrow:'1'}}>
                      <div className={classes.filesHeading}>
                      <p className={classes.filesHeadingText}>Feed Guru</p>
                        <div className={classes.addFileImageDiv}>
                          <Tooltip title="Feed your Guru more data">
                        <img src={GreenAddIcon} alt="add file" onClick={e=>openFileUploadWindow()} className={classes.addFile}/>
                          </Tooltip>
                        </div>
                      </div>
                      {myDocuments.map((doc,index)=>{
                        if (doc.contentType=== 'application/pdf')
                          return(<p className={classes.file} key={index}> {doc.filename}</p>)
                        else {
                          return(<p className={classes.file} key={index}> {doc.title}</p>)
                        }
                          
                      })}
                    </div>
                    <div style={{position:'sticky',bottom:'10px',textAlign:'center'}}>
                        <Button onClick={sendToCourseCreation} className={classes.courseButton} 
                        >
                          <p style={{margin:0,position:'relative',top:'1px'}}>Create a course</p>
                          <div style={{marginLeft:8,display:'flex'}}>
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                              <Tooltip PopperProps={{disablePortal: true,}} onClose={handleTooltipClose} open={tooltipOpen} disableFocusListener 
                                title="Create a course from this Guru's knowledge">
                                <InfoOutlinedIcon className={classes.infoIcon} style={{color:'#a591ad'}} onClick={handleInfoIconClick}/>
                              </Tooltip>
                            </ClickAwayListener>
                          </div>
                        </Button>
                    </div>
                  </div>
                  
                }   
                <div className={classes.chatDiv}>
                    <div  className={classes.chatUpperDiv} style={{marginBottom:'4px'}}>
                      <div style={{paddingBottom:width<641?'182px':'7px',flexGrow:'1', overflowY:width<641?'auto':'initial'}}  id="chat-window">
                        {chatContext.messages.map((message,index) => (
                            <>
                                <MessageBox
                                key={message.id}
                                // @ts-ignore
                                position={getMessagePosition(message)}
                                type="text"
                                text={message.text}
                                className={classes.messageBox}
                                // light blue shade for background where user.id === "bot"
                                styles={{
                                    backgroundColor: message.user.id === 'bot' ? '#172543' : '#21345f',
                                    color:message.sendStatus === 'ERROR'?'red':'white',
                                    
                                }}
                                />
                                {message.sendStatus === 'ERROR' && message.user.id !== "bot" && (
                                <div className={classes.errorDiv} key={index}>
                                    <Error fontSize={'small'}/> Error sending message
                                </div>
                                )}
                            </>
                        ))}
                        <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center',}}>
                          {(chatContext.status === 'LOADING'
                          // or last message sendStatus is LOADING
                            || (chatContext.messages.length > 0 && chatContext.messages[chatContext.messages.length - 1].sendStatus === 'LOADING'))
                            && <CircularProgress size="20px" />}
                          <div style={{ float:"left", clear: "both" }}
                            ref={messagesEndRef}>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={classes.chatLowerDiv}>
                        {width<641 && <>
                          <Button onClick={sendToCourseCreation} className={classes.courseButtonSmallScreen}
                            endIcon={
                              <div style={{position:'relative', left:'6px',display:'flex'}}>
                                <ClickAwayListener onClickAway={handleTooltipClose}>
                                  <Tooltip PopperProps={{disablePortal: true,}} onClose={handleTooltipClose} open={tooltipOpen} disableFocusListener 
                                    title="Create a course from this Guru's knowledge">
                                    <InfoOutlinedIcon className={classes.infoIcon} style={{color:'#7a7a7a'}} onClick={handleInfoIconClick}/>
                                  </Tooltip>
                                </ClickAwayListener>
                              </div>
                            }
                            >
                              Create a course
                          </Button>
                          <div className={classes.mobileDesign}>
                            <div className={classes.textfieldDiv}>
                              <div className={classes.schoolIconDiv}>
                                <IconButton
                                  style={{padding: '0'}}
                                  aria-controls={open ? 'basic-menu' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={open ? 'true' : undefined}
                                  onClick={handleClick}
                                  >
                                  <AttachFileIcon className={classes.iconInTextArea}/>
                                </IconButton>
                              </div>
                              {customTextField()}
                              
                            </div>
                            {!!textError && <FormHelperText style={{marginLeft:'55px', color:'red', fontWeight: 'bold', textShadow: '#400000 0px 0px 5px'}}>{textError}</FormHelperText>}
                          </div>
                        </>}
                          
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                              <MenuItem onClick={openFileUploadWindow}>Add a file</MenuItem>
                            </Menu>
                        {width>640 && 
                        <div>
                          {customTextField()}
                          {!!textError && <FormHelperText style={{color:'red'}}>{textError}</FormHelperText>}
                        </div>
                        }
                    </div>
                </div>
            </div>
        </Container>
        
    </ThemeProvider>
  );
};

export default TestNewChatWindow;