import { useContext } from 'react';
import { Button, CircularProgress, Container, Fab, Grid, Input, Paper, Theme, Typography,useMediaQuery,useTheme } from "@mui/material";
import NewNavBar from "../components/NewNavBar";
import { useHistory } from "react-router";
import { makeStyles } from '@mui/styles';
import NewGetStartedToSignIn from "../components/NewGetStartedToSignIn";
import RoboBig from "../assets/robo-big.png"
import RoboSmall from "../assets/robo-small.svg"
import { useEffect, useState } from "react";
import OurProcessPage from "../components/OurProcessPage";
import Pricing from "./Pricing";
import SubscriptionContext from '../context/SubscriptionContext';
import { Check } from '@mui/icons-material';
import useUser from '../hooks/useUser';
import GettingStartedFab from '../components/GettingStartedFab';

const useStyles = makeStyles((theme: Theme) => ({
    extraSpaceBehindScreen:{
        background:'#101828'
    },
    root:{
        
        background:"#101828",
        paddingRight:'0px',
        '@media (max-width: 900px)': {
            paddingLeft:'0'
          },
          maxWidth:'1920px',
        
    },
    upperDiv:{
        '@media (max-width: 900px)': {
            marginBottom:'30px',
          },
    },
    textContainer:{
        marginTop:'30px',
        paddingLeft:'50px',
        height:'100vh',
        color:'white',
        '@media (max-width: 900px)': {
            paddingLeft:'0',
            height:'auto',
          },
    },
    disabledButton:{
        marginTop:'100px',
        padding:'5px',
        border:'1px solid white',
        width:'200px',
        borderRadius:'20px',
        textAlign:'center',
        letterSpacing:'0.1rem',
        '@media (max-width: 900px)': {
            margin:'auto',
            marginBottom:'30px',
          },
    },
    mainText:{
        marginTop:'30px',
        fontSize:'4vw',
        fontWeight:'550',
        background:"#101828",
        '@media (max-width: 900px)': {
            textAlign:'center',
            margin:'auto',
            fontSize:'200%',
            width:'350px'
          },
    },
    purpleText:{
        color:theme.palette.info.main    
    },
    extraText:{
        marginTop:'30px',
        fontStyle:'italic',
        fontSize:'1.2vw',
        '@media (max-width: 900px)': {
            fontSize:'0.75rem',
            textAlign:'center',
          },
    },
    authorText:{
        marginTop:'30px',
        fontSize:'1vw',
        '@media (max-width: 900px)': {
            fontSize:'0.70rem',
            textAlign:'center',
          },
    },
    signUpDiv:{
        marginTop:'30px',
        display:'flex',
        flexDirection:'row',
        '@media (max-width: 900px)': {
            justifyContent:'center',
          },
    },
    bottomButtons:{
        marginTop:'80px',
        width:'100%',
        '@media (max-width: 900px)': {
            marginTop:'30px',
            textAlign:'center',
          },
    },
    expertButton:{
        background:'#101828',
        border:'1px white solid',
        color:'white',
        '&:hover': {
            backgroundColor: '#0b111c', 
            },
        marginLeft:'25px',
        '@media (max-width: 538px)': {
            marginTop:'30px',
            right:'12px'
        },
    },

    imageContainer:{
        display: 'flex',
        justifyContent: 'flex-end',
        '@media (max-width: 900px)': {
            justifyContent: 'center',
          },
    }

}))

function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      function handleResize() {
        setWindowWidth(window.innerWidth);
      }
  
      window.addEventListener('resize', handleResize);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return windowWidth;
  }
  


export default function HomePage() {
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const windowWidth = useWindowWidth();
    const picture=windowWidth<900? RoboSmall:RoboBig;
    const subscriptionContext = useContext(SubscriptionContext);

    const [signingUp, setSigningUp] = useState(false);
    const [email, setEmail] = useState('');
    const [signUpSuccess, setSignUpSuccess] = useState(false);
    const { user } = useUser();

    const onSubmit = async () => {
        setSigningUp(true);
        setSignUpSuccess(false);
        const url = `https://gmail.us1.list-manage.com/subscribe/post-json?u=a29cd7ac0b0e65bbe77c6eaab&amp;id=c7e4cd460d&EMAIL=${encodeURIComponent(email)}&c=?`;
        // make post request
        try {
            let response = await fetch(url, {
                method: 'POST',
                mode: 'no-cors',
            });
            console.log('response', response);
            setSignUpSuccess(true);
        } catch (error) {
            console.log("could not sign up", error);
        } finally {
            setSigningUp(false);
        }
    };
    
    return (
        
        <div className={classes.extraSpaceBehindScreen}>
        <Container className={classes.root} >
        
            <NewNavBar/>
        
        <Grid container spacing={2} className={classes.upperDiv}>
         
            <Grid item xs={12} md={6} style={{ order: windowWidth < 900 ? 1 : 2 }}>
                <div className={classes.imageContainer} >
                <img  src={picture}/>
                </div>
            </Grid>
        
        <Grid item xs={12} md={6} style={{ order: windowWidth < 900 ? 2 : 1 }} >
        <div className={classes.textContainer} >
            {/* {(picture==RoboBig) && <Typography className={classes.disabledButton}>
                Learn to code
            </Typography>} */}
            <Typography className={classes.mainText}>
                Learn to code <span className={classes.purpleText}>for free</span> 
            </Typography>
            <Typography className={classes.extraText}>100+hour JS course, focused strictly on building fundamentals of problem solving and computer science</Typography>
            <Typography className={classes.authorText}>- <a href='https://www.linkedin.com/in/zaidqureshi/' target='_blank'>Zaid</a>, Senior engineer at Amazon, building responsible AI</Typography>
            {/* <div className={classes.signUpDiv}>

                <Input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} /> 
                <Button variant="contained" color="primary" onClick={onSubmit} disabled={signingUp}>
                    Subscribe
                    {signingUp && <CircularProgress style={{marginLeft: '5px'}} size={12} color='secondary' /> }
                    {signUpSuccess && <Check style={{marginLeft: '5px'}} color='secondary' /> }
                </Button>
            </div> */}
            {/* {(picture==RoboBig) && <Typography className={classes.disabledButton}>
                AI Newsletter
            </Typography>}
            <Typography className={classes.mainText}>
                Get the most important <span className={classes.purpleText}>AI updates</span> 
            </Typography>
            <Typography className={classes.extraText}>The only AI newsletter you need, no bullshit emails.</Typography>
            <Typography className={classes.authorText}>- <a href='https://www.linkedin.com/in/zaidqureshi/' target='_blank'>Zaid</a>, Senior engineer at Amazon, building responsible AI</Typography>
            <div className={classes.signUpDiv}>

                <Input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} /> 
                <Button variant="contained" color="primary" onClick={onSubmit} disabled={signingUp}>
                    Subscribe
                    {signingUp && <CircularProgress style={{marginLeft: '5px'}} size={12} color='secondary' /> }
                    {signUpSuccess && <Check style={{marginLeft: '5px'}} color='secondary' /> }
                </Button>
            </div> */}
            
            <div className={classes.bottomButtons}>
                Get <span className={classes.purpleText}>free</span> access to the best
                <Fab variant="extended" 
                    className={classes.expertButton}
                    onClick={() => history.push((user ? "/edu" : "") + "/course/intro-to-comp-sci-1-js")}
                >
                    intro to computer science course
                </Fab>
            </div>
            <GettingStartedFab/>
        </div>
        </Grid>
        </Grid>
        </Container>
        </div>
    );
}