import React, { useEffect } from "react";
import getCoupon from "../api/subscription/getCoupon";
import Coupon from "../types/coupon";


interface CouponState {
  couponCode?: string;
  coupon?: Coupon;
  valid?: boolean;
  status: string;
  error?: string; 
}
export default function useCoupon(coupon?: string) {
  
  const [currentCouponState, setCurrentCouponState] = 
    React.useState<CouponState>({
      couponCode: coupon,
      status: 'INITIAL',
    });

  const applyCoupon = async (coupon: string) => {

      const resp = await getCoupon(coupon);
      if (resp.error) {
        setCurrentCouponState({
          couponCode: coupon,
          valid: false,
          status: 'INVALID_COUPON',
          error: resp.error
        });
      } else {
        setCurrentCouponState({
          couponCode: coupon,
          coupon: resp.coupon,
          valid: true,
          status: 'SUCCESS',
          error: undefined
        });
      }
  }

  useEffect(() => {
    if (coupon) {
      applyCoupon(coupon);
    }
  }, []);

  return {
    applyCoupon,
    couponState: currentCouponState
  }
}