import React from "react";
import { SubscriptionState } from "../hooks/useSubscription";

interface ISubscripbtionContext extends SubscriptionState {
  fetchSubscription: () => void;
  setIncludeInvoice: (includeInvoice: boolean) => void;
}

const SubscriptionContext = React.createContext<ISubscripbtionContext>({
  isSubscribed: false,
  includeInvoice: false,
  status: 'INITIAL',
  fetchSubscription: () => {},
  setIncludeInvoice: (includeInvoice: boolean) => {},
});

export default SubscriptionContext;