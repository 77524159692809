import { useEffect, useState } from "react";
import ChatDocument from "../types/ChatDocument";
import createDocumentRequest, { CreateDocumentResponse } from "../api/chatDocument/createDocumentRequest";
import getDocument from "../api/chatDocument/getDocument";

interface ChatDocumentStore {
  documentId: string;
  status: 'INITIAL' | 'LOADING' | 'SUCCESS' | 'ERROR';
  fileUploadStatus: 'INITIAL' | 'PENDING_UPLOAD' | 'UPLOADED' | 'ERROR';
  document?: ChatDocument;
  fetchDocument: () => Promise<void>;
  isVectorized: (docId:string) => Promise<boolean | undefined>;
  setNullDocument:()=>void;
  uploadFile: (file: File, presignedUrl: CreateDocumentResponse['presignedUrl']) => Promise<void>;
  createDocument: (file: File,isCollection: boolean,collectionId:string|undefined) => Promise<CreateDocumentResponse | undefined>;
}

export default function useChatDocument(documentId: string): ChatDocumentStore {
  const [document, setDocument] = useState<ChatDocument|undefined>();
  const [status, setStatus] = useState<'INITIAL' | 'LOADING' | 'SUCCESS' | 'ERROR'>('INITIAL');
  const [fileUploadStatus, setFileUploadStatus] = useState<'INITIAL' | 'PENDING_UPLOAD' | 'UPLOADED' | 'ERROR'>('INITIAL');

  const fetchDocument = async () => {
    console.log("fetching document", documentId);
    if (documentId === 'new') {
      setStatus('SUCCESS');
      return;
    }
    setStatus('LOADING');
    try {
      const document = await getDocument(documentId);
      setDocument(document);
      setStatus('SUCCESS');
    } catch (err) {
      setStatus('ERROR');
    }
  }

  const isVectorized = async (docId:string) => {
    console.log("is Vectorized?"+ docId);
    
    try {
      const document = await getDocument(docId);
      if (document?.vectorizationStatus=="VECTORIZED"){return true}
      else {return false}
    } catch (err) {
      setStatus('ERROR');
    }
  }

  const setNullDocument=()=>{
    setDocument(undefined)
  }
 
  /**
   * Gets S3 presigned url for upload
   * Uploads file to S3
   * @param file 
   * @param documentId we need to pass it in probably because it may not have reset yet
   */
  const uploadFile = async (file: File, presignedUrl: CreateDocumentResponse['presignedUrl']) => {
    if (!presignedUrl) {
      console.log("No presigned url")
      return;
    }
    setFileUploadStatus('PENDING_UPLOAD');
    if (documentId === 'd591a487-bf33-4c39-81a5-6b32e6a2182e') {
      // set timeout of 10 seconds to simulate upload
      await new Promise(resolve => setTimeout(resolve, 10000));
      
      setFileUploadStatus('UPLOADED');
      return;
    }

    const fields = presignedUrl?.fields;


    try {
      const form = new FormData();
      const key = fields.key;
      form.append('key', key);
      delete fields.key;

      const headers: any = {}
      Object.keys(fields).forEach(key => {
        form.append(key, fields[key]);
        // headers[key] = fields[key]
      });
      form.append('file', file);
      await fetch(presignedUrl.url, {
        method: 'POST',
        body: form,
        headers: {
          ...headers
        }
      })
      await fetchDocument();
      setFileUploadStatus('UPLOADED');
    } catch (err) {
      setFileUploadStatus('ERROR');
    }
  }

  const createDocument = async (file: File, isCollection: boolean,collectionId:string|undefined) => {
    setStatus('LOADING');
     // calls api to create document
     try {
      const document = await createDocumentRequest(file,isCollection,collectionId);
      return document;
      // return {
      //   id: 'd591a487-bf33-4c39-81a5-6b32e6a2182e',
      //   filename: 'romeo_and_juliet.txt',
      //   title: 'Thinking...',
      //   uploadStatus: 'PENDING_UPLOAD',
      //   vectorizationStatus: 'PENDING_VECTORIZE'
      // } as ChatDocument;
    } catch (err) {
      console.error("could not create document", err);
      setStatus('ERROR');
      throw err;
    }
  };

  useEffect(() => {
    fetchDocument();
  }, [documentId])

  return {
    documentId,
    status,
    fileUploadStatus,
    document,
    setNullDocument,
    fetchDocument,
    isVectorized,
    uploadFile,
    createDocument
  }
}