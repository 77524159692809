import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(
  {
    buttonLabel,
    title,
    confirmationText,
    dangerousAction,
    buttonProps,
    onNo,
    onYes
  }: {
    
    buttonLabel: string,
    title: string,
    confirmationText: string,
    onYes?: () => void,
    onNo?: () => void,
    dangerousAction?: boolean,
    buttonProps?: any
  }
) {
  const [open, setOpen] = React.useState(false);
  buttonProps = buttonProps || {};
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleNo = () => {
    setOpen(false);
    onNo && onNo();
  };
  const handleYes = () => {
    setOpen(false);
    onYes && onYes();
  };

  return (
    <>
      <Button variant="outlined" {...buttonProps}  onClick={handleClickOpen}>
        {buttonLabel}
      </Button>
      <Dialog
        open={open}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmationText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo} color={dangerousAction ? "primary" : "secondary"}>
            No
          </Button>
          <Button onClick={handleYes} color={!dangerousAction ? "primary" : "secondary"}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}