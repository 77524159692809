import { Button, CircularProgress, Container, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import useQuizes from "../../../hooks/useQuizes";


export default function ManageQuizes() {

  const {
    status: quizesStatus,
    quizes
  } = useQuizes();


  return (
    <Container style={{marginTop: '2em'}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">
            Manage Quizes
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            component={Link}
            to={`/edu/admin/quizes/new`}
            variant="contained"
          >
            Create new Quiz
          </Button>
        </Grid>
        <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table style={{width: '100%'}}>
            <TableHead>
              <TableRow>
                <TableCell component="th" >Title</TableCell>
                <TableCell  component="th" align="right">Id</TableCell>
                <TableCell  component="th" align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quizesStatus === 'FETCHING' &&
                <CircularProgress/>
              }
              {quizes?.map((quiz) => (
                <TableRow key={quiz.id}>
                  <TableCell>
                    {quiz.title}
                  </TableCell>
                  <TableCell align="right">{quiz.id}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      component={Link} 
                      to={`/edu/admin/quizes/${quiz.id}`}>
                      <Edit/>
                    </IconButton>
                    <Button
                      component={Link} 
                      to={`/edu/admin/quizes/${quiz.id}/attempts`}
                      variant="contained">
                      Attempts
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Grid>
      </Grid>
    </Container>
  )

}