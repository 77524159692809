import React, { useEffect } from "react";
import getQuizAttempt from "../api/quiz/getQuizAttempt";
import { QuizAttempt } from "../types/Quiz";


export interface QuizAttemptState {
  status: 'FETCHING' | 'FETCH_ERROR' | 'SUCCESS';
  error?: string;
  attempt?: QuizAttempt;
};
 
export interface QuizAttemptStore extends QuizAttemptState {
  fetchQuizAttempt: () => void;
}
export default function useAttempt(quizId?: string, timestamp?: string, userId?: string): QuizAttemptStore {
  
  const [quizAttemptState, setQuizAttemptState] = React.useState<QuizAttemptState>({
    status: 'FETCHING'
  });

  const fetchQuizAttempt = React.useMemo(() => async () => {
    if (!quizId) {
      setQuizAttemptState({
        status: 'SUCCESS',
        attempt: undefined,
        error: undefined
      });
      return;
    }
    const resp = await getQuizAttempt(quizId, timestamp, userId);
    if (resp.error) {
      setQuizAttemptState({
        status: 'FETCH_ERROR',
        error: resp.error
      })
    } else {
      setQuizAttemptState({
        status: 'SUCCESS',
        error: '',
        attempt: resp.attempt
      })
    }
  }, [quizId]);
  useEffect(() => {
    fetchQuizAttempt()
  }, [fetchQuizAttempt]);
  const value = React.useMemo(() => {
    return {
      ...quizAttemptState,
      fetchQuizAttempt
    }
  }, [quizAttemptState, fetchQuizAttempt]);
  return value;
}