import styled from "styled-components";
import Editor from 'react-simple-code-editor';
import './ProgrammingChallenge.css';
import Highlight, { defaultProps, Language } from "prism-react-renderer"
import { Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { ProgramRunner } from "../../hooks/useProgramRunner";
import theme from "prism-react-renderer/themes/nightOwl";
import { Fragment } from "react";
import { Alert } from "@mui/lab";
import { Done, RotateLeft } from "@mui/icons-material";
import { ExecutionArgs } from "../../api/codeExecutor/runProgram";

const styles = {
  root: {
    boxSizing: 'border-box',
    fontFamily: '"Dank Mono", "Fira Code", monospace',
    ...theme.plain
  }
}

const languageToDesc = {
  'JAVA': "Java",
  // 'JAVASCRIPT': 'Javascript',
  'PYTHON': 'Python',
  'NODE_LTS': 'Node 14 (Javascript)'
} 

const toSyntaxHighlighter = (languageCode: ExecutionArgs['languageChoice']): Language => {
  switch (languageCode) {
    case 'JAVASCRIPT':
    case 'NODE_LTS': 
      return 'javascript';
    case 'JAVA':
      return 'objectivec';
    case 'PYTHON':
      return 'python';
    default:
      return 'typescript';
  }
}
export default function ProgrammingChallenge({
  programRunner,
  onSubmit,
  loading,
  disabled,
  SubmissionResult,
  quizMode
}: {
  programRunner: ProgramRunner,
  quizMode?: boolean,
  onSubmit?: () => void,
  disabled?: boolean,
  loading?: boolean,
  SubmissionResult?: React.ReactNode
}) {

  return (
    <div style={{marginBottom: '2em'}}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '1em'
      }}>
        <FormControl variant="filled" style={{minWidth: '150px'}}>
          <InputLabel id="languageLabel">Language</InputLabel>
          <Select
            labelId="languageLabel"
            id="languageSelect"
            disabled={programRunner.restrictLanguage || disabled}
            value={programRunner.executionArgs.languageChoice}
            onChange={(e) => {
              programRunner.setLanguage(e.target.value as ExecutionArgs['languageChoice']);
            }}
          >
            {
              Object.entries(languageToDesc).map(([key, desc]) => (
                <MenuItem 
                  key={key}
                  value={key}>
                   {desc}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <Tooltip title="This will reset your code editor" aria-label="reset code">
          <Button
            style={{marginLeft: '1em'}}
            variant="contained"
            color="error"
            disabled={disabled}
            onClick={programRunner.reset}
          >
            <RotateLeft/>
            Reset
          </Button>
        </Tooltip>
      </div>
      <div className="editor">
        <Editor
          value={programRunner.executionArgs.program}
          disabled={disabled}
          onValueChange={code => programRunner.setProgram(code)}
          // @ts-ignore
          highlight={code => (
            <Highlight {
              ...defaultProps} 
              theme={theme}
              code={code} 
              language={toSyntaxHighlighter(programRunner.executionArgs.languageChoice)}>
              {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <Fragment>
                  {tokens.map((line, i) => (
                    <div {...getLineProps({ line, key: i })}>
                      <span 
                        style={{
                          float: 'left', 
                          position:'absolute',
                          userSelect: 'none',
                          background: 'rgb(69, 63, 74)',
                          textAlign: 'right',
                          width: `${tokens.length >= 99 ? '30' : '25'}px`,
                          opacity: '0.5',
                          marginLeft: `-${tokens.length >= 99 ? '35' : '40'}px`}}>
                            {i + 1}
                      </span>
                      {line.map((token, key) => <span {...getTokenProps({ token, key })} />)}
                    </div>
                  ))}
                </Fragment>
              )}
            </Highlight>
          )}
          padding={45}
          // @ts-ignore
          style={styles.root}
        />
      </div>
      { !disabled &&
        <div style={{
          marginTop: '2em',
          display: 'flex',
          alignContent: 'center'
        }}>
          <Button 
            variant="contained"
            color={quizMode ? "secondary" : "primary"}
            style={{marginRight: '1em'}}
            disabled={loading || programRunner.executionStatus === 'RUNNING'}
            onClick={programRunner.submit}
          >Run</Button>
          {onSubmit && (
            <Button 
              variant="contained"
              color="secondary"
              style={{marginRight: '1em'}}
              disabled={loading || programRunner.executionStatus === 'RUNNING'}
              onClick={onSubmit}
            >Submit</Button>
          )}
          {(loading || programRunner.executionStatus === 'RUNNING') && 
              <CircularProgress/>
          }
          {programRunner.executionStatus === 'RUN_ERRORS' &&
            <Alert severity="error">Errors, see output</Alert>
          }
          {programRunner.executionStatus === 'RUN_SUCCESS' &&
            <Done style={{color: 'green'}}/>
          }
        </div>
      }
      <div style={{marginTop: '2em'}}>
        {SubmissionResult}
      </div>
      {!disabled &&
        <Grid container>
          <Grid item xs={12} md={6}>
              <h3>Input</h3>
              <textarea
                value={programRunner.executionArgs.inputArgs}
                onChange={(e) => programRunner.setInputArgs(e.target.value)}
                rows={3}
              />
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Output</h3>
            <pre style={{
              padding: '10px',
              backgroundColor: 'rgb(240, 240, 240)',
              border: programRunner.executionStatus === 'RUN_ERRORS' ? 'red 1px solid' : '',
              borderRadius: '5px',
              overflow: 'scroll'
            }}>
              {programRunner.lastExecution?.result}
              {programRunner.lastExecution?.errors && (
                <>
                  <h4 style={{color: 'red'}}>Errors</h4>
                  {programRunner.lastExecution.errors}
                </>
              )}
            </pre>
          </Grid>
        </Grid>
      }
    </div>
  ); 
}


const Wrapper = styled.div`
  font-family: sans-serif;
  text-align: center;
`;

const Pre = styled.pre`
  text-align: left;
  margin: 1em 0;
  padding: 0.5em;
  overflow: scroll;

  & .token-line {
    line-height: 1.3em;
    height: 1.3em;
  }
`;

const Line = styled.div`
  display: table-row;
`;

const LineNo = styled.span`
  display: table-cell;
  text-align: right;
  padding-right: 1em;
  user-select: none;
  opacity: 0.5;
`;

const LineContent = styled.span`
  display: table-cell;
`;
