import React from "react";
import useCourse from "../hooks/useCourse";
import CourseContext from "./CourseContext";

export default function CourseProvider(props: { courseId: string; isPublic?: boolean; children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) {
  const courseState = useCourse(props.courseId, props.isPublic);
  
  return (
    <CourseContext.Provider value={{...courseState, isPublic:props.isPublic}}>
      {props.children}
    </CourseContext.Provider>
  )
}