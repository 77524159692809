import { Button, Checkbox, CircularProgress, Container, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Typography} from "@mui/material";
import { Field, FieldArray, FieldProps, Formik } from "formik";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom"
import useQuiz from "../../../hooks/useQuiz";
import { Quiz } from "../../../types/Quiz";
import { v4 as uuidv4 } from 'uuid';
import SelectField from "../../../components/formik/SelectField";
import CheckboxField from "../../../components/formik/CheckboxField";
import { Delete, Save } from "@mui/icons-material";
import * as Yup from 'yup';
import { Alert } from "@mui/lab";
import InputField from "../../../components/formik/InputField";
import FormikErrorMessage from "../../../components/formik/FormikErrorMessage";
import postQuizMetadata from "../../../api/quiz/postQuizMetadata";

const QuizSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Too Short, min 5 characters')
    .max(250, 'Too Long, max 250 character')
    .required('Required'),
  assessmentType: Yup.string()
      .required('Required')
      .oneOf(['', 'EXERCISE', 'QUIZ'], 'Invalid choice'),
  questions: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
        problemStatement: Yup
          .string()
          .min(10, 'Min 10 characters')
          .required('Required'),
        answerType: Yup.string()
          .required('Required')
          .oneOf(['', 'MCQ_SINGLE', 'MCQ_MULTI', 'CODE', 'TEXT'], 'Invalid choice'),
        options: Yup.
          array()
            .when(['answerType'], {
              is: ['MCQ_SINGLE', 'MCQ_MULTI'],
              then: Yup
                .array()
                .of(
                  Yup
                    .object()
                    .shape({
                      id: Yup.string().required(),
                      content: Yup.string().required(),
                      isCorrect: Yup.boolean().required(),
                      contentType: Yup.string()
                        .required()
                        .oneOf(['', 'TEXT', 'IMAGE', 'CODE'], 'Unsupported option'),
                      codeLanguage: Yup.string()
                        .when(['contentType'], {
                          is: 'CODE',
                          then: Yup.string().required('Choose code language')
                        }),
                      feedback: Yup.string(),
                      wrongFeedback: Yup.string(),
                      rightFeedback: Yup.string()
                    })
                ).required('Required')
                .min(1, 'At least one option required')
            }),
        weight: Yup
            .number()
            .min(0),
        codeTestCases: Yup.
          array()
            .when(['answerType'], {
              is: 'CODE',
              then: Yup
                .array()
                .of(
                  Yup
                    .object()
                    .shape({
                      id: Yup.string().required(),
                      input: Yup.string(),
                      expectedOutput: Yup.string(),
                    })
                ).min(1, 'At least 1 test case required')
                .required('Required')
            }),
        baseLanguage: Yup.string(),
        baseCode: Yup.string()
            .when(['answerType'], {
              is: 'CODE',
              then: Yup.string()
            }),
        correctText: Yup.string()
            .when(['answerType'], {
              is: 'TEXT',
              then: Yup.string()
            }),
        feedback: Yup.string(),
        wrongFeedback: Yup.string(),
        rightFeedback: Yup.string()
      }),
    ).required('Must have a question')
    .min(1, 'At least one question required')
})
export default function QuizForm() {

  const {
    quizId
  } = useParams() as {
    quizId?: string
  };

  const quizState = useQuiz(quizId);
  const history = useHistory();

  const initalQuiz: Quiz|undefined = useMemo(() => {
    if (quizState.status === 'SUCCESS' && quizState.quiz) {
      return quizState.quiz;
    }
    return {
      title: '',
      assessmentType: 'EXERCISE', 
      questions: []
    }
  }, [quizState]);

  return quizState.status === 'FETCHING' ? <CircularProgress/> : (
    <Container>
      <Grid container component={Paper} style={{
        padding: '2em',
        marginTop: '2em',
      }} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">
            {quizId ? 'Edit Quiz' : 'Create Quiz'}
          </Typography>
          {quizId && 
            <Button 
              variant="contained"
              onClick={async () => {
                try {
                  let copiedQuiz = {
                    ...initalQuiz,
                    title: initalQuiz.title + " Duplicate"
                  }
                  delete copiedQuiz.id;
                  let savedQuiz = await postQuizMetadata(copiedQuiz);
                  if (!savedQuiz.error && savedQuiz.quiz) {
                    history.push(savedQuiz.quiz.id!)
                  }
                } catch(err) {
                  console.log("could not save", err);
                  alert("could not duplicate");
                }
              }}
            >Duplicate</Button>
          }
        </Grid>
        <Formik
          validationSchema={QuizSchema}
          initialValues={initalQuiz}
          validateOnBlur={true}
          enableReinitialize={true}
          onSubmit={async (values) => {
            try {
              let savedQuiz = await postQuizMetadata(values);
              if (!savedQuiz.error && savedQuiz.quiz) {
                if (quizId) {
                  quizState.fetchQuiz();
                } else {
                  history.push(savedQuiz.quiz.id!)
                }
              }
            } catch(err) {
              console.log("could not save", err);
            }
          }}
        >
          {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <>
                <Grid item xs={12}>
                  <Field
                    name="title"
                    label="Title"
                    required
                    component={InputField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectField
                      name={`assessmentType`}
                      label="Assessment Type*"
                      items={[
                        {
                          value: 'EXERCISE',
                          label: 'Exercise'
                        },
                        {
                          value: 'QUIZ',
                          label: 'Quize'
                        }
                      ]}
                      getValue={item => item.value}
                      render={item => <>{item.label}</>}
                    />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Questions
                  </Typography>
                  <FormikErrorMessage name="questions"/>
                </Grid>
                <FieldArray
                  name="questions"
                  render={ arrayHelpers => {
                    return (
                      <>
                        {values.questions?.map((question, qIndex) => (
                          <Grid item key={question.id} xs={12}>
                            <Grid container spacing={2} style={{
                              borderColor: 'lightgray',
                              borderStyle: 'solid',
                              padding: '1em',
                              borderWidth: '1px',
                              borderRadius: '10px'
                            }}>
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                 Question {qIndex + 1}
                                 <span>
                                  <IconButton onClick={() => arrayHelpers.remove(qIndex)}>
                                    <Delete/>
                                  </IconButton>
                                </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  name={`questions.${qIndex}.weight`}
                                  type="number"
                                  label="Weight"
                                  defaultValue={1}
                                  component={InputField}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <SelectField
                                    name={`questions.${qIndex}.answerType`}
                                    label="Question type*"
                                    items={[
                                      {
                                        value: 'MCQ_SINGLE',
                                        label: 'MCQ SINGLE'
                                      },
                                      {
                                        value: 'MCQ_MULTI',
                                        label: 'MCQ MULTI'
                                      },
                                      {
                                        value: 'TEXT',
                                        label: 'TEXT'
                                      },
                                      {
                                        value: 'CODE',
                                        label: 'Coding'
                                      }
                                    ]}
                                    getValue={item => item.value}
                                    render={item => <>{item.label}</>}
                                  />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel>
                                  Problem Statement* (Markdown)
                                </InputLabel>
                                <Field
                                  name={`questions.${qIndex}.problemStatement`}
                                  required
                                  rows={4}
                                  wrap="soft"
                                  cols={100}
                                  as="textarea"
                                />
                                <br/>
                                <FormikErrorMessage name={`questions.${qIndex}.problemStatement`}/>
                              </Grid>
                              {question.answerType === 'TEXT' &&
                                <Grid item xs={12}>
                                  <InputLabel>
                                    Correct Text
                                  </InputLabel>
                                  <Field
                                    name={`questions.${qIndex}.correctText`}
                                    required
                                    rows={4}
                                    wrap="soft"
                                    cols={100}
                                    as="textarea"
                                  />
                                  <FormikErrorMessage name={`questions.${qIndex}.correctText`}/>
                                </Grid>
                              }
                              {['MCQ_SINGLE', 'MCQ_MULTI'].includes(question.answerType || '') &&
                                  <QuizOptionInputs qIndex={qIndex} values={values}/>
                              }
                              {question.answerType === 'CODE' &&
                                <>
                                  <TestCasesInput qIndex={qIndex} values={values}/>
                                  <Grid item xs={12}>
                                    <InputLabel>
                                      Base code
                                    </InputLabel>
                                    <Field
                                      name={`questions.${qIndex}.baseCode`}
                                      required
                                      rows={4}
                                      wrap="soft"
                                      cols={100}
                                      as="textarea"
                                    />
                                    <FormikErrorMessage name={`questions.${qIndex}.baseCode`}/>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <SelectField
                                      name={`questions.${qIndex}.baseLanguage`}
                                      label="Code language"
                                      items={[
                                        {
                                          value: 'JAVA',
                                          label: 'Java'
                                        },
                                      ]}
                                      getValue={item => item.value}
                                      render={item => <>{item.label}</>}
                                    />
                                  </Grid>
                                </>
                              }
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  Question level feedback
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel>
                                  Generic feedback
                                </InputLabel>
                                <Field
                                  name={`questions.${qIndex}.feedback`}
                                  rows={4}
                                  wrap="soft"
                                  cols={100}
                                  as="textarea"
                                />
                                <FormikErrorMessage name={`questions.${qIndex}.feedback`}/>
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel style={{color: 'green'}}>
                                  Right feedback
                                </InputLabel>
                                <Field
                                  name={`questions.${qIndex}.rightFeedback`}
                                  rows={4}
                                  wrap="soft"
                                  cols={100}
                                  as="textarea"
                                />
                                <FormikErrorMessage name={`questions.${qIndex}.rightFeedback`}/>
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel style={{color: 'red'}}>
                                  Wrong feedback
                                </InputLabel>
                                <Field
                                  name={`questions.${qIndex}.wrongFeedback`}
                                  rows={4}
                                  wrap="soft"
                                  cols={100}
                                  as="textarea"
                                />
                                <FormikErrorMessage name={`questions.${qIndex}.wrongFeedback`}/>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                        <Grid item xs={12}>
                          <Button
                            onClick={() => arrayHelpers.push({
                              id: uuidv4(),
                              problemStatement: '',
                              answerType: 'TEXT'
                            })}
                          >
                            Add Question
                          </Button>
                        </Grid>
                      </>
                    );
                  }}
                />
                <Grid item xs={12}>
                  {Object.keys(touched).length > 0 && Object.keys(errors).length > 0 &&
                    <>
                      <Alert severity="error">Fix all errors before submitting</Alert>
                      {<pre>{JSON.stringify(errors, null, 2)}</pre>}
                    </>
                 }
                  
                </Grid>
                
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={() => handleSubmit()}
                  >
                    Save <Save style={{marginLeft: '10px'}}/>
                  </Button>
                </Grid>
              </>
            )
          }
        </Formik>
      </Grid>
    </Container>
  )
}

interface QuestionArrayFieldInputsProps {
  qIndex: number;
  values: Quiz;
}

function QuizOptionInputs(
  {
    qIndex,
    values
  }: QuestionArrayFieldInputsProps
) {
  return (
    <FieldArray
      name={`questions.${qIndex}.options`}
      render={optionsArrayHelper => (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">
              Options
            </Typography>
            <FormikErrorMessage name={`questions.${qIndex}.options`}/>
          </Grid>
          {values.questions[qIndex].options?.map((option, optionIndex) => (
            <Grid container key={optionIndex} spacing={2} style={{
                margin: '1em',
                borderColor: 'lightgray',
                borderStyle: 'solid',
                padding: '1em',
                borderWidth: '1px',
                borderRadius: '10px'
              }}>
              <Grid item xs={12}>
                Option {optionIndex + 1}
                <span>
                  <IconButton onClick={() => optionsArrayHelper.remove(optionIndex)}>
                    <Delete/>
                  </IconButton>
                </span>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                <SelectField
                  name={`questions.${qIndex}.options.${optionIndex}.contentType`}
                  label="Option content type"
                  items={[
                    {
                      value: 'TEXT',
                      label: 'Text'
                    },
                    {
                      value: 'IMAGE',
                      label: 'Image'
                    },
                    // {
                    //   value: 'USER_INPUT',
                    //   label: 'User Input'
                    // },
                    {
                      value: 'CODE',
                      label: 'Code (language specific highlighting)'
                    }
                  ]}
                  getValue={item => item.value}
                  render={item => <>{item.label}</>}
                />
                <CheckboxField
                  style={{
                    marginLeft: '0.5em'
                  }}
                  name={`questions.${qIndex}.options.${optionIndex}.isCorrect`}
                  label="Is correct"
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>
                  Option content* (Markdown)
                </InputLabel>
                {option.contentType === 'IMAGE' &&
                  <>
                    <Typography variant="caption">
                      Include url with s3 prefix (option not supported yet)
                    </Typography>
                    <br/>
                  </>
                }
                <Field
                  name={`questions.${qIndex}.options.${optionIndex}.content`}
                  required
                  rows={4}
                  wrap="soft"
                  cols={100}
                  as="textarea"
                />
                <FormikErrorMessage name={`questions.${qIndex}.options.${optionIndex}.content`}/>
              </Grid>
              {option.contentType === 'CODE' &&
                <Grid item xs={12}>
                  <SelectField
                    name={`questions.${qIndex}.options.${optionIndex}.codeLanguage`}
                    label="Code language"
                    items={[
                      {
                        value: 'JAVA',
                        label: 'Java'
                      },
                    ]}
                    getValue={item => item.value}
                    render={item => <>{item.label}</>}
                  />
                </Grid>
              }
              <Grid item xs={12}>
                <InputLabel>
                  Generic feedback
                </InputLabel>
                <Field
                  name={`questions.${qIndex}.options.${optionIndex}.feedback`}
                  rows={4}
                  wrap="soft"
                  cols={100}
                  as="textarea"
                />
                <FormikErrorMessage name={`questions.${qIndex}.options.${optionIndex}.feedback`}/>
              </Grid>
              <Grid item xs={12}>
                <InputLabel style={{color: 'green'}}>
                  Right feedback
                </InputLabel>
                <Field
                  name={`questions.${qIndex}.options.${optionIndex}.rightFeedback`}
                  rows={4}
                  wrap="soft"
                  cols={100}
                  as="textarea"
                />
                <FormikErrorMessage name={`questions.${qIndex}.options.${optionIndex}.rightFeedback`}/>
              </Grid>
              <Grid item xs={12}>
                <InputLabel style={{color: 'red'}}>
                  Wrong feedback
                </InputLabel>
                <Field
                  name={`questions.${qIndex}.options.${optionIndex}.wrongFeedback`}
                  rows={4}
                  wrap="soft"
                  cols={100}
                  as="textarea"
                />
                <FormikErrorMessage name={`questions.${qIndex}.options.${optionIndex}.wrongFeedback`}/>
              </Grid>
            </Grid>
          ))}
          
          
          <Grid item xs={12}>
            <Button 
              onClick={() => optionsArrayHelper.push({
                id: uuidv4(),
                content: '',
                isCorrect: false,
                contentType: 'TEXT',
                codeLangauge: undefined,
                userInputType: undefined,
                feedback: undefined,
                rightFeedback: undefined,
                wrongFeedback: undefined
              })}
            >
              Add Option
            </Button>
          </Grid>
        </>
      )}
  />
  )
}

function TestCasesInput(
  {
    qIndex,
    values
  }: QuestionArrayFieldInputsProps
) {
  return (
    <FieldArray
      name={`questions.${qIndex}.codeTestCases`}
      render={testCasesArrayHelper => (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">
              Code test cases
            </Typography>
            <FormikErrorMessage name={`questions.${qIndex}.codeTestCases`}/>
          </Grid>
          {values.questions[qIndex].codeTestCases?.map((testCase, testCaseIndex) => (
            <Grid container key={testCaseIndex} spacing={2} style={{
                margin: '1em',
                borderColor: 'lightgray',
                borderStyle: 'solid',
                padding: '1em',
                borderWidth: '1px',
                borderRadius: '10px'
              }}>
              <Grid item xs={12}>
                Test Case {testCaseIndex + 1}
                <span>
                  <IconButton onClick={() => testCasesArrayHelper.remove(testCaseIndex)}>
                    <Delete/>
                  </IconButton>
                </span>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>
                  Input
                </InputLabel>
                <Field
                  name={`questions.${qIndex}.codeTestCases.${testCaseIndex}.input`}
                  rows={4}
                  wrap="soft"
                  cols={100}
                  as="textarea"
                />
                <FormikErrorMessage name={`questions.${qIndex}.codeTestCases.${testCaseIndex}.input`}/>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>
                  Output
                </InputLabel>
                <Field
                  name={`questions.${qIndex}.codeTestCases.${testCaseIndex}.expectedOutput`}
                  rows={4}
                  wrap="soft"
                  cols={100}
                  as="textarea"
                />
                <FormikErrorMessage name={`questions.${qIndex}.codeTestCases.${testCaseIndex}.expectedOutput`}/>
              </Grid>
            </Grid>
          ))}
          
          
          <Grid item xs={12}>
            <Button 
              onClick={() => testCasesArrayHelper.push({
                id: uuidv4(),
                input: '',
                expectedOutput: ''
              })}
            >
              Add Test case
            </Button>
          </Grid>
        </>
      )}
  />
  );
}