import React, { useEffect } from "react";
import getSubscription from "../api/subscription/getSubscription";
import useUser from "./useUser";
import Subscription from "../types/subscription"
import StripeInvoice from "../types/StripeInvoice";


export interface SubscriptionState {
  status: string;
  subscriptionStatus?: string;
  includeInvoice: boolean;
  error?: string;
  sessionUrl?: string;
  isSubscribed: boolean;
  isTrialing?: boolean;
  subscription?: Subscription;
  latestInvoice?: StripeInvoice;
  upcomingInvoices?: StripeInvoice[];
};

const activeSubscriptionStatus =
  ['active', 'trialing'];
export default function useSubscription() {
  
  const [subscriptionState, setSubscriptionState] = React.useState<SubscriptionState>({
    isSubscribed: false,
    isTrialing: false,
    includeInvoice: false,
    status: 'FETCHING'
  });

  const fetchSubscription = React.useMemo(() => async () => {
    const resp = await getSubscription(subscriptionState.includeInvoice);
    if (resp.error) {
      setSubscriptionState({
        ...subscriptionState,
        isSubscribed: false,
        status: 'FETCH_ERROR',
        error: resp.error,
      })
    } else if (!resp.subscription || !activeSubscriptionStatus.includes(resp.subscription.subscription.status)) {
      setSubscriptionState({
        ...subscriptionState,
        isSubscribed: false,
        subscriptionStatus: resp.subscription?.subscription?.status,
        status: 'SUCCESS',
        subscription: resp.subscription,
        latestInvoice: resp.latestInvoice,
        upcomingInvoices: resp.upcomingInvoices,
        sessionUrl: resp.sessionUrl
      })
    } else {
      setSubscriptionState({
        ...subscriptionState,
        isSubscribed: true,
        isTrialing: resp.subscription.subscription.status === 'trialing',
        subscriptionStatus: resp.subscription?.subscription?.status,
        status: 'SUCCESS',
        subscription: resp.subscription,
        latestInvoice: resp.latestInvoice,
        upcomingInvoices: resp.upcomingInvoices,
        sessionUrl: resp.sessionUrl
      })
    }
  }, [subscriptionState]);
  useEffect(() => {
    fetchSubscription()
  }, [subscriptionState.includeInvoice]);
  const value = React.useMemo(() => {
    return {
      ...subscriptionState,
      fetchSubscription,
      setIncludeInvoice: (includeInvoice: boolean) => setSubscriptionState({...subscriptionState, includeInvoice})
    }
  }, [subscriptionState, fetchSubscription]);
  return value;
}