import { Alert } from "@mui/lab";
import { Field } from "formik";
import QuestionComponentProps from "./QuestionComponentProps";

export default function TextQuizQuestion({
  question,
  index,
  answer
}: QuestionComponentProps) {

  const answerInput = answer?.userInputs ? answer.userInputs[0] : undefined;
  return (
    <>
      <Field disabled={!!answer} name={`answers[${index}].userInputs[0].attemptText`}/>
      {answerInput && (
        <>
          {answerInput.feedback &&
            <Alert severity="info">{answerInput.feedback}</Alert>
          }
          {answerInput.rightFeedback &&
            <Alert severity="success">{answerInput.rightFeedback}</Alert>
          }
          {answerInput.wrongFeedback &&
            <Alert severity="error">{answerInput.wrongFeedback}</Alert>
          }
        </>
      )}
    </>
  )
}