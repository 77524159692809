import { Container, Grid } from "@mui/material";
import { Redirect } from "react-router-dom";
import ActionTile from "../../components/ActionTile";
import useUser from "../../hooks/useUser";



export default function AdminDashboard() {
  const userState = useUser();

  if (userState.status === 'LOADING') {
    return <p>Loading user</p>
  }
  if (!userState.user || !userState.user.admin) {
    return <Redirect to="/edu/home"/>
  } 
  return (
    <Container>
      <Grid container>
       <Grid item  xs={12} style={{
        }}>
         <ActionTile 
            title="View Quizes"
            link="/edu/admin/quizes"
            description="View user quizes"
         />
         <ActionTile 
            title="Manage Courses"
            link="/edu/admin/courses"
            description="Create and manage all your courses"
         />
       </Grid>
      </Grid>
    </Container>
  );
}