import { CircularProgress } from "@mui/material";
import { Alert } from "@mui/lab";
import { ErrorMessage, ErrorMessageProps, Field, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from 'yup';
import sendEmail from "../api/sendEmail";

const FormSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Too Short, min 5 characters')
    .max(250, 'Too Long, max 250 characters')
    .required('Required'),
  lastName: Yup.string().max(250, 'Too Long, max 250 characters'),
  email: Yup.string().email().required('Required'),
  body: Yup.string().min(5, 'Too short, min 5 characters').required('Required')
});

const FieldError = (props: any) => {
  return (
    <div style={{
      marginLeft: '2.5em',
      color: 'red'
    }}>
      {props.children}
    </div>
  )
}
export default function EmailInquiry({
  subjectPrefix,
  bodyPlaceholder
}: {
  subjectPrefix: string,
  bodyPlaceholder?: string
}) {
  const [messageStatus, setMessageStatus] = useState<'INITIAL' | 'SENT' | 'ERROR'>('INITIAL');

  return messageStatus === 'SENT' ?
  (
    <Alert severity="success">Thank you! We will be reaching out shortly.</Alert>
  ) :
  (
    <Formik
      initialValues={{ email: '', firstName: '', lastName: '', body: '' }}
      validationSchema={FormSchema}
      validateOnBlur={true}
      onSubmit={async (values) => {
        const result = await sendEmail({
          ...values,
          subject: subjectPrefix + ' ' + values.firstName
        });
        if (result.error) {
          setMessageStatus('ERROR');
        } else {
          try {
            // @ts-ignore
            fbq('track', 'Contact');
          } catch(err) {
            console.log("Fb track event failed", err);
          }
          
          setMessageStatus('SENT');
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Field type="text" name="firstName" requried placeholder="FIRST NAME"/>
          <ErrorMessage name="firstName" component={FieldError} />
          <Field type="text" name="lastName" placeholder="LAST NAME"/>
          <ErrorMessage name="lastName" component={FieldError} />
          <Field type="email" name="email" placeholder="EMAIL" required/>
          <ErrorMessage name="email" component={FieldError} />
          <Field 
            name="body"
            placeholder={bodyPlaceholder || "MESSAGE"}
            component="textarea"
            rows={3}
            required/>
          <ErrorMessage name="body" component={FieldError} />
          {messageStatus === 'ERROR' &&
            <Alert severity="error">Unable to send the message successfully, please email zaid@learnly.dev directly.</Alert>
          }
          {isSubmitting &&
            <CircularProgress style={{
              marginLeft: '1em'
            }}/>
          }
          <br/>
          <input type="submit" className="submit" disabled={isSubmitting} value="SUBMIT"/>
        </Form>
      )}
    </Formik>
  )
}