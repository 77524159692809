import { Field, FieldProps, getIn } from 'formik';


export default function FormikErrorMessage({
  name
}: {name: string}) {
  return (
    <Field
      name={name}
    >{({ form }: FieldProps) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return <span style={{color: 'red'}}>
        {(touch || form.dirty) && error && typeof error === 'string' ? error : null}
      </span>;
    }}
    </Field>
  );
}