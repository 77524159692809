import ApiRequest from "../apiRequest";
import { QuizAttempt } from "../../types/Quiz";

interface getAllQuizAttemptsOut {
  error?: string;
  attempts?: QuizAttempt[];
}
export default async function getAllQuizAttempts(quizId: string, userId?: string): Promise<getAllQuizAttemptsOut> {

  try {
    let result = await ApiRequest(
      '/api/quiz/' + quizId + '/attempt' + (userId ? `?userId=${userId}` : ''),
      'GET'
    );

  
    return {
      // @ts-ignore
      error: result.error,
      // @ts-ignore
      attempts: result.attempts
    }
  } catch (err) {
    console.error("could not fetch all quiz attempts details", err);
    return {
      error: 'Unable to get all attempts'
    }
  }
}

