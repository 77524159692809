import React from "react";
import { CourseState } from "../hooks/useCourse";


export interface Message {
  // for current users message status
  sendStatus: 'INITIAL' | 'LOADING' | 'SUCCESS' | 'ERROR';
  id: string;
  text: string;
  createdAt: Date;
  updatedAt?: number;
  user: {
    id: string;
  }
}
export interface IChatContext {
  currentUserId: string;
  mode?: 'CHAT_COURSE' | 'CHAT_DOCUMENT'|'CHAT_COLLECTION';
  currentEntityId?: string;
  messages: Message[];
  sendMessage: (text: string) => Promise<void>;
  setContext: (context: string) => void;
  fetchMessages: () => Promise<void>;
  status: 'INITIAL' | 'LOADING' | 'SUCCESS' | 'ERROR';
}

const ChatContext = React.createContext<IChatContext>({
  currentUserId: '',
  fetchMessages: async () => {},
  messages: [],
  setContext: (context: string) => {},
  sendMessage: async (text: string) => {},
  status: 'INITIAL'
});

export default ChatContext;