import ApiRequest from "../apiRequest";
import Feedback from "../../types/feedback";

export async function getFeedback(entityId: string): Promise<Feedback|undefined> {
    try {
        let result: Feedback | undefined = await ApiRequest(
            `/api/feedback/${entityId}`,
            'GET'
        )
        
        return result; 
    } catch (err) {
        console.error("could not request course generation", err);
        throw err;
    }
}