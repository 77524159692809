import { Box, Button, Divider, Drawer, List, ListItem, ListItemText } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components"
import useUser from "../hooks/useUser";
import { HashLink } from "react-router-hash-link";

export default function LoggedOutNavBar() {

  
  const userStore = useUser();
  const isUserSignedIn = !!userStore.user;
  
  const [isMenuOpen, setMenuOpen] = useState(false);

  const loginLink = isUserSignedIn ? "/edu/home" : "/edu/signin";

  return (
    <NavBarDiv>
      <div className="smallScreenMenu menu-bar">
        <Link to="/" className="nav-link logo">
          <img 

            src="/main-logo.png" 
            alt="Learnly Home"/>
          <span className="logo-text">Learnly</span>
        </Link>
        <div>
          <Button 
            id="small-screen-login-button"
            variant="contained"
            component={Link}
            to="/edu/home">
            {isUserSignedIn ? "Edu" : "Sign up"}
          </Button>
          <Button 
            onClick={() => setMenuOpen(true)}
            >
            <Menu/>
          </Button>
        </div>
        <Drawer
          anchor={"right"}
          open={isMenuOpen}
          onClose={() => setMenuOpen(false)}
        >
          <Box
            role="presentation"
          >
            <List>
              <ListItem button component={Link} to="/">
                <ListItemText primary={"Home"} />
              </ListItem>
              <ListItem button component={Link} to="/faq">
                <ListItemText primary={"FAQ's"} />
              </ListItem>
              <ListItem button component={Link} to="/edu/home">
                <ListItemText primary={"Getting started"} />
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem button component={Link} to={loginLink}>
                <ListItemText primary={isUserSignedIn ? "Edu" : "Sign up"} />
              </ListItem>
            </List>
          </Box>
        </Drawer>

      </div>
      <div className="bigScreenMenu menu-bar">
        <Link to="/" className="nav-link logo">
          <img 

            src="/main-logo.png" 
            alt="Learnly Home"/>
          <span className="logo-text">Learnly</span>
        </Link>
        <div>
          <Link to="/faq" className="nav-link">FAQ's</Link>
          <Link to="/edu/home" className="nav-link">GETTING STARTED</Link>
        </div>
        <Button 
          variant="contained"
          color="primary"
          component={Link}
          key={"loggedIn-" + isUserSignedIn}
          to={loginLink}>
          {isUserSignedIn ? "Edu" : "Sign up"}
        </Button>
      </div>
    </NavBarDiv>
  )
}

const NavBarDiv = styled.div`

.menu-bar {
  padding-top:1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

@media screen and (max-width: 1020px) {
    .bigScreenMenu {
      display: none;
    }
}
@media screen and (max-width: 400px) {
  #small-screen-login-button {
    display: none;
  }
}
@media screen and (min-width: 1021px) {
    .smallScreenMenu {
      display: none;
    }
}

.logo {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .logo-text {
    font-size: xx-large;
    margin-left: 0.5em;
    font-weight: 600;
  }
  .nav-link {
    font-weight: 600;
    font-size: xx-large;
    margin-left: 1em;
  }
  @media screen and (max-width: 1020px) {
    .logo {
      margin-left: 0px;
    }
  }
  
  img {
    width: 70px;
    height: 70px;
  }

`;