import ApiRequest from "../apiRequest";

export async function submitFeedback(entityId:string, contentLiked:boolean|undefined, pageUrl:string): Promise<boolean> {
    try {
        let result: {success?: boolean} = await ApiRequest(
            `/api/feedback`,
            'POST',
            JSON.stringify({
            entityId,
            contentLiked,
            pageUrl,
        })
        )
        console.log("this is the result: "+result)
        return !!result.success;
    } catch (err) {
        console.error("could not submit feedback", err);
        throw err;
    }
}