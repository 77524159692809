import { CardMedia } from '@mui/material';


const VideoBox = ({id}: {id: string}) => {

    const embedLink = `https://www.youtube.com/embed/${id}`;
    return (
        
        <CardMedia
            component="iframe"
            src={embedLink}
            frameBorder="0"
            allowFullScreen
            style={{ minHeight: '300px' }}
        />
    )
}

export default VideoBox;