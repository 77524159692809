import { Button, CircularProgress, FormHelperText, TextField, Container, Typography, Menu, MenuItem, IconButton, Tooltip, ClickAwayListener, Grid } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import NewNavBar from '../../components/NewNavBar';
import blurryImage from '../../assets/blurry.svg'
import fileUploadImage from '../../assets/file-upload.png'
import studentChatImage from '../../assets/student-chat.png'
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';


const theme = createTheme({
    palette: {
        info:{
            main:'#5D4682',
            dark:'#4c396b'  
        }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 800,
        md: 870,
        lg: 1200,
        xl: 1920,
      },
    },
    typography: {
        fontFamily: [
          'Inter', 
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          'sans-serif'
        ].join(',')
      }
  });


const useStyles=makeStyles(()=>({

    '@keyframes fadeInAnimation': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
    pageRoot:{
        padding:'50px 0',
    },
    heading:{
        fontWeight:'500',
        fontSize:'40px',
        opacity: 0,
        animation: '$fadeInAnimation 1s ease-in forwards',
        '@media (max-width: 540px)': {
            fontSize:'30px',    
          },
    },
    secondaryHeading:{
      fontWeight:'200',
      fontSize:'20px',
      opacity: 0,
      animation: '$fadeInAnimation 1s ease-in forwards',
      '@media (max-width: 540px)': {
          fontSize:'15px',    
        },
  },
    firstContainer:{
        marginBottom:'50px',
    },
    example:{
      height:'auto',
      borderRadius:'30px',
      background: 'rgba(4, 10, 20, 0.6)',
      backdropFilter: 'saturate(1.5) blur(30px)',
      padding:'30px',
      minHeight:'140px',
    },
    secondContainer:{
        height:'auto',
        alignItems:'center',
        borderRadius:'30px',
        background: 'rgba(4, 10, 20, 0.6)',
        backdropFilter: 'saturate(1.5) blur(30px)',
        padding:'40px 10px',
        marginBottom:'50px',
    },
    upperGrid:{
        '@media (max-width: 870px)': {
            marginBottom:'35px',    
          },
    },
    textDiv:{
        fontSize:'18px',
        padding:'0 30px',
    },
    imageContainer:{
        
    },
    image:{
        width:'100%', 
        minWidth:'320px',
        maxWidth:'600px', 
        borderRadius:'20px',
    },
    bothDivs:{
      animation: '$fadeInAnimation 0.4s ease-in backwards',
    },
}))

export default function StudentUseCase(){
    const classes=useStyles()

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth={"xl"} sx={{backgroundImage:`url(${blurryImage})`, backgroundSize:'cover', backgroundPosition:'center', padding:0}}>
                <NewNavBar/>
            <Container maxWidth={"xl"} className={classes.pageRoot} >
                <Container sx={{textAlign:'center', marginBottom:'50px'}}>
                    <Typography className={classes.heading}>Learn your subjects with your personal Learnly Gurus</Typography>
                    <Typography className={classes.secondaryHeading}>Have a lot of different text material? Master specific topics, without manually searching through different materials</Typography>
                </Container>
                <Container>
                    <Grid container className={classes.secondContainer}>
                        
                        <Grid item xs={12} md={5} style={{display:'flex',justifyContent:'center'}}>
                            <div className={classes.textDiv}>
                              <Typography style={{marginBottom:'35px', fontWeight:'400', fontSize:'25px'}}>Key Benefits <AutoGraphIcon/></Typography>
                              <CustomRomanList text='Create on-demand courses for specific topics (optionally provide your own reference materials)'/>
                              <CustomRomanList text='Increase retention with automated assessments'/>
                              <CustomRomanList text='Increase productivity by reducing time to search and learn topics'/>                                     
                            </div>
                        </Grid>
                        <Grid item xs={12} md={7} sx={{textAlign:'center'}} className={classes.upperGrid}>
                            <div className={classes.imageContainer}>
                                <img src={fileUploadImage} className={classes.image} loading='lazy'/>
                            </div>
                        </Grid>  
                    </Grid>  
                </Container>
                <Container>
                    <Grid container className={classes.secondContainer}>
                        <Grid item xs={12} md={7} sx={{textAlign:'center'}} className={classes.upperGrid}>
                            <div className={classes.imageContainer}>
                                <img src={studentChatImage} className={classes.image} loading='lazy'/>
                            </div>
                        </Grid>  
                        <Grid item xs={12} md={5} style={{display:'flex',justifyContent:'center'}}>
                            <div className={classes.textDiv}>
                              <Typography style={{marginBottom:'35px', fontWeight:'400', fontSize:'25px'}}>Features <AutoAwesomeIcon/></Typography>
                              <CustomRomanList text='Chat with virtual chatbots, trained on your custom material'/>
                              <CustomRomanList text='Create courses in 1 click, based on the data you provided or the data available on the internet'/>
                              <CustomRomanList text='Ensure learning with automatic assessments'/>
                              <CustomRomanList text='Completely secure, your data is encrypted at rest, only accessible by you'/>
                            </div>
                        </Grid>
                    </Grid>  
                </Container>
                <Container>
                    <Grid container className={classes.secondContainer}>
                        <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>
                            <div className={classes.textDiv}>
                                <Typography style={{fontSize:'20px', fontWeight:'500', marginBottom:'10px'}}>Client testimonial</Typography>
                                <Typography>As a university student, I had a midterm for my marketing course. I knew about the chapters that were going to be covered, I created a “Guru” on that material and quickly asked questions regarding practice problems. When I discovered gaps, I created small courses to get comfortable with the topic of my choice, the awesome part was that it automatically chose the relevant chapters from my course. I really liked the quizzes because they showed me where I needed to improve.</Typography>
                            </div>
                        </Grid>
                    </Grid>  
                </Container>
            </Container>
            </Container>
        </ThemeProvider>
    )
}


function CustomRomanList({text}:{ text: string }){
    return(
        <Typography style={{marginBottom:'28px', lineHeight:'20px', fontWeight:'300'}}>
            {text}
        </Typography>
    )
}