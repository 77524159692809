import React from "react";
import useSubscription from "../hooks/useSubscription";
import SubscriptionContext from "./SubscriptionContext";

export default function SubscriptionProvider(props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) {
  const subscriptionState = useSubscription();

  
  return (
    <SubscriptionContext.Provider value={subscriptionState}>
      {props.children}
    </SubscriptionContext.Provider>
  )
}