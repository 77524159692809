import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { ReactElement } from "react";
import FormikErrorMessage from "./FormikErrorMessage";

interface SelectFieldProps<T> {
  name: string;
  label: string;
  render: (item: T) => ReactElement;
  getValue: (item: T) => string;
  items: T[];
}
export default function SelectField<T>(props: SelectFieldProps<T>) {

  return (
    <Field name={props.name}>
        {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps<string>) => {
        const errorMessage = getIn(errors, field.name);
        const isTouched = getIn(touched, field.name);
        const hasError = errorMessage && isTouched;
        return (
          <FormControl style={{minWidth: '150px'}}>
            <InputLabel id={props.name + "-answerTypeLabel"}>{props.label}</InputLabel>
            <Select
              labelId={props.name + "-answerTypeLabel"}
              id={props.name + "-input"}
              name={props.name}
              error={hasError}
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
              }}
            >
              <MenuItem value={undefined}>
                <em>None</em>
              </MenuItem>
              {props.items.map((item, i) => (
                <MenuItem value={props.getValue(item)} key={i}>
                  {props.render(item)}
                </MenuItem>
              ))}
            </Select>
            <br/>
            <FormikErrorMessage name={props.name}/>
          </FormControl>
      )
    }}
    </Field>
  );
}