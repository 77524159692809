import { Send } from '@mui/icons-material';
import { Button, CircularProgress, FormHelperText, TextField } from '@mui/material';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { MessageBox, Input } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import ChatContext, { Message } from '../context/ChatContext';

// interface MessageBoxProps {
//   position: 'left' | 'right';
//   text: string;
// }

// function MessageBox({
//   position,
//   text,
// }: MessageBoxProps) {
//   return (
//     <div className={`message-box message-${position}`}>
//       <div className="message-box-content">{text}</div>
//     </div>
//   );
// }


const ChatBox = () => {
  const [minimized, setMinimized] = useState(true);

  const chatContext = useContext(ChatContext);

  const [text, setText] = useState('');
  const [textError, setTextError] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const validateText = (text: string) => {
    // max limit of 250 characters
    if (text.length > 250) {
      setTextError('Text must be less than 250 characters');
      return false;
    }

    // verify not blank
    if (text.trim().length === 0) {
      setTextError('Text cannot be blank');
      return false;
    }

    // make sure there is no forbidden characters
    if (!/^[a-zA-Z0-9:'.,? \n\t]*$/.test(text)) {
      setTextError('Text can only contain letters, numbers, and the following characters: \'.,?');
      return false;
    }


    setTextError('');
    return true;
  };

  // 

  const handleTextChange = (text: string) => {
    setText(text);
    validateText(text);
  };

  const onSend = () => {
    let message = text?.trim() || '';
    if (message && validateText(message)) {
      chatContext.sendMessage(message);
      setText('');
    }
  };

  const getMessagePosition = useMemo(() => (message: Message) => {
    if (message.user.id === chatContext.currentUserId) {
      return 'right';
    } else {
      return 'left';
    }
  }, [chatContext.currentUserId]);

  // end of messages ref
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    // @ts-ignore
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const toggleMinimized = () => {
    setMinimized(!minimized);
  };

  
  // scroll to bottom on new message
  useEffect(() => {
    if (!minimized) {
      scrollToBottom();
    }
  }, [chatContext.messages, minimized]);


  return (
    <div className={`chat-box ${minimized ? 'minimized' : ''}`} style={{boxShadow: '0 0 19px 7px #5D4682',border:'2px solid #5D4682'}}>
      <div className="chat-box-header" onClick={toggleMinimized}>
        <div className="chat-box-header-title" >Chat</div>
        <div className="chat-box-header-button">-</div>
      </div>

      <div style={{maxHeight: '90vh', backgroundColor: '#171F30', opacity: 1, transition: 'all 0.3s ease-in-out', overflowX: 'hidden', overflowY: 'scroll', height: minimized? 0 : '380px',
        marginBottom:'-0.5px',}} className="chat-box-scroll-style">
        {!minimized && chatContext.messages.map((message) => (
          <MessageBox
            key={message.id}
            // @ts-ignore
            position={getMessagePosition(message)}
            type="text"
            text={message.text}
            className="chat-box-message"
            styles={{
              backgroundColor: message.user.id === 'bot' ? '#172543' : '#21345f',
            }}


            />
        ))}
        <div style={{
          // display center
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          {(chatContext.status === 'LOADING'
           // or last message sendStatus is LOADING
            || (chatContext.messages.length > 0 && chatContext.messages[chatContext.messages.length - 1].sendStatus === 'LOADING'))
          && <CircularProgress size="20px" />}
          <div style={{ float:"left", clear: "both" }}
             ref={messagesEndRef}>
        </div>
        </div>
        
      </div>
    <div style={{height: '1px', backgroundColor: '#171F30'}}>

    </div>
    {!minimized && <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderTop:'2px solid #5D4682',
    }}>
      <div style={{flex: '1',}}>
        <textarea
          rows={2}
          ref={textAreaRef}
          wrap="soft"
          // cols={100}
          value={text}
          autoFocus
          placeholder='Type a message...'
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSend();
            }
        }}
          onChange={(e) => handleTextChange(e.target.value)}
          style={{
            resize: 'none',
            width: '100%',
            border: 'none',
            outline: 'none',
            padding: '10px',
            fontSize: '13px',
            fontFamily: 'inherit',
          }}
        />
        {textError && (
          <FormHelperText error>{textError}</FormHelperText> 
        )}
      </div>
      
      <Button disabled={!!textError} onClick={onSend}><Send/></Button>
    </div>}
      
      
    </div>
  );
};

export default ChatBox;