import ApiRequest from "../apiRequest";
import Subscription from "../../types/subscription";


interface SubscriptionResult {
  error?: string;
  subscription?: Subscription;
}
export default async function deleteSubscription(): Promise<SubscriptionResult> {

  try {
    let result = await ApiRequest(
      '/api/subscription',
      'DELETE'
    );

  
    return {
      // @ts-ignore
      error: result.error,
      // @ts-ignore
      subscription: result.subscription
    }
  } catch (err) {
    console.error("could not delete subscription", err);
    return {
      error: 'Unable to delete subscription'
    }
  }
}