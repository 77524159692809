import { useContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { useHistory } from "react-router";
import {useLocation } from "react-router-dom";
import NewNavBar from '../components/NewNavBar';
import SubscriptionContext from '../context/SubscriptionContext';
import useUser from "../hooks/useUser";

const aTheme = createTheme({
  palette:{
    primary: {
      main: '#172543',
      dark: '#0d1526',
    },
    secondary:{
      main:'#5D4682'
    },
    
    text:{
      primary:'#fff',
      secondary:'#999999'
    }

  }
});

export default function Pricing() {
  const { user } = useUser();
  const subscriptionContext = useContext(SubscriptionContext);
  const history = useHistory();
  const location = useLocation();
  const isOwnPage = location.pathname.startsWith('/pricing');
  

  const tiers = [
    {
      title: 'Free',
      price: '0',
      description: [
        'Maximum of 5 Gurus',
        'Maximum of 5 courses',
        'Maximum file size of 5MB',
        'YouTube video limited to 5 minutes',
        "Can't share courses",
      ],
      buttonText: isOwnPage?"Signed in":user?'Already active':'Sign up for free',
      fontWeight:'bold',
      buttonVariant: 'outlined',
      buttonAction:'/edu/home',
      disabled:isOwnPage?false:user?true:false,
    },
    {
      title: 'Pro',
      subheader: 'Most popular',
      price: '11',
      description: [
        'Unlimited Gurus',
        'Unlimited course creation',
        'Maximum file size of 200MB',
        'Up to 1 hour long youtube videos',
        'Share courses',
      ],
      buttonText:subscriptionContext.isSubscribed? 'Already active':'Get started',
      fontWeight:'thin',
      buttonVariant: 'contained',
      buttonAction:'/edu/upgradePlan',
      disabled:subscriptionContext.isSubscribed?true:false,
  
    },
  ];

  return (
    <>
    {isOwnPage && <NewNavBar/>}
    <ThemeProvider theme={aTheme}>
      <div style={{marginBottom:'20px',minHeight:'100vh'}}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none',  } }} />
      <CssBaseline />
      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 3, pb: 6,}}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          style={{color:'white'}}
          gutterBottom
        >
          Pricing
        </Typography>
        <Typography variant="h5" align="center" component="p">
          Unparalleled pricing, redefining the industry benchmark.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end" justifyContent="center">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card sx={{backgroundColor: aTheme.palette.primary.main,color:aTheme.palette.text.primary,}}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon style={{color:aTheme.palette.text.secondary}}/> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                    color:aTheme.palette.text.secondary
                  }}
                  sx={{
                    backgroundColor: aTheme.palette.primary.dark
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                    <span style={{fontSize:'2rem'}}>US </span>${tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /mo
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                      
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    disabled={tier.disabled}
                    color='secondary'
                    style={{fontWeight:tier.fontWeight}}
                    fullWidth
                    variant={tier.buttonVariant as 'outlined' | 'contained'}
                    onClick={() => history.push(tier.buttonAction)}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      </div>
    </ThemeProvider>
    </>
  );
}
