import { Message } from "../../context/ChatContext";
import ApiRequest from "../apiRequest";

export interface ChatSendMessageResponse {
  messageId: string;
  response?: string;
  topn_chuncks?: string[];
}

export async function sendDocumentBotMessageSync(documentId: string, chatHistory: Message[], context: {[key: string]: any}): Promise<ChatSendMessageResponse | undefined> {
  try {
    let result: {response?: string} = await ApiRequest(
      `/api/docChatHandler`,
      'POST',
      JSON.stringify({
        documentId,
        chatHistory,
        context
      })
    )
    return result as ChatSendMessageResponse;
  } catch (err) {
    console.error("could not request course generation", err);
    throw err;
  }
}