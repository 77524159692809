import { FormControl, FormControlLabel, FormControlLabelProps, InputLabel, MenuItem, Select } from "@mui/material";
import { Field, FieldProps } from "formik";
import { ReactElement } from "react";
import {Checkbox} from "@mui/material";

interface CheckboxFieldProps {
  name: string;
  label: string;
  style: FormControlLabelProps['style']
}
export default function CheckboxField(props: CheckboxFieldProps) {

  return (
    <Field name={props.name}>
        {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps<string>) => (
          <FormControlLabel
            style={props.style}
            control={<Checkbox
              checked={Boolean(field.value)}
              onChange={field.onChange}
              name={props.name} />}
            label={props.label}
          />
        )}
    </Field>
  );
}