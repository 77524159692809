import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CopyAll, Share } from '@mui/icons-material';
import { CircularProgress, Switch } from '@mui/material';
import { getCourseAccess, isReadEnabled } from '../../api/courses/getCourseAccess';
import { addCourseAccess } from '../../api/courses/addCourseAccess';
import { deleteCourseAccess } from '../../api/courses/deleteCourseAccess';
import { toast } from 'react-toastify';

export default function ShareCourseButton(
  {
    userSignedIn,
    courseId
  }: {
    userSignedIn: boolean,
    courseId: string
  }
) {

  // check if 'PUBLIC' user has course access
  const [publicAccess, setPublicAccess] = React.useState<boolean|undefined>(true);

  const [loading, setLoading] = React.useState<boolean>(false);

  // useEffect(() => {
  //   if (courseId) {
  //     const getPublicAccess = async () => {
  //       setLoading(true);
  //       try {
  //         const publicCourseAccess = await getCourseAccess(courseId, 'PUBLIC');
  //         setPublicAccess(publicCourseAccess && isReadEnabled(publicCourseAccess));
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //     getPublicAccess();
  //   }
  // }, [courseId]);

  // const changePublicAccess = async (enabled: boolean) => {
  //   setLoading(true);
  //   try {
  //     if (enabled) {
  //       let enabledResult = await addCourseAccess(courseId, 'PUBLIC', 'READ');
  //       setPublicAccess(enabledResult);
  //     } else {
  //       let deletedResult = await deleteCourseAccess(courseId, 'PUBLIC');
  //       setPublicAccess(!deletedResult);
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleNo = () => {
    setOpen(false);
  };
  const handleYes = () => {
    setOpen(false);
  };

  // get current domain
  const domain = window.location.hostname;
  // get port for localhost
  const port = window.location.port;
  
  let publicUrl = `https://${domain}/course/${courseId}`;

  if (domain === "localhost") {
    publicUrl = `http://${domain}:${port}/course/${courseId}`;
  }


  return (
    <>
      <Button variant="contained" onClick={handleClickOpen} style={{marginLeft:'15px'}}>
        Share <Share/>
      </Button>
      <Dialog
        open={open}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Course Access</DialogTitle>
        <DialogContent style={{minWidth: '300px', maxWidth: '100vw'}}>
          <DialogContentText id="alert-dialog-description">
            {loading && <CircularProgress size={20}/>}
            <br/>
            Public URL
            {/* {userSignedIn && <>
              {'Enable public access '}
              <Switch disabled={loading} checked={publicAccess} onChange={(e) => changePublicAccess(e.target.checked)} />
            </>} */}
            <br/>
            {/*Show url and copy button*/}
            <div>
              <input value={publicUrl} disabled/>
              <Button 
                style={{height: '30px'}}
                onClick={() => {
                  navigator.clipboard.writeText(publicUrl);
                  // show copied toast message
                  toast.success('Copied to clipboard');

                }}
                variant='contained'><CopyAll/></Button>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo} color={"secondary"}>
            Close
          </Button>
          {/*
          <Button onClick={handleYes} color={!dangerousAction ? "primary" : "secondary"}>
            Yes
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
