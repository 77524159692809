import { RefObject, useRef } from "react";

export default function useScroll(): [() => void, RefObject<HTMLInputElement>]  {
  const elRef: RefObject<HTMLInputElement> = useRef(null);
  const executeScroll = () => elRef?.current?.scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  });

  return [executeScroll, elRef];
};