import { Alert, CircularProgress, Container, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useParams } from "react-router";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import CourseOverview from "../components/course/CourseOverview";
import CourseSectionPage from "../components/course/CourseSection";
import CourseContext from "../context/CourseContext";
import CourseProvider from "../context/CourseProvider";
import SubscriptionContext from "../context/SubscriptionContext";
import useCourse from "../hooks/useCourse";
import ChatBox from "../components/ChatBox";
import ChatProvider from "../context/ChatProvider";
import '../styles/CircularProgress.css'



export default function CoursePage() {

  const params: any = useParams();
  const courseId: string = params.courseId;

  let match = useRouteMatch();
  // REMEMBER to add to PublicCoursePage.tsx

  return (
    <>
    <ChatProvider currentEntityId={courseId}>
      
            <Grid container>
              <CourseProvider courseId={courseId}>
                <LoadingIndicator/>
                <Grid item xs={12}>
                  <Switch>
                    <Route path={`${match.path}/module/:moduleId/section/:sectionId`}>
                      <CourseSectionPage />
                    </Route>
                    <Route path={match.path}>
                      <CourseOverview />
                    </Route>
                  </Switch>
                </Grid>
              </CourseProvider>              
          </Grid>
      </ChatProvider>
            
    </>
  );
}

function LoadingIndicator() {
  const courseState = useContext(CourseContext);
  // REMEMBER to add to PublicCoursePage.tsx
  return (
    <Grid xs={12} item>
      {['INITIAL', 'LOADING'].includes(courseState.status) && <CircularProgress  className="center-circular-progress"/>}
      {courseState.status === 'INVALID_COURSE' && <Alert severity="error">Course not found or you don't have permissions</Alert>}
    </Grid>
  )
}