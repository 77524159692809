import ApiRequest from "../apiRequest";
import { QuizAttempt } from "../../types/Quiz";

interface postQuizAttemptOut {
  error?: string;
  attempt?: QuizAttempt;
}
export default async function postQuizAttempt(attempt: QuizAttempt): Promise<postQuizAttemptOut> {

  try {
    let result = await ApiRequest(
      `/api/quiz/${attempt.quizId}/attempt`,
      'POST',
      JSON.stringify({
        attempt
      })
    )

    return {
      // @ts-ignore
      error: result.error,
      // @ts-ignore
      attempt: result.attempt
    }
  } catch (err) {
    console.error("could not post attempt details", err);
    return {
      error: 'Unable to post attempt'
    }
  }
}