import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { Alert } from "@mui/lab";
import { Field, FieldProps } from "formik";
import { useMemo } from "react";
import { AnswerInput } from "../../../types/Quiz";
import OptionContent from "./OptionContent";
import QuestionComponentProps from "./QuestionComponentProps";

export default function MCQSingleQuestion({
  question,
  index,
  answer
}: QuestionComponentProps) {

  const answerInputByOptionId: {
    [key: string]: AnswerInput
  } = useMemo(() => {
    if (!answer || !answer.userInputs) {
      return {};
    }
    return answer.userInputs.reduce((obj, opt) => {
      // @ts-ignore
      obj[opt.optionId] = opt;
      return obj;
    }, {});
  }, [answer]);

  return (
    <Field name={`answers[${index}].userInputs[0].optionId`}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps<string>) => (
        <FormControl component="fieldset" >
          <RadioGroup 
             {...field}>
            {
              question.options
                ?.map((option, index) => (
                  <div key={index} >
                  <div  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <Radio
                      checked={(answer?.userInputs?.[0]?.optionId || field.value) === option.id}
                      disabled={!!answer}
                      value={option.id}
                    />
                    <OptionContent option={option}/>
                   
                  </div>
                   {answerInputByOptionId[option.id] && (
                    <>
                      {answerInputByOptionId[option.id].feedback &&
                        <Alert severity="info">{answerInputByOptionId[option.id].feedback}</Alert>
                      }
                      {answerInputByOptionId[option.id].rightFeedback &&
                        <Alert severity="success">{answerInputByOptionId[option.id].rightFeedback}</Alert>
                      }
                      {answerInputByOptionId[option.id].wrongFeedback &&
                        <Alert severity="error">{answerInputByOptionId[option.id].wrongFeedback}</Alert>
                      }
                    </>
                  )}
                  </div>
                ))
            }
          </RadioGroup>
          {meta.touched && meta.error && (
            <div className="error">{meta.error}</div>
          )}
        </FormControl>
      )}
    </Field>
    
  )
}