import ChatDocument from "../../types/ChatDocument";
import ApiRequest from "../apiRequest";


export default async function getDocument(documentId: string): Promise<ChatDocument|undefined> {


  try {
    let result: ChatDocument | undefined = await ApiRequest(
      `/api/docMetadata/${documentId}`,
      'GET'
    )
    return result as ChatDocument;
  } catch (err) {
    console.error("could not get document", err);
    
    return undefined;
  }
};