import ApiRequest from "../apiRequest";
import Subscription from "../../types/subscription";
import StripeInvoice from "../../types/StripeInvoice";


interface SubscriptionResult {
  error?: string;
  subscription?: Subscription;
  latestInvoice?: StripeInvoice;
  upcomingInvoices?: StripeInvoice[];
  sessionUrl?: string;
}
export default async function getSubscription(includeInvoice?: boolean): Promise<SubscriptionResult> {

  try {
    let result = await ApiRequest(
      '/api/subscription' + (includeInvoice ? '?includeInvoice=true' : ''),
      'GET'
    );

  
    return {
      // @ts-ignore
      error: result.error,
      // @ts-ignore
      subscription: result.subscription,
      // @ts-ignore
      latestInvoice: result.latestInvoice,
      // @ts-ignore
      upcomingInvoices: [result.upcomingInvoices],
      // @ts-ignore

      sessionUrl: result.sessionUrl
    }
  } catch (err) {
    console.error("could not fetch coupon details", err);
    return {
      error: 'Unable get subscription'
    }
  }
}