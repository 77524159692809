import { Container, createTheme, Divider, Grid, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Twitter } from "@mui/icons-material";
import {Link,useLocation } from "react-router-dom";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    hidden:{
        display:'none',
    }
}))


export default function Footer(){
    const classes = useStyles();
    const location = useLocation();

    const isEduRoute = location.pathname.startsWith('/edu/collection-chat')||  location.pathname.startsWith('/edu/test-collection-chat');
    return(
        <div style={{backgroundColor:'#101828',paddingTop:'30px'}} className={`${isEduRoute ? classes.hidden : ''}`}>
              <Divider/>
              <Container>
                <Grid container>
                  <Grid item sm={6}>
                    <List>
                      <ListItem component={Link} to="/">
                        <ListItemText primary="Home"/>
                      </ListItem>
                      <ListItem component={Link} to="/contact-us">
                        <ListItemText primary="Contact us"/>
                      </ListItem>
                      {/* <ListItem component={Link} to="/about-us">
                        <ListItemText primary="About us"/>
                      </ListItem> */}
                      {/* <ListItem component={Link} to="/about">
                        <ListItemText primary="About me"/>
                      </ListItem>
                      <ListItem component={Link} to="/getting-started">
                        <ListItemText primary="Getting started"/>
                      </ListItem> */}
                    </List>
                  </Grid>
                  <Grid item md={6}>
                    <List>  
                      {/* <ListItem component={Link} to="/faqs">
                          <ListItemText primary="FAQs"/>
                        </ListItem> */}
                      <ListItem>
                        <ListItemText primary={(
                        <a href="https://twitter.com/zqureshi_">Twitter &ndash; <Twitter/></a>
                        )}/>
                      </ListItem>
                      <ListItem component={Link} to="/tc">
                        <ListItemText primary="Terms and Conditions"/>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
                <Grid container style={{color:'white'}}>
                  Copright 2024 Learnly Inc. All rights reserved.
                </Grid>
              </Container>
            </div>
    )
}