import { useEffect, useMemo, useState } from "react";
import executeProgram, { ExecutionArgs, ExecutionResult } from "../api/codeExecutor/runProgram";


export interface ExecutionState {
  lastExecution?: ExecutionResult;
  executionStatus: 'IDLE' | 'RUNNING' | 'RUN_SUCCESS' | 'RUN_ERRORS';
  executionArgs: ExecutionArgs
}

export interface ProgramRunner extends ExecutionState {
  setLanguage: (language: ExecutionArgs['languageChoice']) => void;
  setProgram: (program: ExecutionArgs['program']) => void;
  setInputArgs: (inputArgs: ExecutionArgs['inputArgs']) => void;
  setCompilerArgs: (compilerArgs: ExecutionArgs['compilerArgs']) => void;
  restrictLanguage?: boolean
  reset: () => void;
  submit: () => void;
}

const baseCodeByLanguage: Record<ExecutionArgs['languageChoice'], string> = {
  'JAVA': `
  import java.util.*;

  class Solution {
  
    public static void main(String[] args) {
    
      System.out.println("Hello world!");
      
    }
  }
  `,
  'JAVASCRIPT': 'console.log("Hello world!");',
  'NODE_LTS': 'console.log("Hello world!");',
  'PYTHON': 'print("Hello world!");'
}
for (const [key, value] of Object.entries(baseCodeByLanguage)) {
  const numberOfLines = value.split(/\r\n|\r|\n/).length;
  if (numberOfLines < 5) {
    let newBaseCode = value;
    for (let i = 0; i < (5 - numberOfLines); i++) {
      newBaseCode += '\n';
    }
    baseCodeByLanguage[key as ExecutionArgs['languageChoice']] = newBaseCode;
  }
}
export default function useProgramRunnner(
  baseLanguage: ExecutionArgs['languageChoice'],
  baseProgram?: string,
  restrictLanguage?: boolean
): ProgramRunner {

  const [state, setState] = useState<ExecutionState>({
    executionStatus: 'IDLE',
    executionArgs: {
      languageChoice: baseLanguage,
      program: !!baseProgram ? baseProgram : baseCodeByLanguage[baseLanguage],
    }
  });


  const setLanguage = useMemo(() => (languageChoice: ExecutionArgs['languageChoice'])  => {
    if (restrictLanguage) {
      return;
    }
    setState(prev => ({
      ...prev,
      executionArgs: {
        ...prev.executionArgs,
        languageChoice,
        program: baseCodeByLanguage[languageChoice]
      }
    }))
  }, []);
  const setProgram = useMemo(() => (program: ExecutionArgs['program'])  => {
    setState(prev => ({
      ...prev,
      executionArgs: {
        ...prev.executionArgs,
        program 
      }
    }))
  }, []);
  const setInputArgs = useMemo(() => (inputArgs: ExecutionArgs['inputArgs'])  => {
    setState(prev => ({
      ...prev,
      executionArgs: {
        ...prev.executionArgs,
        inputArgs 
      }
    }))
  }, []);
  const setCompilerArgs = useMemo(() => (compilerArgs: ExecutionArgs['compilerArgs'])  => {
    setState(prev => ({
      ...prev,
      executionArgs: {
        ...prev.executionArgs,
        compilerArgs 
      }
    }))
  }, []);

  const submitCode = async () => {
    setState(prev => ({
      ...prev,
      executionStatus: 'RUNNING'
    }));
    console.log("state", state);
    const executionResult = await executeProgram(state.executionArgs);

    setState(prev => ({
      ...prev,
      lastExecution: executionResult,
      executionStatus: executionResult.errors ? 'RUN_ERRORS' : 'RUN_SUCCESS'
    }));

  };

  const reset = useMemo(() => () => {
    setState({
      executionStatus: 'IDLE',
      executionArgs: {
        languageChoice: baseLanguage,
        program: !!baseProgram ? baseProgram : baseCodeByLanguage[baseLanguage],
      }
    });
  }, []);
  const result = useMemo(() => ({
    ...state,
    restrictLanguage,
    setLanguage,
    setProgram,
    setCompilerArgs,
    setInputArgs,
    reset,
    submit: () => submitCode()
  }), [state]);
  return result;
}

