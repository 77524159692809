import { PersonalVideo } from "@mui/icons-material";
import React, { useEffect } from "react";
import getCourse from "../api/courses/getCourse";
import getCourseProgress, { CourseProgress } from "../api/courses/getCourseProgress";
import getSection from "../api/courses/getSection";
import markAsCompleted from "../api/courses/markAsCompleted";
import Course from "../types/Course";


export interface CourseState {
  courseId: string;
  course?: Course;
  courseProgress?: CourseProgress;
  valid?: boolean;
  status: string;
  error?: string;
  
}
export default function useCourse(courseId: string, isPublic?: boolean) {
  
  const [currentCourseState, setCurrentCourseState] = 
    React.useState<CourseState>({
      courseId,
      status: 'INITIAL',
    });

  const fetchCourse = React.useMemo(() => async () => {

      const resp = await getCourse(courseId, isPublic, true);
      if (resp.error) {
        setCurrentCourseState((prev) => ({
          ...prev,
          courseId,
          status: 'INVALID_COURSE',
          error: resp.error
        }));
      } else {
        setCurrentCourseState((prev) => ({
          ...prev,
          courseId,
          course: resp.course,
          status: 'SUCCESS',
          error: undefined
        }));
      }
  }, [courseId, isPublic]);

  // const fetchSection = React.useMemo(() => async (courseId: string, moduleId: string, sectionId: string) => {
  //   const resp = await getSection(courseId, moduleId, sectionId);
  // }, []);

  const updateCourseProgress = React.useMemo(() => (completed: CourseProgress['completed']) => {
    setCurrentCourseState((prev) => ({
      ...prev,
      courseId,
      courseProgress: {
        courseId,
        completed
      }
    }));
  }, [courseId]);
  const fetchProgress = React.useMemo(() => async () => {
    const resp = await getCourseProgress(courseId);
    if (resp.completed) {
      updateCourseProgress(resp.completed);
    }
  }, [courseId, updateCourseProgress]);
  useEffect(() => {
    if (!isPublic) {
      fetchProgress();
    }
  }, [fetchProgress, isPublic])

  useEffect(() => {
    if (courseId) {
      fetchCourse();
    }
  }, [courseId, fetchCourse]);
  const result = React.useMemo(() => {

    return {
      ...currentCourseState,
      fetchCourse,
      fetchSection: (sectionId: string) => {

      },
      markAsCompleted: async (sectionId: string, partIds?: string[]) => {
        if (!partIds) {
          return;
        }
        let result = await markAsCompleted(courseId, sectionId, partIds);
        if (result && result.completed) {
          updateCourseProgress(result.completed);
        }
      }
    }
  }, [courseId, currentCourseState, fetchCourse, updateCourseProgress]);

  return result;
}