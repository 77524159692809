import React, { useEffect } from "react";
import { Button, Box, IconButton } from "@mui/material";
import {ThumbUpAlt, ThumbDownAlt} from '@mui/icons-material';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { getFeedback } from "../../api/feedback/getFeedback";
import { submitFeedback } from "../../api/feedback/submitFeedback";
import { CircularProgress} from '@mui/material';


export default function FeedbackButton({entityId}: {entityId: string}) {

  const [loading, setLoading] = React.useState<boolean>(false);
  const [isThumbUp, setIsThumbUp] = React.useState<boolean>(false);
  const [isThumbDown, setIsThumbDown] = React.useState<boolean>(false);

  useEffect(() => {

    const getExistingFeedback = async () => {
      setLoading(true);
      try {
        setIsThumbUp(false);
        setIsThumbDown(false);
        let givenFeedback;
        try{
          givenFeedback = await getFeedback(entityId);
        }catch(error){
          console.log("An error occured while getting feedback: ",error);
        }
        if(givenFeedback?.contentLiked===true){setIsThumbUp(true)}
          else if(givenFeedback?.contentLiked===false){setIsThumbDown(true)}
        
      } finally {
        setLoading(false);
      }
    }
    getExistingFeedback()
  }, [entityId]);

  let url:string=window.location.href;

  
  
  const onThumbUp = async () => {
    let feedbackRegistered;
    if(isThumbUp){ //check if it was already pressed, if yes then pass an empty courseLiked
      try{
        feedbackRegistered=await submitFeedback(entityId,undefined,url)
      }catch(e){
        console.log("An error occured while providing feedback: ",e)
      }
      
    }else{
      try{
        feedbackRegistered=await submitFeedback(entityId,true,url)
      }catch(e){
        console.log("An error occured while providing feedback: ",e)
      }
      
    }
    setIsThumbUp(!isThumbUp);
    if (isThumbDown) {
      setIsThumbDown(!isThumbDown);
    }

  };
  const onThumbDown = async () => {
    let feedbackRegistered;
    if(isThumbDown){
      try{
        feedbackRegistered=await submitFeedback(entityId,undefined,url)
      }catch(e){
        console.log("An error occured while providing feedback: ",e)
      }
      
    }else{
      try{
        feedbackRegistered=await submitFeedback(entityId,false,url)
      }catch(e){
        console.log("An error occured while providing feedback: ",e)
      }
      
    }
    
    setIsThumbDown(!isThumbDown);

    if (isThumbUp) {
      setIsThumbUp(!isThumbUp);
    }
  };


  return (
    <>
      <Box display="flex" alignItems="center" style={{backgroundColor:'#ffffff', borderRadius:'4px'}}>
        <IconButton
          onClick={onThumbUp}
          style={{color:'black'}}
        >
          {loading?<CircularProgress size={20}/>:
            isThumbUp? <ThumbUpAlt color='success'/> : <ThumbUpOffAltIcon/>
          }
        </IconButton>
        <IconButton
          onClick={onThumbDown}
          style={{color:'black'}}
        >
          {loading?<CircularProgress size={20}/>:
            isThumbDown?<ThumbDownAlt color='error'/> : <ThumbDownOffAltIcon/>
          }
        </IconButton>
      </Box>
    </>
  );
}
