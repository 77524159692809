/* eslint-disable react-hooks/exhaustive-deps */

// a page where user can upload a document

import { Box, Button, CircularProgress, Container, Grid, Typography } from "@mui/material";
import { Link, useHistory, useParams } from "react-router-dom";
import createDocumentRequest, { CreateDocumentResponse } from "../api/chatDocument/createDocumentRequest";
import { Toast } from "react-toastify/dist/components";
import { toast } from "react-toastify";
import useChatDocument from "../hooks/useChatDocument";
import { useContext, useEffect, useState } from "react";
import ChatProvider from "../context/ChatProvider";
import ChatContext from "../context/ChatContext";
import ChatWindow from "../components/ChatWindow";
import getDocumentS3Url from "../api/chatDocument/getDocumentS3Url";
import PDFViewer from "../components/PDFViewer";
import { FileDownload } from "@mui/icons-material";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import ChatBox from "../components/ChatBox";
import SubscriptionContext from "../context/SubscriptionContext";

// then chat with the document
export default function ChatDocumentPage() {

  const history = useHistory();

  const [file, setFile] = useState<File | null>(null);
  const [fileStatus, setFileStatus] = useState<'INITIAL' | 'PENDING_ID_CREATION' | 'UPLOAD_FILE' | 'UPLOADED' | 'ERROR'>('INITIAL');
  const [presignedDownloadUrl, setPresignedDownloadUrl] = useState<string|undefined>();
  const [presignedUploadUrl, setPresignedUploadUrl] = useState<CreateDocumentResponse['presignedUrl']|undefined>();
  // get document id from url

  const {documentId}: {
    documentId: string
  } = useParams();

  const documentStore = useChatDocument(documentId);
  const document = documentStore.document;
  const {height, width} = useWindowDimensions();

  // if document is present and vectorization status is INITIAL or pending vectorization, keep polling every 15 seconds
  useEffect(() => {
    if (document && ['INITIAL', 'PENDING_VECTORIZE'].includes(document.vectorizationStatus)) {
      const interval = setInterval(() => {
        documentStore.fetchDocument();
      }, 15000);
      return () => clearInterval(interval);
    }
  }, [document?.vectorizationStatus])

  // if document is present and vectorized, fetch the presigned download url
  useEffect(() => {
    async function fetchPresignedDownloadUrl() {
      if (!document) {
        return;
      }
      try {
        const presignedUrl = await getDocumentS3Url(document.id);
        setPresignedDownloadUrl(presignedUrl);
      } catch (err) {
        console.log("unable to fetch presigned download url", err);
        toast.error('unable to get document');
      }
    }
    if (document && document.vectorizationStatus === 'VECTORIZED') {
      fetchPresignedDownloadUrl();
    }
  }, [document?.vectorizationStatus])



  // create document function, takes in filename, length, and type, creates a document, forwards to /edu/chatdocument/:documentId
  const createDocument = async (file: File): Promise<void> => {
    // calls api to create document
    try {
      setFile(file);
      setFileStatus('PENDING_ID_CREATION');
      const documentResp = await documentStore.createDocument(file,false,undefined);
      if (documentResp?.document?.id) {
        // redirect to /edu/chatdocument/:documentId
        history.push(`/edu/chatdocument/${documentResp?.document.id}`);
        setPresignedUploadUrl(documentResp.presignedUrl);
        toast.info('Uploading document');
      }
    } catch (err) {
      console.log("unable to create document", err);
      toast.error('unable to upload')
    }
  };


  useEffect(() => {
    async function uploadFile() {
      try {
        if (!presignedUploadUrl) {
          return;
        }
        documentStore.uploadFile(file as File, presignedUploadUrl);
        setFileStatus('UPLOADED');
        setPresignedUploadUrl(undefined);
      } catch (err) {
        console.log("unable to upload file", err);
        toast.error('unable to upload')
        setFileStatus('ERROR');
      }
    }

    if (file && fileStatus === 'PENDING_ID_CREATION' && documentId !== 'new' && presignedUploadUrl) {
      setFileStatus('UPLOAD_FILE');
      uploadFile();
    }

  }, [documentId, fileStatus, file, presignedUploadUrl])
  const isNew = documentId === 'new';
  const isLoading = documentStore.status === 'LOADING' 
  || documentStore.status === 'INITIAL'
  || [ 'PENDING_UPLOAD' ].includes(documentStore.document?.uploadStatus || '')
  || [ 'PENDING_VECTORIZE'].includes(documentStore.document?.vectorizationStatus || '')
  ;
  const uploadingFile =  ['PENDING_ID_CREATION', 'UPLOAD_FILE'].includes(fileStatus) ||
          ['PENDING_UPLOAD'].includes(documentStore.fileUploadStatus);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPageText, setCurrentPageText] = useState<string|undefined>();
  const [highlightFileSizeError, setHighlightFileSizeError] = useState(false);
  let subscriptionContext = useContext(SubscriptionContext);
  let maxFileSize = subscriptionContext.isSubscribed ? 200 * 1024 * 1024 : 5 * 1024 * 1024;

  return (
    <ChatProvider currentEntityId={documentId} mode='CHAT_DOCUMENT'>
      <Container  maxWidth={isNew ? "md" : "xl"} 
        // ceneter the contents
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: isNew ? 'center' : 'flex-start',
            alignItems: 'center',
            minHeight: '70vh',
        }}>

          <Typography variant="h3" style={{
            marginBottom: '1rem'
          }}>{

            isNew ? 'Create a new document' : documentStore.document?.title || documentStore.document?.title
          }
          {presignedDownloadUrl && 
              <Button 
                variant="text" 
                download
                size="small"
                style={{
                  marginLeft: '1rem',
                }}
                href={presignedDownloadUrl}
                ><FileDownload/></Button>
            }
          
          </Typography>
          {isLoading && <CircularProgress/>}
        {/**Upload button that uploads a document, allows only pdf, single document */}
        {isNew && 
        
          <input type="file" disabled={isLoading || uploadingFile} accept=".pdf" style={{
            marginBottom: '1rem',
            marginTop: '1rem',
          }} onChange={(e) => {
            setHighlightFileSizeError(false);
            if (e.target.files && e.target.files.length > 0) {
              if (isNew) {
                // if document size is greater than 5 MB, don't upload
                if (e.target.files[0].size > maxFileSize) {
                  toast.error("File size too large");
                  setHighlightFileSizeError(true);
                  return;
                }
                createDocument(e.target.files[0]);
              } else {
                toast.warning("Edit not supported");
                // documentStore.uploadFile(e.target.files[0]);
              }
            }
          }} />
        }
        {isNew && (
          <Typography variant="caption" style={{padding: '1em'}} onClick={() => {}}>
            {subscriptionContext.isSubscribed ? "Max file size: 200MB" : 
                <span>
                    <span style={{color: highlightFileSizeError ? '#ff5757' : 'inherit'}}>Max file size is 5MB.</span>
                    <Button
                        variant="contained"
                        style={{marginLeft: '5px', marginRight: '5px'}}
                        size="small"
                        id="upgradePlan"
                        component={Link} to="/edu/upgradePlan">Upgrade Plan</Button>
                    to upload larger files.
                </span>
            }
          </Typography>
        )}
        {documentStore.document?.vectorizationStatus === 'PENDING_VECTORIZE' 
        && documentStore.document?.uploadStatus === 'UPLOADED'
        && <div>Processing document</div>
        }
        {documentStore.document?.vectorizationStatus === 'VECTORIZED' &&
          <Grid container spacing={2} style={{marginTop: '1rem'}}>
            <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom:'10px'}}>
              {!presignedDownloadUrl && <CircularProgress size={20} />}
              {presignedDownloadUrl && <>
                <PDFViewer url={presignedDownloadUrl} onPageChange={setPageNumber} onNewPageText={setCurrentPageText}/>
                <PageUpdateInContext pageNumber={pageNumber} currentPageText={currentPageText}/>  
              </>}
            </Grid>
            {width >= 900 && 
              <Grid item xs={12} md={6}>
                <Box style={{display: 'flex', minHeight: '350px'}}>
                  <ChatWindow/>
                </Box>
              </Grid>
            }
            {width < 900 && 
              <ChatBox/>
            }
          </Grid>
        }
      </Container>
    </ChatProvider>
  )
}

function PageUpdateInContext(props: {
  pageNumber: number,
  currentPageText?: string,
}) {
  const {pageNumber, currentPageText} = props;
  const chatContext = useContext(ChatContext);
  useEffect(() => {
    chatContext.setContext(JSON.stringify({
      currentPageNumber: pageNumber,
      currentPageText: ''//currentPageText,
    }))
  }, [pageNumber, currentPageText])
  return null;
}