import { Button, Card, CardActions, CardContent, CardMedia, Container, IconButton, TextField, Theme, Typography,CircularProgress, createTheme, LinearProgress, Box } from "@mui/material";
import { useHistory,Link,useParams, Prompt } from "react-router-dom";
import { useContext, useRef } from "react";
import { ThemeProvider } from "styled-components";
import { useEffect,useState } from "react";
import { makeStyles } from '@mui/styles';
import { toast } from "react-toastify";
import UploadIcon from '@mui/icons-material/Upload';
import useChatDocument from "../hooks/useChatDocument";
import createDocumentRequest, { CreateDocumentResponse } from "../api/chatDocument/createDocumentRequest";
import React from "react";
import { textToFile } from "../components/textToFile/textToFile";
import useVideoDocument from "../hooks/useVideoDocument";
import createCollectionRequest, { CreateCollectionResponse } from "../api/chatCollection/createCollectionRequest";
import DescriptionIcon from '@mui/icons-material/Description';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import ChatDocument from "../types/ChatDocument";
import ChatYoutubeVideo from "../types/ChatYoutubeVideo";
import getYoutubeTitle from "../api/chatVideo/getYoutubeTitle";
import SubscriptionContext from "../context/SubscriptionContext";
import getYoutubeMeta from "../api/chatVideo/getYoutubeMeta";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const customTheme = createTheme({
    
    palette: {
      primary: {
        main: '#101828',
        light:'#65748D',
        dark:'#040A14',
        contrastText: "#fff",
      },
      secondary: {
        main:  '#5D4682',
        contrastText: "#fff",
        
      },
      
    },
    
});

const useStyles=makeStyles(()=>({
    outerContainer:{
        backgroundColor:customTheme.palette.primary.main,
        minHeight:'100vh',
        paddingLeft:'0',
        paddingRight:'0',
        maxWidth:'4000px',
    },
    innerContainer:{
        marginTop:'35px',
        maxWidth:'1920px',
    },
    body:{
        maxWidth:'1920px',
        paddingBottom:'200px',
        
    },
    mainText:{
        color:customTheme.palette.primary.contrastText,
        fontSize:'45px',
        '@media (max-width: 450px)': {
            fontSize:'35px',
          },
        '@media (max-width: 400px)': {
            fontSize:'25px',
          },
        '@media (max-width: 900px)': {
        textAlign:'center',
        },
          
    },
    contentDiv:{
        marginTop:'30px',
        backgroundColor:'#65748D',
        borderRadius:'30px',
        minHeight:'58vh',
        
        '@media (max-width: 900px)': {
            justifyContent:'center',
          },
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
    titleBar:{

        backgroundColor:'#D9D9D9',
        height:'45px',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px',
        display:'flex',
        alignItems:'center',
        flexDirection:'row',
        paddingLeft:'20px',
        '@media (max-width: 540px)': {
            height:'80px',
          },
    },
    titleBarButton:{
        backgroundColor:'#040A14',
        borderRadius:'30px',
        height:'30px',
        marginRight:'10px',
        textTransform:'none',
        color:'white',
        "&:hover": {
            backgroundColor:'#040415',
            color:'white',
          },
    },
    alternateBackground:{
        backgroundColor:'white',
        color:'#040A14',
        "&:hover": {
            backgroundColor:'#eaf0fb',
            color:'#040A14',
          },
    },
    buttonDiv:{
        width:'40%',
        '@media (max-width: 540px)': {
            width:'30%',
            display:'flex',
            flexDirection:'column',
            gap:'8px',
            alignItems: 'flex-start',
          },
    
    },
    nameDiv:{
      '@media (max-width: 540px)': {
        width:'100%',    
      },
    },
    collName:{
        color: '#040A14',
        width:'120%',
        textAlign: 'center',
        backgroundColor: 'white',
        padding: '0 5px',
        borderRadius: '10px 10px 0 0',
        '@media (max-width: 540px)': {
            width:'95%',    
          },
    },

    uploadAreaDiv:{
        // display:'flex',
        flexGrow:'1',
        padding:'8px 0 30px 0',
    },
    emptyArea:{
        flexGrow:'1',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        cursor: 'pointer',
    },
    uploadIcon:{
        fontSize:'800%', color:'#EFEFEF',
        '@media (max-width: 520px)': {
            fontSize:'19vw',    
          },
    },
    uploadPDFText:{
        color:'white', fontSize:'40px',
        '@media (max-width: 520px)': {
            fontSize:'7.5vw',    
          },
    },
    otherText:{
        color:'#040A14',fontSize:'30px',
        '@media (max-width: 520px)': {
            fontSize:'3.5vw',    
          },
    },
    ytDiv:{
        display:'flex',
        flexGrow: '1',
        flexDirection:'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    linkText:{
        color:'black',
        marginTop:'20px',
        width:'70%',
        backgroundColor:'white',
        borderRadius:'30px',
        '@media (max-width: 900px)': {
        width:'85%',   
        },
        '& .MuiOutlinedInput-root': {
            border: 'none', // Remove the default border
            borderRadius:'30px',
          },
    },
    linkButton:{
        backgroundColor:'#5D4682',
        color:'white',
        borderRadius:'30px',
        width:'80px',
        "&:hover": {
            backgroundColor:'white',
            color:'#5D4682',
            border:'1px solid #5D4682'
          },
    },
    beginButtonDiv:{
        textAlign: 'center',
        position: 'relative',
        bottom: '10px',
    },
    beginButton:{
        textTransform:'none',
        borderRadius:'30px',
        width:'100px',
        fontSize:'120%',
        fontWeight:'600',
        backgroundColor:'#040A14',
        color:'white',
        "&:hover": {
            backgroundColor:'white',
            color:'#040A14',
          }, 
    },
    cancelButton:{
        marginLeft:'5px',
        textTransform:'none',
        borderRadius:'30px',
        fontSize:'120%',
        backgroundColor:'#d9d9d9',
        color:'#040415',
        "&:hover": {
            backgroundColor:'#530101',
            color:'white',
          }, 
    },
    fileIcon:{
        fontSize:'500%', color:'white',
        '@media (max-width: 520px)': {
            fontSize:'350%',    
          },
    },
    filesDiv:{
        padding: '2px',
        flexGrow: 1,
        flexWrap:'wrap',
    },
    iconAndText:{
        paddingTop: '4px',
        marginLeft:'6px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '@media (max-width: 525px)': {
            width:'78px',    
          },
        position:'relative'
    },
    removeIconDiv:{
      position:'absolute',
      top:0,
      right:0,
      '@media (max-width: 520px)': {
        right:'4px',    
      },
    },
    removeIcon:{
      fontSize: '20px',
      color: '#db0c00',
      backgroundColor: 'white',
      borderRadius: '10px',
      '@media (max-width: 520px)': {
        fontSize:'17px',    
      },
    },
    textBelowIcon:{
        maxWidth:'80px',
        textAlign:'center', 
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '@media (max-width: 525px)': {
          maxWidth:'78px',    
        },
    },
    anotherAddIcon:{
        fontSize:'500%', color:'#040A14',
        '@media (max-width: 520px)': {
            fontSize:'350%',    
          },
    },
    IconButtonAdd:{
        fontSize:'inherit',
    },
    nameNotWritten:{
        '&::placeholder': {
            color: 'red', // Change the color as needed
          },
    },
    loadingIconDiv:{
        marginTop:'90px',
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
        flexDirection:'column',
    },
    circularProgress:{
        color:'white',
        width: '100px', 
        height: '100px', 
    },
    uploadingtext:{
        color:'white',
        fontSize:'30px',
        marginBottom:0,
        textAlign:'center',
    },
    uploadingFilesDiv:{
      width:'320px',
      padding:'0 10px',
    },
    fileUploadTextSpan:{
      display:'flex',
      alignItems:'center',
      borderBottom:'1px solid #6c727f'
    },
    fileUploadText:{
      width:'240px',
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }

    
}))

async function readFromFile(file: File) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();

    fr.onload = () => {
      resolve(fr.result);
    };

    fr.onerror = () => {
      reject(new Error('An error occurred while reading the file.'));
    };

    fr.readAsText(file);
  });
}

interface FileUploadProps {
    chatCollectionId: string;
    onClose: () => void;
  }

export default function FileUploadPage({chatCollectionId,onClose }:FileUploadProps ){
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [fileTabOpen,setFileTabOpen]=useState(true);
    const [aFile,setAFile]=useState<File>();
    const [files,setFiles]=useState<File[]>([]);
    const [youtubeLink, setYoutubeLink] = useState('');
    const [videoId, setVideoId] = useState<string>("");
    const [fileStatus, setFileStatus] = useState<'INITIAL' | 'PENDING_ID_CREATION' | 'UPLOAD_FILE' | 'UPLOADED' | 'ERROR'>('INITIAL');
    const [presignedUploadUrl, setPresignedUploadUrl] = useState<CreateDocumentResponse['presignedUrl']|undefined>();
    let presignedUploadUrlList: ({ url: string; fields: { [key: string]: string; }; } | undefined)[]=[]
    const [collectionName, setCollectionName] = useState('');
    let documentList: (ChatDocument|ChatYoutubeVideo)[]=[]
    const [pdfWait, setPdfWait] = useState<number>(0);
    const [videoWait, setVideoWait] = useState<number>(0);
    let arrayIndex=0
    const [nameNotWritten,setNameNotWritten]=useState(false)
    const history = useHistory();
    const classes=useStyles();
    const [isLoading,setIsLoading]=useState(false);
    const [loaded,setLoaded]=useState(false);
    const placeholderColor=nameNotWritten?'red':'black'
    let collectionData: CreateCollectionResponse | undefined
    let collectionId: string | undefined;
    let subscriptionContext = useContext(SubscriptionContext);
    const [highlightFileSizeError, setHighlightFileSizeError] = useState(false);
    const [youtubeLinkError, setYoutubeLinkError] = useState<string|undefined>(undefined);
    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const [progress, setProgress] = React.useState(0);
    const [buffer, setBuffer] = React.useState(10);

    //notify the user that he should not close the page while data is being sent to backend
    useEffect(() => {
      if(isLoading){
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            return (event.returnValue = "");
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }  
    }, [isLoading]);
    
    //some linerProgress code start
    const progressRef = useRef(() => {});
    useEffect(() => {
      progressRef.current = () => {
        if (progress >= 100) {
          setBuffer(10);
        } else {
          const diff = Math.random() * 10;
          const diff2 = Math.random() * 10;
          setBuffer(progress + diff + diff2);
        }
      };
    });
    useEffect(() => {
      const timer = setInterval(() => {
        progressRef.current();
      }, 500);

      return () => {
        clearInterval(timer);
      };
    }, []);
    //linearprogress bar code finishes here

    const handleCollectionNameChange = (event:any) => {
        const inputValue = event.target.value;
        setCollectionName(inputValue);
        
      };
 
    
    const fileTab=()=>{
        setFileTabOpen(true)
    }
    const ytTab=()=>{
        setFileTabOpen(false)
    }
    const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>) => {
        setYoutubeLinkError(undefined);
        setHighlightFileSizeError(false);
        setYoutubeLink(event.target.value)
      };
    
    const handleYouTubeSubmit = () => {

      const id = extractYouTubeVideoId(youtubeLink);
      if (!id) {
        toast.error("Invalid YouTube link.");
        setYoutubeLinkError("Invalid YouTube link.");
        return;
      }
      setVideoId(id);
    };

    const extractYouTubeVideoId = (link: string): string => {
        const regex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        const match = link.match(regex);
        if (match && match[7].length == 11) {
          return match[7];
        }else{
          return ""
        } 
      }

    useEffect(()=>{
        if(videoId){
          toFile();
        }
      },[videoId])

      useEffect(()=>{
        if(files.length>0){
            console.log("files")
            console.log(files)
        }
        
      },[files])



    const inputDivClick=(e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>): void=>{
        // @ts-ignore
        if (e.target && e.target.id === "upgradePlan") {
            return;
        }
        //this function works twice needs a workaround
        fileInputRef.current?.click();
    }

    const addFiles=(file: File)=>{
        if(!files.length && !collectionName){
            setCollectionName(file.name.slice(0, -4))
        }
        if (!files.length || file !== files[files.length - 1]) {
            setFiles((prevFiles) => [...prevFiles, file]);
        }
    }

    const toFile= async () => {
        try {
          const videoObject=await getYoutubeMeta(youtubeLink)
          if (!videoObject) {
            toast.error("Invalid YouTube link.");
            setYoutubeLinkError("Invalid YouTube link.");
            return;
          }
          const maxVideoLength = subscriptionContext.isSubscribed ?
          60 * 60 // 1 hour if subscribed
          : 5 * 60; // 5 minutes if not subscribed;
          if (videoObject.length > maxVideoLength) {
            toast.error("Video length too long.");
            setYoutubeLinkError("Video length too long. Please upload a video less than " + maxVideoLength + " seconds.");
            setHighlightFileSizeError(true);
            return;
          }
          let videoTitle=videoObject?.title
          if(videoTitle) {videoTitle=videoTitle.substring(0, 30);}

          const file = await textToFile(youtubeLink, videoTitle?videoTitle:videoId);
          if(!files.length && !collectionName){
            setCollectionName(file.name.slice(0, -4))
          }
          setFiles((prevFiles) => [...prevFiles, file]);
          setYoutubeLink('')
          fileTab();
          // Further processing or usage of the file object
        } catch (error) {
          console.error('Error creating file:', error);
        }
    }


    const videoDocumentStore = useVideoDocument('new');
    const pdfDocumentStore = useChatDocument('new');


    const beginPressed=async ()=>{
        if(files.length==0){return alert("No file or link uploaded")}
        
        //check if file upload is opened as a new page or is opened from the chat page
        if(!chatCollectionId){ //new
            if(!collectionName){
                setNameNotWritten(true)  
                return alert("Type a Guru name!")
            }
            setIsLoading(true)
            collectionData=await createCollectionRequest(collectionName)
            collectionId=collectionData?.document?.id
        }else{ //existing
            collectionId=chatCollectionId
            setIsLoading(true)
        }
        
        toast.info('Feeding data to the Guru',{
            autoClose:120000, //120 seconds 
            });

        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Add smooth scrolling animation
        });
        
        for (const file of files){
            setProgress(0);
            setAFile(file);
            setFileStatus('INITIAL');

            if(file.type==='application/pdf'){
                await createDocument(file,1); 
            }
            else{
                await createDocument(file,2);
            }
        }
        
        if(presignedUploadUrlList.length==files.length){
            for (let i=0;i<files.length; i++){
                
                await uploadFile(documentList[i].id,files[i],presignedUploadUrlList[i])
                
            }
        }
        setIsLoading(false);
        setIsLoading(false);
        toast.dismiss();
        //if new page then go to the chat page
        if(!chatCollectionId)history.push(`/edu/collection-chat/${collectionId}`) 
        //else just reload the page
        else window.location.reload();
        
    }


    useEffect((()=>{
      if(fileStatus=='UPLOADED'){
        setCurrentFileIndex(currentFileIndex+1);
      }      
    }),[fileStatus])

    const createDocument = async (file: File,number: number): Promise<void> => {
        // calls api to create document
        try {
        setFileStatus('PENDING_ID_CREATION');
        let documentResp;
        if(number==1){
            documentResp = await pdfDocumentStore.createDocument(file,true,collectionId);
        }else if(number==2){
            const urlCurrentFile=await readFromFile(file);
            documentResp = await videoDocumentStore.createDocument(file,urlCurrentFile,true,collectionId);
        }
        
        if (documentResp?.document?.id) {
            setPresignedUploadUrl(documentResp.presignedUrl);
            presignedUploadUrlList.push(documentResp.presignedUrl)
            documentList.push(documentResp.document)

        }
        } catch (err) {
        console.log("unable to create document", err);
        toast.error('unable to upload')
        }
    }
    

    
    async function uploadFile(id:string,file: File, url: undefined| { url: string; fields: { [key: string]: string; }; }) {
        setFileStatus('UPLOAD_FILE');
        setProgress(10)
        try {
          if(file.type==='application/pdf'){
              await pdfDocumentStore.uploadFile(file, url);
              setProgress(65);
              //wait till it gets vectorized
              let isVec=await pdfDocumentStore.isVectorized(id);
              while (!isVec) {
                  await new Promise(resolve => setTimeout(resolve, 5000));
                  console.log("waiting")
                  isVec=await pdfDocumentStore.isVectorized(id)
              }
              if(isVec){
                console.log("Vectorized");
              }
              
          }else{
              await videoDocumentStore.uploadFile(file, url);
              setProgress(65);
              //wait till it gets vectorized
              let isVec=await videoDocumentStore.isVectorized(id)
              while (!isVec) {
                  await new Promise(resolve => setTimeout(resolve, 15000));
                  console.log("waiting")
                  isVec=await videoDocumentStore.isVectorized(id)
              }
              if(isVec){
                console.log("Vectorized");
                
              }
          }
          setProgress(100);
          setFileStatus('UPLOADED');
          console.log("uploaded")
          setPresignedUploadUrl(undefined);
        } catch (err) {
          console.log("unable to upload file", err);
          toast.error('unable to upload')
          setFileStatus('ERROR');
        }
    }

    const handleClose = () => {
        onClose();
      };

      const removeFile = (indexToRemove: number) => {
        const updatedFiles = [...files];
        updatedFiles.splice(indexToRemove, 1);
        setFiles(updatedFiles);
      };
      

    return subscriptionContext.status === "FETCHING" &&
      !subscriptionContext.subscription ? (
      <CircularProgress className="center-circular-progress" />
    ) : (
      <ThemeProvider theme={customTheme}>
        <Container className={classes.outerContainer}>
          <Container className={classes.innerContainer}>
          <Prompt when={isLoading} message="Changes you made may not be saved." />
            <Container className={classes.body}>
              {!isLoading?
                <>
                  <Typography className={classes.mainText}>
                    {!chatCollectionId
                      ? "File Upload"
                      : "Feed More Data To Guru"}
                  </Typography>
                  <div className={classes.contentDiv}>
                    <div className={classes.titleBar}>
                      <div className={classes.buttonDiv}>
                        <Button
                          className={
                            fileTabOpen
                              ? `${classes.titleBarButton} ${classes.alternateBackground}`
                              : `${classes.titleBarButton}`
                          }
                          onClick={() => fileTab()}
                        >
                          Files
                        </Button>
                        <Button
                          className={
                            !fileTabOpen
                              ? `${classes.titleBarButton} ${classes.alternateBackground}`
                              : `${classes.titleBarButton}`
                          }
                          onClick={() => ytTab()}
                        >
                          YouTube
                        </Button>
                      </div>
                      <div className={classes.nameDiv}>
                        {!chatCollectionId && (
                          <TextField
                            id="collection-name"
                            className={classes.collName}
                            variant="standard"
                            value={collectionName}
                            onChange={handleCollectionNameChange}
                            InputProps={{
                              inputProps: {
                                placeholder: "Guru Name*",
                                style: { color: placeholderColor },
                              },
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {fileTabOpen ? (
                      <div className={classes.uploadAreaDiv}>
                        {!files.length ? (
                          <div
                            className={classes.emptyArea}
                            onClick={inputDivClick}
                          >
                            <UploadIcon className={classes.uploadIcon} />
                            <Typography className={classes.uploadPDFText}>
                              Upload PDFs
                            </Typography>
                            <Typography className={classes.otherText}>
                              Click to upload
                            </Typography>
                           
                            <input
                                  id="pdf-upload-input"
                                  ref={fileInputRef}
                                  type="file"
                                  accept=".pdf"
                                  style={{ display: "none" }}
                                  multiple  // Allow multiple file selections
                                  onChange={(e) => {
                                    setHighlightFileSizeError(false);
                                    if (e.target.files && e.target.files.length > 0) {
                                      let maxFileSize = subscriptionContext.isSubscribed?
                                         200 * 1024 * 1024 // 200 MB
                                        : 5 * 1024 * 1024; // 5 MB

                                      // Loop through selected files
                                      for (let i = 0; i < e.target.files.length; i++) {
                                        const file = e.target.files[i];
                                        if (file.size > maxFileSize) {
                                          toast.error("File size too large for file: " + file.name);
                                          setHighlightFileSizeError(true);
                                          return;
                                        }
                                        if(files.length>50){
                                          toast.error("You can only add 50 files!" );
                                          break;
                                        }
                                        addFiles(file);
                                      }
                                    }
                                  }}
                                />
                             <Typography variant="caption" style={{padding: '1em'}} onClick={() => {}}>
                                {subscriptionContext.isSubscribed ? "Max file size: 200MB" : 
                                    <span>
                                        <span style={{color: highlightFileSizeError ? '#ff5757' : 'inherit'}}>Max file size is 5MB.</span>
                                        <Button
                                            variant="contained"
                                            style={{marginLeft: '5px', marginRight: '5px'}}
                                            size="small"
                                            id="upgradePlan"
                                            component={Link} to="/edu/upgradePlan">Upgrade Plan</Button>
                                        to upload larger files.
                                    </span>
                                }
                            </Typography>
                          </div>
                        ) : (
                        <div>
                            <div className={classes.filesDiv} style={{display: 'flex'}}>
                                {files.map((file, index) => (
                                <div key={index} className={classes.iconAndText}>
                                    <div className={classes.removeIconDiv}>
                                      <IconButton style={{padding:0}} onClick={()=>removeFile(index)}>
                                        <RemoveCircleIcon className={classes.removeIcon}/>
                                      </IconButton>
                                    </div>
                                    <DescriptionIcon className={classes.fileIcon} />
                                    
                                    <Typography className={classes.textBelowIcon}>
                                      {file.name}
                                    </Typography>
                                    
                                </div>
                                ))}
                                <div className={classes.iconAndText}>
                                <input
                                  id="pdf-upload-input"
                                  ref={fileInputRef}
                                  type="file"
                                  accept=".pdf"
                                  style={{ display: "none" }}
                                  multiple  // Allow multiple file selections
                                  onChange={(e) => {
                                    setHighlightFileSizeError(false);
                                    if (e.target.files && e.target.files.length > 0) {
                                      let maxFileSize = subscriptionContext.isSubscribed?
                                         200 * 1024 * 1024 // 200 MB
                                        : 5 * 1024 * 1024; // 5 MB

                                      // Loop through selected files
                                      for (let i = 0; i < e.target.files.length; i++) {
                                        const file = e.target.files[i];
                                        if (file.size > maxFileSize) {
                                          toast.error("File size too large for file: " + file.name);
                                          setHighlightFileSizeError(true);
                                          return;
                                        }
                                        if(files.length>50){
                                          toast.error("You can only add 50 files!" );
                                          break;
                                        }
                                        addFiles(file);
                                      }
                                    }
                                  }}
                                />

                                <label htmlFor="pdf-upload-input">
                                    <IconButton
                                    className={classes.IconButtonAdd}
                                    onClick={inputDivClick}
                                    >
                                    <AddCircleOutlineTwoToneIcon
                                        className={classes.anotherAddIcon}
                                    />
                                    </IconButton>
                                </label>
                                </div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                                <Typography variant="caption" style={{padding: '1em'}} onClick={() => {}}>
                                    {subscriptionContext.isSubscribed ? "Max file size: 200MB" : 
                                        <span>
                                            <span style={{color: highlightFileSizeError ? '#ff5757' : 'initial'}}>Max file size is 5MB.</span>
                                            <Button
                                                variant="contained"
                                                style={{marginLeft: '5px', marginRight: '5px'}}
                                                size="small"
                                                id="upgradePlan"
                                                component={Link} to="/edu/upgradePlan">Upgrade Plan</Button>
                                            to upload larger files.
                                        </span>
                                    }
                                </Typography>
                            </div>
                        </div>
                        )}
                      </div>
                    ) : (
                      <div className={classes.uploadAreaDiv}>
                        <div className={classes.ytDiv}>
                          <TextField
                            placeholder="YouTube URL"
                            value={youtubeLink}
                            sx={{ boxShadow: 5 }}
                            onChange={(e) => handleLinkChange(e)}
                            className={classes.linkText}
                            error={!!youtubeLinkError}
                            InputProps={{
                              endAdornment: (
                                <Button
                                  onClick={handleYouTubeSubmit}
                                  className={classes.linkButton}
                                >
                                  Submit
                                </Button>
                              ),
                              style: { color: "black" }
                            }}
                          />
                          {youtubeLinkError && (
                            <Typography variant="caption" style={{color: '#ff5757'}} onClick={() => {}}>
                              {youtubeLinkError}
                            </Typography> 
                          )}
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                          <Typography variant="caption" style={{padding: '1em'}} onClick={() => {}}>
                              {subscriptionContext.isSubscribed ? "Max video length is 1 hour" : 
                                  <span>
                                        <span style={{color: highlightFileSizeError ? '#ff5757' : 'inherit'}}>Max video length is 5 minutes.</span>
                                      <Button
                                          variant="contained"
                                          style={{marginLeft: '5px', marginRight: '5px'}}
                                          size="small"
                                          id="upgradePlan"
                                          component={Link} to="/edu/upgradePlan">Upgrade Plan</Button>
                                      to upload larger videos.
                                  </span>
                              }
                          </Typography>
                        </div>
                      </div>
                    )}
                    <div className={classes.beginButtonDiv}>
                        <Button
                          onClick={beginPressed}
                          className={classes.beginButton}
                          sx={{ boxShadow: 3 }}
                        >
                          {!chatCollectionId ? "Begin" : "Continue"}
                      </Button>
                      
                      {chatCollectionId && (
                        <Button
                          className={classes.cancelButton}
                          onClick={handleClose}
                        >
                          {" "}
                          Cancel{" "}
                        </Button>
                      )}
                    </div>
                  </div>
                </>
                :
                  <div className={classes.loadingIconDiv}>
                    <div style={{border:'1px solid white',borderRadius:'10px',padding:'0px 0px 25px'}}>
                      <p className={classes.uploadingtext}>Uploading</p>
                      <div className={classes.uploadingFilesDiv}>
                        {files.map((file, index) => (                      
                          <span className={classes.fileUploadTextSpan}>
                            <p className={classes.fileUploadText}>{file.name}</p>
                            <LinearProgress color="success" variant="buffer" key={index}
                              value={currentFileIndex === index ? progress: currentFileIndex > index ? 100 : 0} 
                              style={{ width: "80%", margin:'0 5px' }} 
                              valueBuffer={buffer} 
                            />
                          </span> 
                        ))} 
                        </div>
                      </div>
                  </div>
                }
               
            </Container>
          </Container>
        </Container>
      </ThemeProvider>
    );
}





  