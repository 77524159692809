import { PaymentMethod } from "@stripe/stripe-js";
import ApiRequest from "../apiRequest";



export default async function createSubscription(paymentMethod?: PaymentMethod, coupon?: string) {

  try {
    let result = await ApiRequest(
      '/api/subscription',
      'POST',
      JSON.stringify({
        coupon,
        paymentMethodId: paymentMethod?.id
      })
    )

  
    return {
      // @ts-ignore
      subError: result.error,
      // @ts-ignore
      subscription: result.subscription
    }
  } catch (err) {
    console.error("unable to create subscription", err);
    return {
      subError: "unable to create subscription"
    };
  }
}